import React,{Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { persistStore } from "reduxjs-toolkit-persist";
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
// import {CircularProgress} from '@material-ui/core';
import loadingImg from './assets/loading.gif';

import { MuiThemeProvider } from '@material-ui/core';
import theme from './theme';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider } from 'notistack';
import Notifier from './components/snackbar';


const UserActions = lazy(() => import('./components/user-action'));
const App = lazy(() => import('./App'));
const Index = lazy(() => import('./components/index'));
const Home = lazy(() => import('./components/index/home'));
const ErrorPage = lazy(() => import('./components/error-page'))

const Loading = () => {
  return (
    <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#f0f0f0'}}>
      {/* <CircularProgress size={80} color="inherit"/> */}
      <img src={loadingImg} alt="" />

    </div>
  )
}


const useStyles = makeStyles(theme => ({
  variantInfo:{
    backgroundColor: theme.palette.background.paper
  }
}))

const SnackbarProviderComponent = (props:any) => {
  const classes = useStyles();
  return (
    <SnackbarProvider 
      autoHideDuration={3000} 
      preventDuplicate={true} 
      hideIconVariant={true}
      dense={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      classes={{
        variantInfo: classes.variantInfo
      }}
    >
      {props.children}
    </SnackbarProvider>
  )
}
localStorage.removeItem('persist:pandora');
ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistStore(store)}>
          <MuiThemeProvider theme={theme}>
            <SnackbarProviderComponent >
              <Grid style={{width: '100vw', height: '100vh',display: 'flex'}}>
                <Notifier/>
                <Router>
                  <Switch>
                    <Route path='/index' component={Index}/>
                    <Route path='/home' component={Home}/>
                    <Route path='/u/:type' component={UserActions}/>
                    <Route path='/:status(404|500|error-browser)' component={ErrorPage}/>
                    <Route path='/' component={App}/>
                    <Route render={() => {
                      return (
                        <Redirect to={`/404`} />
                      )
                    }}/>
                  </Switch>
                </Router>
              </Grid>
            </SnackbarProviderComponent>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </Suspense>
  ,
  document.getElementById('root')
);



serviceWorker.unregister();
