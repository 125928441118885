import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';

const {Map} = require('immutable');
interface StateProps {
  loading: {
    [key: string]: boolean;
  },
  activeThemeId:number,
  activeCompanyId: number,
  activeEventId: number,
  activeTab: string,
  stockCode:string,
  news:{
    [key:string]: any;
  },
  eventNews:{
    [key:string]: any;
  },
  companyInformations:{
    [key:string]: any
  },
  companyInSights: {
    [key:string]: any
  },
  companyProductIndustry:{
    product:string,
    industry:string,
  },
  watched: {
    dataList: Array<any>,
    total: number, 
    hasMore: boolean,
    page?: number
  },
  opportunities: any,
  eventDetails: any,
  topCompanyEvent: any,
  mainBusinessMap: any,
  insightsTypes: Array<any>,
  companyAnnouncement:{
    [key:string]: any
  }
}

const initialState: StateProps = {
  loading: {},
  activeCompanyId: 0,
  activeThemeId: 0,
  activeEventId: 0,
  stockCode:'',
  activeTab: 'event',
  news:{},
  companyInformations:{},
  companyInSights: {},
  companyProductIndustry:{
    product:'',
    industry:'',
  },
  watched: {
    dataList: [],
    total: 0, 
    hasMore: true,
  },
  opportunities: {},
  eventDetails:{},
  topCompanyEvent: {},
  eventNews: {},
  mainBusinessMap: {},
  insightsTypes: [],
  companyAnnouncement:{}
}

export const fetchNewsByCompanyId = createAsyncThunk(
  'portfolio-dashboard/fetch-get-news-by-company-id', 
  (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchGetNewsByCompanyId(params)
      return {
        data: result.data,
        companyId
      }
    })
  }
)

export const fetchEventNewsByCompanyId = createAsyncThunk(
  'portfolio-dashboard/fetch-get-event-news-by-company-id', 
  (params:any, thunkAPI) => {
    const {eventId} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchGetNewsByCompanyId(params)
      return {
        data: result.data,
        eventId
      }
    })
  }
)

export const fetchCompanyProduct = createAsyncThunk(
  'portfolio-dashboard/fetch-company-product', 
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchCompanyProduct(params)
      return {
        data: result.data,
        code:result.code
      }
    })
  }
)


export const fetchCompanyInformation = createAsyncThunk(
  '/portfolio-dashboard/fetch-company-information',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyInformation(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)
export const fetchGetCompanyInsights = createAsyncThunk(
  '/theme-map/fetch-company-insights',
  async (params: any):Promise<any> => {
    const { companyId, insightType, ...restProps} = params;
    const pageParams = {
      "endTimestamp": Date.now(),
      "page": 0,
      "size": 10,
    }
    const { data } = await pandora.fetchGetCompanyInsights({...restProps, companyId, ...pageParams});
    return {
      data,
      companyId,
      insightType,
    }
  }
)
export const fetchCompanyList = createAsyncThunk(
  'portDash/fetch-company-list',
  (params:any, thunkAPI):Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data,code} = await pandora.fetchGetCompanyList(params);
      return {
        ...data,
        ...code,
        ...params
      }
    })
  }
)

export const fetchGetEventDataListByCompany = createAsyncThunk(
  '/portfolio-dashboard/fetch-get-event-dataList-by-company',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDataListByCompany(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)

export const fetchGetTopCompanyEvent = createAsyncThunk(
  '/portfolio-dashboard/fetch-get-top-company-event',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetTopCompanyEvent(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)

export const fetchGetEventDetail = createAsyncThunk(
  '/portfolio-dashboard/fetch-get-event-detail',
  async (params:any, thunkAPI) => {
    const {eventId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDetail(params);
      return {
        ...result,
        eventId,
      }
    })
  }
)

export const fetchGetCompanyAnnouncement = createAsyncThunk(
  '/portfolio-dashboard/fetch-get-company-announcement',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyAnnouncement(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)


export const fetchGetCompanyInsightsEventTypeList = createAsyncThunk(
  '/portfolio-dashboard/fetch-get-company-insights-event-type-list',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyInsightsEventTypeList(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)

export const fetchGetCompanyMainBusinessTurnover = createAsyncThunk(
  '/portfolio-dashboard/fetch-get-company-main-business-turnover',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyMainBusinessTurnover(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)


export const portfolioDashboard = createSlice({
  name:'portfolioDashboard',
  initialState,
  reducers: {
    setActiveTab: (state, {payload}) => {
      state.activeTab = payload;
    },
    setThemeId: (state, {payload}) => {
      state.activeThemeId = payload;
    },
    setCompanyIndustry: (state, {payload}) => {
      state.companyProductIndustry.industry = payload 
    },
    setStockCode: (state, {payload}) => {
      state.stockCode = payload 
    },
    setCompanyProduct :(state, {payload}) => {
      state.companyProductIndustry.product =''
      if(payload){
        payload.forEach((item:any) => {
          state.companyProductIndustry.product += `${item.businessNameZh}，`
        });
      }
    },
  },
  
  extraReducers:(builder) => {
    builder.addCase(fetchNewsByCompanyId.pending, state => {
      state.loading.newsLoading = true
    }).addCase(fetchNewsByCompanyId.fulfilled, (state, {payload}) => {
      const {companyId, data} = payload
      state.news[companyId] = data;
      console.log("state.news[companyId]--",state.news[companyId])
    }).addCase(fetchEventNewsByCompanyId.pending, state => {
      state.loading.fetchEventNewsByCompanyId = true
    }).addCase(fetchEventNewsByCompanyId.fulfilled, (state, {payload}) => {
      const {eventId, data} = payload
      state.eventNews[eventId] = data
    }).addCase(fetchCompanyInformation.pending, state => {
      state.loading.information = true;
    }).addCase(fetchCompanyInformation.fulfilled, (state, {payload}) => {
      const {companyId, data} = payload;
    
      state.companyInformations[companyId] = data
      state.loading.information = false;
    }).addCase(fetchGetCompanyInsights.pending, state => {
      state.loading.insight = true;
    }).addCase(fetchGetCompanyInsights.fulfilled, (state,{payload}) => {
      state.loading.insight = false;
      const {companyId, insightType, data} = payload;
      state.companyInSights = {
        ...state.companyInSights,
        [companyId]: {
          ...state.companyInSights[companyId],
          [insightType]: data
        }
      }
    }).addCase(fetchCompanyProduct.fulfilled, (state,{payload}) => {
      const {data, code } = payload
      state.companyProductIndustry.product = ''
      if(code=== 0 && data.length){
        data.forEach((item:any) => {
          state.companyProductIndustry.product += `${item.nameZh}，`
        });
      }
     
    }).addCase(fetchCompanyList.pending, state => {
      state.loading.fetchCompanyList = true;
    }).addCase(fetchCompanyList.fulfilled,(state, {payload}) => {
      const {size, page, dataList = [], ...restProps} = payload;
        const refactorCompanyList = dataList.length ? dataList.map((item:any) => ({...item, riskPoint: item.companyImpactScoreData.riskPoint,opportunityPoint: item.companyImpactScoreData.opportunityPoint,impactScore: item.companyImpactScoreData.impactScore,impact: item.companyImpactScoreData.impact})) : []
        state.watched = {
          ...restProps,
          dataList: page > 0 ? state.watched.dataList.concat(refactorCompanyList) : refactorCompanyList,
          hasMore: !Boolean(dataList.length < size),
          page: page + 1,
        }      
      state.loading.fetchCompanyList = false;
    }).addCase(fetchGetEventDataListByCompany.pending, (state) => {
      state.loading.fetchCompanyEventsLoading = true
    }).addCase(fetchGetEventDataListByCompany.fulfilled, (state, {payload}) => {
      const {code, data, companyId} = payload;
      if(code === '0'){
        state.opportunities[companyId] = data;
        state.loading.fetchCompanyEventsLoading = false
      }
      
    }).addCase(fetchGetTopCompanyEvent.pending, (state) => {
      state.loading.fetchGetTopCompanyEventLoading = true
    }).addCase(fetchGetTopCompanyEvent.fulfilled, (state, {payload}) => {
      const {code, data, companyId} = payload;
      if(code === '0'){
        state.topCompanyEvent[companyId] = data;
        state.loading.fetchGetTopCompanyEventLoading = false
      }
      
    }).addCase(fetchGetEventDetail.pending, (state) => {
      state.loading.fetchEventDetailLoading = true
    }).addCase(fetchGetEventDetail.fulfilled, (state, {payload}) => {
      const {code, data, eventId} = payload;
      if(code==='0'){
        const {eventEntity, entityGraph,  ...restResult} = data;
        let entityGraphObj = {
          children:[] as any[],
          name:'',
          id:''
        }
        entityGraph.forEach((item:any) => {
          if(item.master){
            entityGraphObj.id =item.entityId
            entityGraphObj.name = item.entityValueZh
          }else{
            entityGraphObj.children.push({...item, name:item.entityValueZh,type:'child'})
          }
        });
        const normlizedData = eventEntity.reduce((all: {[key: string]: any}, dataItem: any) => {
          const {entityList:currentEntities, type} = dataItem;
          return {
                ...all,
                [type]: currentEntities.map((item:any) => ({...item, company_name_en: item.entityValueEn, company_name_zh: item.entityValueZh})),
              }
        }, {});
        const normlizedDataZh = eventEntity.reduce((all: {[key: string]: any}, dataItem: any) => {
          const {entityList:currentEntities, typeZh} = dataItem;
          return {
                ...all,
                [typeZh]: currentEntities.map((item:any) => ({...item, company_name_en: item.entityValueEn, company_name_zh: item.entityValueZh})),
              }
        }, {});
        state.eventDetails[eventId] = {
          ...restResult,
          graph:{
            data:entityGraphObj
          },
          entities:{
            data_list: normlizedData
          },
          entitiesZh:{
            data_list: normlizedDataZh
          }
        };
      }
      state.loading.fetchEventDetailLoading = false
    }).addCase(fetchGetCompanyMainBusinessTurnover.pending, (state) => {
      state.loading.fetchGetCompanyMainBusinessTurnover = true
    }).addCase(fetchGetCompanyMainBusinessTurnover.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        let obj = {}  as any
        data.forEach((item:any) => {
          obj[item.year] = item
        });
        state.mainBusinessMap = obj;
      }
    }).addCase(fetchGetCompanyInsightsEventTypeList.pending, (state) => {
      state.loading.fetchGetCompanyInsightsEventTypeList = true
    }).addCase(fetchGetCompanyInsightsEventTypeList.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.insightsTypes = data;
      }
    }).addCase(fetchGetCompanyAnnouncement.pending, (state) => {
      state.loading.fetchGetCompanyAnnouncement = true
    }).addCase(fetchGetCompanyAnnouncement.fulfilled, (state, {payload}) => {
      const {code, companyId, data} = payload;
      if(code === '0'){
        state.companyAnnouncement[companyId] = data;
      }
    })

  }
})


export const {setStockCode, setActiveTab, setCompanyIndustry, setCompanyProduct, setThemeId} = portfolioDashboard.actions;

const selectBaseState = (state: RootState)  => state.portfolioDashboard;
export const selectLoadingByType = (type:string) =>  createSelector(selectBaseState, state => state.loading[type] || false);
export const selectNewsById = (id: any) => createSelector(selectBaseState, state => {
  if(!id){
    return []
  }
  return state.news[id] || []
})
export const selectEventNewsById = (id: any) => createSelector(selectBaseState, state => {
  if(!id){
    return []
  }
  return state.eventNews[id] || []
})
export const selectCompanyInformation = (companyId: any) => createSelector(selectBaseState, state => {
  if(!companyId){
    return {};
  }
  return state.companyInformations[companyId];
})
export const selectTopCompanyEvent = (companyId: any) => createSelector(selectBaseState, state => {
  if(!companyId){
    return [];
  }
  return state.topCompanyEvent[companyId];
})
export const selectInsightList = (companyId:number, insightType: string) => createSelector(selectBaseState, state => {
  if(!companyId || !insightType){
    return []
  }
  return Map(state.companyInSights).getIn([companyId.toString(), insightType], [])
})
export const selectProductIndustry = createSelector(selectBaseState, state => state.companyProductIndustry)
export const selectActiveThemeId = createSelector(selectBaseState, state => state.activeThemeId)
export const selectWatchedList = createSelector(selectBaseState, state => state.watched.dataList);
export const watchedFetchMoreOptions = createSelector(selectBaseState, state => {
  const {dataList, ...othersProps} = state.watched;
  return othersProps;
}) 
export const selectEventDetailById = (id:any) => createSelector(
  selectBaseState, state => state.eventDetails[id] || {}
)
export const selectOpportunitiesDetailById = (id: any) => createSelector(
  selectBaseState, state => state.opportunities[id] || [] 
)
export const selectMainBusinessMap = createSelector(selectBaseState, state => state.mainBusinessMap || {});
export const selectInsightsTypes = createSelector(selectBaseState, state => state.insightsTypes || []);
export const selectCompanyAnnouncement = (companyId: any) => createSelector(selectBaseState, state => {
  if(!companyId){
    return [];
  }
  return state.companyAnnouncement[companyId];
})

export const selectStockCode = createSelector(selectBaseState, state => state.stockCode);

export default portfolioDashboard.reducer;
