import { createSlice, createSelector, createAsyncThunk} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import pandora from '../../api';
import { UserSettingsProps } from '../../types';
import {enqueueSnackbar} from '../snackbar/reducer';
import i18n from '../../i18n';

const { Map } = require('immutable');
interface UserInfoProps {
  uid: string,
  user_name: string;
  um_id: string;
  email: string;
  type: number;
  token: string;
  is_active: boolean;
  expire_time: number,
  language: string;
  famliy_name: string;
  config_json: UserSettingsProps;
}

interface UserProps {
  info?: UserInfoProps,
  userInfo: UserInfoProps,
  tokenInfo:any,
  userOpenFloorList:any,
  language:string,
  refresh:boolean,
  loading: boolean,
  userGuide_json: any,
  colorType: string,
  toC:boolean,
  ueserAccess:number,
}

const initialState: UserProps = {
  userInfo: {} as UserInfoProps,
  tokenInfo:{},
  userOpenFloorList:[],
  language: localStorage.getItem('i18nextLng') || 'en',
  refresh: true,
  loading: false,
  userGuide_json: {
    formModule:'floor',
    userGuideDialog:true,
    userGuideStep: false,
    isLoyal:false,
    floor:false,
    lobby:false,
    rooms:false,
    watchList:false,
    setting:false
  },
  colorType:'1',
  toC:false,
  ueserAccess:11,
}


export const fetchUserInfo = createAsyncThunk(
  'user/fetch-user-info',
  async (arg:void, thunkAPI): Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {result, success} = await pandora.fetchUserInfo()
      if(result.data){
        i18n.changeLanguage(result.data.language);
        thunkAPI.dispatch(getThemeColor({type:'B',userId:result.data.uid}))
        return {
          success, 
          result
        }
      }
      thunkAPI.dispatch(toLogout());
      await pandora.logout();
      return Promise.reject()
    })
  }
);

export const fetchTocUserInfo = createAsyncThunk(
  'user/fetch-toc-user-info',
  async (arg:void, thunkAPI): Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data, code} = await pandora.tocGetUser()
      if(data){
        thunkAPI.dispatch(getThemeColor({type:'C',userId:data.uid}))
        i18n.changeLanguage('zh-CN');
        return {
          code, 
          data
        }
      }
      thunkAPI.dispatch(toLogout());
      await pandora.logout();
      return Promise.reject()
    })
  }
);


export const getThemeColor = createAsyncThunk(
  'user/get-theme-color',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.getThemeColor(params))
  }
)


export const checkToken = createAsyncThunk(
  'user/check-token',
  async (args:void, thunkAPI):Promise<any> => {
    return pandora.checkToken().then((resp:any) => {
      return resp;
    }).catch((error:any) => {
      return thunkAPI.dispatch(refreshToken())
    });
  }
)

export const refreshToken = createAsyncThunk(
  'user/refresh-token',
  (arg:void, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState; 
    const rsToken = rootState.user.tokenInfo.refresh_token;
    return pandora.refreshToken({refreshtoken: rsToken}).then(async (resp:any) => {
      const {msg = '', success = true} = resp;
      if(msg === 'invalid_refresh_token'){
        thunkAPI.dispatch(enqueueSnackbar({
          message: 'Invalid Token',
          options: {
            key: 'invalidToken',
            variant: 'error',
          },
        }))
        await pandora.logout();
        return thunkAPI.dispatch(toLogout());
      }
      if(success){
        return thunkAPI.dispatch(setTokenInfo(resp.result))
      }
    }) 
  }
)

export const updateUserConfig = createAsyncThunk(
  '/dashboard/update-config',
  (config: any, thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.updateUserConfig({data: JSON.stringify(config)}))
)

export const updatePassword = createAsyncThunk(
  'header/update-password',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.updatePassword(payload))
);

export const changePwByUser = createAsyncThunk(
  'header/change-Pw-By-User',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.changePwByUser(payload))
);

export const setUserLanguage = createAsyncThunk(
  '/dashboard/set-user-language',
  (lang: string, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.setUserLanguage({data: lang})).then(resp => thunkAPI.dispatch(setLanguage(lang)))
  }
)
export const sendInviteEmail = createAsyncThunk(
  'user/send-invite-email',
  (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.sendInviteEmail(params))
  }
)

export const getUeserAccess = createAsyncThunk(
  'user/fetch-get-ueser-access',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.getUeserAccess(params))
  }
)
export const saveThemeColor = createAsyncThunk(
  'user/saveThemeColor',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.saveThemeColor(payload))
);

export const user = createSlice({
  name:'user',
  initialState,
  reducers: {
    toLogout:(state) => {
      state.userInfo = {} as UserInfoProps;
      state.tokenInfo = {};
    },
     setTokenInfo: (state, {payload}) => {
      state.tokenInfo = {
        ...state.tokenInfo,
        ...payload,
      }
    },
    setLanguage: (state, {payload}) => {
      state.language = payload;
    },
    setUserGuideJson:(state, {payload}) => {
      state.userGuide_json={
        ...state.userGuide_json,
        ...payload
      }
    },
    setToc:(state, {payload}) => {
      state.toC= payload
    },
    setUeserAccess: (state, {payload}) => {
      state.ueserAccess = payload;
    },
    setSystemColorType:(state, {payload}) => {
      state.colorType = payload;
    },
    setUserColorType:(state, {payload}) => {
      state.colorType= payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending,(state,action) => {
      state.loading = true;
    }).addCase(fetchUserInfo.fulfilled, (state, action) => {
      const {success, result} = action.payload;
      if(success){
        const {data} = result;
        state.toC = false
        const user_config = (data?.config_json || '').slice(1,-1).length ? JSON.parse(data.config_json) : {} 
        state.userInfo = {
          ...data,
          config_json: user_config 
        }
        state.language = data.language
      }
      state.loading = false;
    }).addCase(fetchTocUserInfo.pending,(state,action) => {
      state.loading = true;
    }).addCase(fetchTocUserInfo.fulfilled,  (state, action) => {
      const {data,code} = action.payload
      if(code === '0'){
        const user_config = {}
        state.toC = true
        state.userInfo = {
          toC:true,
          ...data,
          config_json: user_config
        }
        state.language = 'zh-CN'
      }
     
      state.loading = false;
    }).addCase(getThemeColor.fulfilled,  (state, action) => {
      const {data,code} = action.payload
      if(code === '0'){
        if(data.type){
          state.colorType = data.value
        }else{
          state.colorType = '2'
        }
      }
     
    })

    
  },
  
})

export const {
  toLogout, 
  setLanguage, 
  setTokenInfo,
  setUserGuideJson,
  setToc,
  setUeserAccess,
  setSystemColorType,
  setUserColorType
} = user.actions;

const selectBaseState = (state: RootState) => Map(state.user);
export const selectIsLogin = createSelector(selectBaseState, user => Boolean(user.getIn(['tokenInfo', 'token'],'') && user.getIn(['userInfo', 'uid'], 0) > 0));
export const selectLanguage = createSelector(selectBaseState, user => user.getIn(['userInfo', 'language'], localStorage.getItem('i18nextLng') || 'en' ));
export const selectUserToken = createSelector(selectBaseState, user => user.getIn(['tokenInfo', 'token'], ''))
export const selectUserGuideDialogStatus = createSelector(selectBaseState, user => user.getIn(['userGuide_json','userGuideDialog'], true));
export const selectUserGuideFormModule = createSelector(selectBaseState, user => user.getIn(['userGuide_json','formModule'], 'floor'));
export const selectUserGuideStepStatus = createSelector(selectBaseState, user => user.getIn(['userGuide_json','userGuideStep'], false));
export const selectUserGuideJson = createSelector(selectBaseState, user => {
return user.get('userGuide_json');})
export const selectUserId = createSelector(selectBaseState, user => user.getIn(['userInfo', 'uid'], ''))
export const selectUserPhone = createSelector(selectBaseState, user => user.getIn(['userInfo', 'phoneNum'], ''))
export const selectUserEmail = createSelector(selectBaseState, user => user.getIn(['userInfo', 'email'], ''))
export const selectUserName = createSelector(selectBaseState, user => user.getIn(['userInfo', 'user_name'], ''))
export const selectUserFamilyName = createSelector(selectBaseState, user => user.getIn(['userInfo', 'family_name'], ''))
export const selectUserExpireTime = createSelector(selectBaseState, user => user.getIn(['userInfo', 'expire_time'], ''))
export const selectUserCreateTime = createSelector(selectBaseState, user => user.getIn(['userInfo', 'create_time'], ''))
export const selectTocUserLoginTime = createSelector(selectBaseState, user => user.getIn(['userInfo', 'userLoginTime'], ''))
export const selectTocUserCreateTime = createSelector(selectBaseState, user => user.getIn(['userInfo', 'userCreateTime'], ''))
export const selectUserGender = createSelector(selectBaseState, user => user.getIn(['userInfo', 'gender'], ''))
export const selectUserStockAge = createSelector(selectBaseState, user => user.getIn(['userInfo', 'stockAge'], 1))
export const selectUserFundPreference = createSelector(selectBaseState, user => user.getIn(['userInfo', 'fundPreference'], []))
export const selectPremiumMembership = createSelector(selectBaseState, user => user.getIn(['userInfo', 'premiumMembership'], false))
export const selectToCUId = createSelector(selectBaseState, user => user.getIn(['userInfo', 'uid'], ''))
export const selectUserRole = createSelector(selectBaseState, user => user.getIn(['userInfo', 'role'], 'user'))
export const selectUserLoginTime = createSelector(selectBaseState, user => { 
  return  user.getIn(['userInfo', 'login_time'], '');
})
export const selectUserSimpleName = createSelector(selectUserName, selectUserFamilyName, selectUserEmail, (username, familyName, email)  => {
  if(username && familyName){
    return `${username.slice(0,1)}${familyName.slice(0,1)}`.toUpperCase()
  }
  return email?.slice(0,2).toUpperCase();
})
export const selectColorType = createSelector(selectBaseState, user =>user.getIn(['colorType'], '2'));
export const selectUserToc = createSelector(selectBaseState, user => user.getIn(['toC'], false));
export const selectUeserAccess = createSelector(selectBaseState, user => user.getIn(['ueserAccess'], 11));



export default user.reducer; 
