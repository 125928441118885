import Axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

const PANDORA_PERSIST_KEY = 'persist:pandora-root';

export const isMobile = () => {
  let userAgentInfo = navigator.userAgent;
  // 去除ipad
  let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
  let flag = false;
  for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = true;
          break;
      }
  }
  return flag;
}


export const formValuesValid = (values: any) => {
  return !Boolean(Object.keys(values).some(value => !value));
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(PANDORA_PERSIST_KEY);
    if (serializedState === null) {
      return {};
    } else {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return {};
  }
};

export const wrapText = (text:string, width:number) => {
  if(!text){
    return '';
  }
  const splitLabel = text.split(' ');
  const maxCodeLength = Math.ceil(width/12);
  const codeGroup = splitLabel.reduce((all:Array<any>, current, index) => {
    if(all.length){
      if(all[all.length-1].join(' ').length + current.length > maxCodeLength ){
        return [...all, [current]];
      }else{
        const allValues = all;
        const latestOne = allValues.pop();
        return [
          ...all,
          latestOne.concat(current)
       ]
      }
    }else{
      return [[current]];
    }
  },[[]])
  return codeGroup.map(item => item.join(' ')).join('\n')
}

export const createMarkup = (htmlString: string) => {
  return {__html: htmlString};
}

export const parseString = (str: string) => {
  if(str) {
    return JSON.parse(str);
  }
  return {}
}

export const isSupportBrowsers = () => {
  const browserWhiteLists= ['Chrome', 'Firefox', 'Safari'];
  return browserWhiteLists.some(item => window.navigator.userAgent.indexOf(item) > -1);
}

export const getCurrentDateTimeFormatted=()=> {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1，并确保是两位数
  const date = String(now.getDate()).padStart(2, '0'); // 确保日期是两位数
  const hours = String(now.getHours()).padStart(2, '0'); // 确保小时是两位数
  const minutes = String(now.getMinutes()).padStart(2, '0'); // 确保分钟是两位数
  const seconds = String(now.getSeconds()).padStart(2, '0'); // 确保秒数是两位数

  return `${year}${month}${date}${hours}${minutes}${seconds}`;
}

var FileSaver = require('file-saver');
export const downloadExcel = (dowloadUrl:string,token:string,params:Object,name:string) => {
  let firstURL = process.env.REACT_APP_BFF_URL;
  let secondURL = dowloadUrl;
  const javaApis = ['/pandora-company-bff', '/pandora-event-bff', '/pandora-noyaxe-bff', '/pandora-news-bff', '/pandora-supply-chain-bff','/pandora-search-bff','/pandora-price-event-bff','/pandora-theme-bff','/pandora-risk-management-bff','/pandora-daily-flash-bff','/pandora-chat-bff']
  javaApis.forEach(item => {
    if(secondURL.indexOf(item) > -1){
      firstURL = 'https://os-bff-dev-cn1.1stepai.cn'
    }
  });
  Axios({
    url:firstURL + secondURL,
    method: 'post',
    responseType:'arraybuffer',
    headers: {
      'token': token,
      'Authorization': `Bearer ${token}`
    },
    data:{
      ...params,
      requestId: uuidv4()
    }
  }).then((res)=>{
    const content = res.data
      const blob = new Blob([content],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})//构造一个blob对象来处理数据
      const fileName = name + '.xlsx'

      //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
      //IE10以上支持blob但是依然不支持download
      if ('download' in document.createElement('a')) { //支持a标签download的浏览器
        const link = document.createElement('a')//创建a标签
        link.download = fileName//a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()//执行下载
        URL.revokeObjectURL(link.href) //释放url
        document.body.removeChild(link)//释放标签
      } else { //其他浏览器
        FileSaver.saveAs(blob, fileName)
      }
  })
}