import { combineReducers, configureStore, ThunkAction, Action, AnyAction} from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'reduxjs-toolkit-persist/lib/storage'
import dashboardReducer from '../components/dashboard/reducer';
import lobbyReducer from '../components/lobby/reducer';
import userActionReducer from '../components/user-action/reducer';
import userReducer from '../components/user/reducer';
import snackbarReducer from '../components/snackbar/reducer';
import dailyFlashReducer from '../components/rooms/daliy-flash/reducer';
import bigThemeReducer from '../components/rooms/big-theme/reducer';
import watchListReducer from '../components/watch-list/reducer';
import financeReportReducer from '../components/rooms/financial-report/reducer';
import themeMapReducer from '../components/rooms/theme-map/reducer';
import dialogReducer from '../components/dialog/reducer';
import priceEventReducer from '../components/rooms/price-event/reducer';
import searchReducer from '../components/search/reducer';
import floorReducer from '../components/floor/reducer';
import hejunReducer from '../components/rooms/hejun/reducer';
import hejunNewsReducer from '../components/rooms/hejun-news/reducer';
import noyaxeReducer from '../components/rooms/noyaxe/reducer';
import portfolioDashboardReducer from '../components/rooms/portfolio-dashboard/reducer';
import newsReducer from '../components/news/reducer';
import riskManagementReducer from '../components/rooms/risk-management/reducer';
import pandoraSearchReducer from '../components/pandora-search/reducer';
import supplyChainReducer from '../components/rooms/supply-chain/reducer';
import investmentFinancingMonitorReducer from '../components/rooms/investment-financing-monitor/reducer';
import policyAnalysisReducer from '../components/rooms/policy-analysis/reducer';
import marketOpinionReducer from '../components/rooms/market-opinion/reducer';
import huakongReducer from '../components/rooms/huakong/reducer';


const persistConfig = {
  key: 'pandora-root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['dashboard', 'user']
};

const rootReducers = combineReducers({
  dashboard: dashboardReducer,
  dailyFlash: dailyFlashReducer,
  lobby: lobbyReducer,
  userActions: userActionReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  bigTheme: bigThemeReducer,
  watchList: watchListReducer,
  financeReport: financeReportReducer,
  themeMap: themeMapReducer,
  dialog: dialogReducer,
  priceEvent: priceEventReducer,
  search: searchReducer,
  floor: floorReducer,
  hejun: hejunReducer,
  hejunNews: hejunNewsReducer,
  portfolioDashboard: portfolioDashboardReducer,
  news: newsReducer,
  riskManagement: riskManagementReducer,
  pandoraSearch: pandoraSearchReducer,
  noyaxe: noyaxeReducer,
  supplyChain: supplyChainReducer,
  investmentFinancingMonitor:investmentFinancingMonitorReducer,
  policyAnalysis:policyAnalysisReducer,
  marketOpinion:marketOpinionReducer,
  huakong:huakongReducer
})

const _persistedReducer = persistReducer<any,AnyAction>(persistConfig, rootReducers);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER
        ],
      }
    })
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  any,
  Action<string>
>;
export interface ActionReducer<T, V extends Action = Action> {
  (state: T | undefined, action: V): T;
}

