import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import pandora from '../../api';
import {checkToken} from '../user/reducer';
import moment from 'moment';
import {PAGE_SIZE} from '../../types/constant';

import {enqueueSnackbar} from '../snackbar/reducer';

interface DataProps {
  activedBusinessId:number,
  activedTagsBusinessId: Array<any>,
  activedCompanyId: number,
}

interface StateProps {
  activeCompanyInfo:any,
  nodeList:Array<any>,
  searchCompanyList:Array<any>,
  searchHistoryList:Array<any>,
  nodeDetails:any,
  activeTab:any,
  nodeKeywordMatch:Array<any>,
  nodeTypes:Array<any>,
  loading: {
    [key: string]: boolean;
  },
  noData:boolean,
  nodeData:any,
  refresh:boolean,
  activeNodeId:string,
  nodeListOptions:{
    total: number, 
    hasMore?: boolean,
    size:number,
    page?: number
  },
  sortingInfo:{
    sortList:Array<any>,  
    sorting:Array<any>,  
    order:string,
    sortType:string,
  },
  isCompanySearch:boolean,
  opportunities: any,
  eventDetails: any,
  eventFromDate:any
  eventEndDate:any,
  reportYears:Array<any>,
  reportMainBusiness:Array<any>,
  reportUrl:any,
  reportFuture:Array<any>,
  reportCoreCompetence:Array<any>,
  companyResearch:{
    project:any,
    employees:any,
    spending:any,
  },
  reportKeyWord:any,
  reportKeyWordList:Array<any>,
  researchList:Array<any>,
  reportInvestment: Array<any>,
  reportCAGR:Array<any>,
  reportCrnTab: Array<any>,
  reportCrn: {
    [key: string]: Array<any>;
  },
  researchUrl:string,
  //mainbusiness
  searchList:{
    company: {
      dataList: Array<any>,
      watchlist: any
    },
    business: {
      dataList: Array<any>,
    },
    searchActiveOption: any,
    searchTagsActiveOption: Array<any>
  },
  isBusinessSearching: boolean,
  isCompanySearching: boolean,
  company:{
    activedBusinessId: number;
    activedCompanyId: number;
    activedTagsBusinessId: Array<any>,
    dataList:{
      [key: number]: {
        dataList: Array<any>,
        total: number,
        page: number,
        hasMore?: boolean
      }
    },
  },
  reportDetails: {
    [key: string]: any
  },
  dataType: string,
  business:{
    activedBusinessId: number,
    activedCompanyId: number,
    activedTagsBusinessId: Array<any>,
    dataList: {
      dataList: Array<any>,
      total: number,
      page: number,
      hasMore?: boolean
    }
  },
  searchLoading: boolean,
  searchStatus: boolean,
  selectType:string,
  listShow:boolean,
  questionChildObj: {
    [key: string]:  Array<any>;
  },
  questionTitleArr:Array<any>,
  questionTitle: {
    [key: string]: any;
  },
  industryQuestion:Array<any>,
  industryEndwordSearchType:boolean,
  allQuestionItem:Array<any>,
  industryKeywordAnswer:any,
  childquestionType:Array<any>,
  industryKeyWord:any,
  questionAnswer: {
    [key: string]: any;
  },
  generateType:boolean,
  eventDriven:any,
  socialMedia:any,
  policyDriven:any,
  aiList:any
}

const initialState: StateProps = {
  activeCompanyInfo:{},
  nodeList:[],
  searchCompanyList:[],
  searchHistoryList:[],
  loading: {},
  nodeKeywordMatch:[],
  nodeTypes:[],
  activeTab:null,
  nodeDetails: {},
  nodeData:{},
  refresh:false,
  activeNodeId:'',
  noData:false,
  sortingInfo:{
    sortList:[{
      format:null,
      key:'score',
      order:'DESC'
    }],  
    sorting:[{columnName:'score',direction:'desc'}],  
    order:'desc',
    sortType:'score',
  },
  nodeListOptions:{
    total: 0, 
    size: 30,
    hasMore: true,
    page:0
  },
  isCompanySearch: false,
  opportunities: {},
  eventDetails:{},
  eventFromDate:null,
  eventEndDate:null,
  reportYears:[],
  reportMainBusiness:[],
  reportUrl:'',
  reportFuture:[],
  reportCoreCompetence:[],
  companyResearch:{
    project:{},
    employees:{},
    spending:{},
  },
  reportKeyWord:'',
  reportKeyWordList:[],
  researchList:[],
  reportInvestment: [],
  reportCAGR:[],
  reportCrnTab: [],
  reportCrn: {},
  researchUrl: '',
  //mainbusiness
  searchList: {
    company: {
      dataList: [],
      watchlist: null
    },
    business:{
      dataList: []
    },
    searchActiveOption:{},
    searchTagsActiveOption:[]
  },
  reportDetails: {},
  company: {
    activedBusinessId: 0,
    activedCompanyId: 0,
    activedTagsBusinessId: [],
    dataList: {},
  },
  dataType: '',
  isBusinessSearching: false,
  isCompanySearching: false,
  business:{
    activedTagsBusinessId: [],
    activedBusinessId: 0,
    activedCompanyId: 0,
    dataList:  {
      dataList: [],
      total: 0,
      page: 0
    }
  },
  searchLoading: false,
  searchStatus: false,
  selectType:'',
  listShow: false,
  questionChildObj:{},
  questionTitle: {},
  questionTitleArr:[],
  industryQuestion:[],
  industryEndwordSearchType:false,
  allQuestionItem:[],
  industryKeywordAnswer:{},
  childquestionType:[],
  industryKeyWord:{
    s:'',e:''
  },
  questionAnswer: {},
  generateType:false,
  eventDriven:{
    companyEventItemList:[],
    totalEventCount:0
  },
  socialMedia:{
    dataList:[],
    total:0
  },
  policyDriven:{
    total:0,
    dataList:[]
  },
  aiList:{
    total:0,
    dataList:[]
  },
}

export const searchCompany = createAsyncThunk(
  '/pandora-search/search-comapny',
  async (params:any, thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.searchNewCompany({...params, page: 0, size:30}))
)

export const searchCompanyInfo = createAsyncThunk(
  '/pandora-search/search-comapny-info',
  async (params:any, thunkAPI) => {
    const keyWords = params.keyWords
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await  pandora.searchCompanyInfo({...params, page: 0, size:10})
      return {
        keyWords:keyWords,
        ...result
      }
    })
  }
)

export const fetchSearchNode = createAsyncThunk(
  '/bigTheme/fetch-search-node',
  async (params:any, thunkAPI) => {
    const type = params.type
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchSearchNode(params);
      return {
        type:type,
        ...result
      }
    })
  }
)

export const fetchNodeKeywordMatch = createAsyncThunk(
  '/bigTheme/fetch-node-keyWord',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchNodeKeywordMatch(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetSubNodeTypes = createAsyncThunk(
  '/bigTheme/fetch-sub-node-types',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetSubNodeTypes(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetNodeList = createAsyncThunk(
  '/bigTheme/fetch-get-node-list',
  async (params:any, thunkAPI) => {
    const type = params.subNodeType
    const {page} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetNodeList(params);
      return {
        type:type,
        page,
        ...result
      }
    })
  }
)



export const fetchGetNodeDetail = createAsyncThunk(
  '/bigTheme/fetch-get-node-details',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetNodeDetail(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetEventDetail = createAsyncThunk(
  '/supply-chain/fetch-get-event-detail',
  async (params:any, thunkAPI) => {
    const {eventId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDetail(params);
      return {
        ...result,
        eventId,
      }
    })
  }
)

export const fetchGetEventDataListByCompanyEvent = createAsyncThunk(
  '/pandora-search/fetch-get-event-data-list-by-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDataListByCompanyEvent(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetReportYears = createAsyncThunk(
  '/pandora-search/fetch-get-report-years',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportYears(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetReportMainBusiness = createAsyncThunk(
  '/pandora-search/fetch-get-report-main-business',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportMainBusiness(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetReportUrl = createAsyncThunk(
  '/pandora-search/fetch-get-report-url',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportUrl(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetCoreCompetenceAndFuture = createAsyncThunk(
  '/pandora-search/fetch-get-core-competence-and-future',
  async (params:any, thunkAPI) => {
    const {type} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCoreCompetenceAndFuture(params);
      return {
        ...result,
        type
      }
    })
  }
)

export const fetchGetCompanyResearch = createAsyncThunk(
  '/pandora-search/fetch-get-company-research',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyResearch(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetReportByKeyword = createAsyncThunk(
  '/pandora-search/fetch-get-report-by-keyword',
  async (params:any, thunkAPI) => {
    const {keyword} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportByKeyword(params);
      return {
        ...result,
        keyword
      }
    })
  }
)


export const fetchGetResearchList = createAsyncThunk(
  '/pandora-search/fetch-get-research-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetResearchList(params);
      return {
        ...result
      }
    })
  }
)


export const fetchCompanyBusinessList = createAsyncThunk(
  'pandora-search/fetch-business-list', 
  async (params:any, thunkAPI):Promise<any> => {
    const rootState = thunkAPI.getState() as RootState;
    const {page, size} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchGetCompanyBusiness({...params, companyId: rootState.pandoraSearch.company.activedCompanyId});
      return {
        dataList:data,
        companyId: rootState.pandoraSearch.company.activedCompanyId,
        page,
        hasMore: !Boolean(data.dataList < size)
      }
    })
  }
)
export const fetchGetReportInvestment = createAsyncThunk(
  '/pandora-search/fetch-get-report-investment',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportInvestment(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetReportCAGR = createAsyncThunk(
  '/pandora-search/fetch-get-report-CAGR',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportCAGR(params);
      return {
        ...result
      }
    })
  }
)
export const fetchBusinessReport = createAsyncThunk(
  'pandora-search/fetch-get-company-report-business',
  async (params:any, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const dataType = rootState.pandoraSearch.dataType;
    if(dataType === 'company'){
      params.companyId = rootState.pandoraSearch.company.activedCompanyId
    }
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyReportBusiness(params)
      return {
        ...result,
        ...params
      }; 
    })
  } 
)

export const getBusinessList = createAsyncThunk(
  'pandora-search/fetch-financial-report',
  async (params: any, thunkAPI):Promise<any> => {
    const rootState = thunkAPI.getState() as RootState;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchGetCompanyListByBusiness({...params, businessIdList: rootState.pandoraSearch.business.activedTagsBusinessId});
      return {
        ...data, 
        page: params.page,
        hasMore: !Boolean(data.dataList.length < params.size)
      } 
    })
  }
)


export const fetchGetReportCRn = createAsyncThunk(
  '/pandora-search/fetch-get-report-CRn',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportCRn(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetResearchUrl = createAsyncThunk(
  '/pandora-search/fetch-get-research-url',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetResearchUrl(params);
      return {
        ...result
      }
    })
  }
)
export const searchBusiness = createAsyncThunk(
  '/pandora-search/search-business',
  async (params:any) => pandora.fetchSearchBusiness({...params, page: 0, size: PAGE_SIZE*2, })
)

// 通过问题和关键字查询回答
export const fetchGetAnswerByIndustryNameAndKeyword = createAsyncThunk(
  'pandora-search/fetchGetAnswerByIndustryNameAndKeyword',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetAnswerByIndustryNameAndKeyword(params))
  }
)
// 通过问题查询回答
export const fetchGetAnswerByQuestion = createAsyncThunk(
  'pandora-search/fetchGetAnswerByQuestion',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetAnswerByQuestion(params))
  }
)
// 查询所有问题是否回答完成
export const fetchGetAnswerStatusByQuestion = createAsyncThunk(
  'pandora-search/fetchGetAnswerStatusByQuestion',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetAnswerStatusByQuestion(params))
  }
)
// 通过问题查询子问题
export const fetchGetQuestionByQuestion = createAsyncThunk(
  'pandora-search/fetchGetQuestionByQuestion',
  async (params:any, thunkAPI) => {
    const id = params.id
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetQuestionByQuestion(params);
      return {
        id:id,
        ...result
      }
    })
  }
)

// 通过问题查询更多子问题
export const fetchGetChildQuestion = createAsyncThunk(
  'pandora-search/fetch-get-child-question',
  async (params:any) => pandora.fetchGetQuestionByQuestion(params)
);


// 通过行业查询一级问题
export const fetchGetQuestionByIndustryName = createAsyncThunk(
  'pandora-search/fetch-get-question-by-industry-name',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetQuestionByIndustryName(params))
  }
)

// 查询子问题是否加载完成
export const fetchGetSubQuestionStatusByPid = createAsyncThunk(
  'pandora-search/fetch-get-sub-question-status-by-pid',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetSubQuestionStatusByPid(params))
  }
)

//新增问题
export const fetchAddQuestion = createAsyncThunk(
  'pandora-search/fetch-add-question',
  async (params:any, thunkAPI) => {
    const pid = params.pid
    const ppid = params.ppid
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchAddQuestion(params);
      return {
        pid:pid,
        ppid:ppid,
        ...result
      }
    })
  }
)

//新增主问题问题
export const fetchAddAdminQuestion = createAsyncThunk(
  'pandora-search/fetch-add-admin-question',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchAddQuestion(params))
  }
)

//删除问题
export const fetchDeleteQuestion = createAsyncThunk(
  'pandora-search/fetch-delete-question',
  async (params:any, thunkAPI) => {
    const id = params.id
    const pid  = params.pid
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchDeleteQuestion(params);
      return {
        id:id,
        pid:pid,
        ...result
      }
    })
  }
)

//更新问题
export const fetchUpdateQuestion = createAsyncThunk(
  'pandora-search/fetch-update-question',
  async (params:any, thunkAPI) => {
    const id = params.id
    const pid = params.pid
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchUpdateQuestion(params);
      return {
        pid:pid,
        id:id,
        ...result
      }
    })
  }
)

export const fetchGenerateKnowledgeBasen = createAsyncThunk(
  'pandora-search/fetchGenerateKnowledgeBasen',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGenerateKnowledgeBasen(params))
  }
)

export const addComment = createAsyncThunk(
  'pandora-search/add-comment',
  async (params:any, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const dataType = rootState.pandoraSearch.dataType;
    const companyId = rootState.pandoraSearch[dataType === 'company' ? 'company' : 'business'].activedCompanyId;
    const businessId = rootState.pandoraSearch[dataType === 'company' ? 'company' : 'business'].activedBusinessId;
    return thunkAPI.dispatch(checkToken()).then(resp => {
      return pandora.addReportBusinessComment({...params, companyId: companyId}).then((resp:any) => {
        thunkAPI.dispatch(enqueueSnackbar({message: 'Add Comment Success', key:'addCommentSuccess',options:{variant: 'success'}}))
        return thunkAPI.dispatch(fetchBusinessReport({companyId: companyId, businessTypeId: businessId}))
      })
    })
  }
)
export const deleteComment = createAsyncThunk(
  'pandora-search/delete-comment',
  async(params: any, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const dataType = rootState.pandoraSearch.dataType;
    const companyId = rootState.pandoraSearch[dataType === 'company' ? 'company' : 'business'].activedCompanyId;
    const businessId = rootState.pandoraSearch[dataType === 'company' ? 'company' : 'business'].activedBusinessId;
    return thunkAPI.dispatch(checkToken()).then(resp => {
      return pandora.removeReportBusinessComment({...params}).then((resp:any) => {
        thunkAPI.dispatch(enqueueSnackbar({message: 'remove Comment Success', key:'removeCommentSuccess',options:{variant: 'success'}}))
        return thunkAPI.dispatch(fetchBusinessReport({companyId: companyId, businessTypeId: businessId}))
      })
    })
  }
)

//事件驱动
export const getCompanyListWithEventRank = createAsyncThunk(
  'pandora-search/getCompanyListWithEventRank',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getCompanyListWithEventRank(params))
  }
)

//社交媒体统计
export const tocSocialMedia = createAsyncThunk(
  'pandora-search/tocSocialMedia',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.tocSocialMedia(params))
  }
) 

// 政策驱动
export const policyDrivenList = createAsyncThunk(
  'pandora-search/policyDrivenList',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.policyDrivenList(params))
  }
) 

//政策操作
export const adminOperate = createAsyncThunk(
  'pandora-search/adminOperate',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.adminOperate(payload))
);
// 事件置顶
export const markCompanyTop = createAsyncThunk(
  'pandora-search/markCompanyTop',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.markCompanyTop(payload))
);
// 事件隐藏
export const markCompanyVisible = createAsyncThunk(
  'pandora-search/markCompanyVisible',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.markCompanyVisible(payload))
);



// 获取公司信息
export const fetchGetCompanyInformation = createAsyncThunk(
  'pandora-search/fetchGetCompanyInformation',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetCompanyInformation(payload))
);

// 获取 ai 模型列表
export const getCompanyScoreRank = createAsyncThunk(
  'pandora-search/fetchgetCompanyScoreRank',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.getCompanyScoreRank(payload))
);
//政策操作
export const updateCompanyScoreRankStatus = createAsyncThunk(
  'pandora-search/updateCompanyScoreRankStatus',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.updateCompanyScoreRankStatus(payload))
);


export const pandoraSearch = createSlice({
  name:'pandoraSearch',
  initialState,
  reducers: {
    setActiveCompanyInfo: (state, {payload}) => {
      state.activeCompanyInfo = payload;
    },
    setNodeList: (state, {payload}) => {
      state.nodeList = payload;
    },
    setNodeDetail: (state, {payload}) => {
      state.nodeDetails = payload;
    },
    setactiveTab: (state, {payload}) => {
      state.activeTab = payload;
    },
    setNodeTypes: (state, {payload}) => {
      state.nodeKeywordMatch = payload;
    },
    setRefresh: (state, {payload}) => {
      state.refresh = payload;
    },
    setActiveNodeId: (state, {payload}) => {
      state.activeNodeId = payload;
    },
    resetNodeListOptions: (state, {payload}) => {
      state.nodeListOptions = payload;
    },
    setSortList: (state, {payload}) => {
      state.sortingInfo.sortList = payload;
    },
    setSorting: (state, {payload}) => {
      state.sortingInfo.sorting = payload;
    },
    setSortOder: (state, {payload}) => {
      state.sortingInfo.order = payload;
    },
    setSortType: (state, {payload}) => {
      state.sortingInfo.sortType = payload;
    },
    setTab: (state, {payload}) => {
      state.nodeTypes = payload;
    },
    setNodeData: (state, {payload}) => {
      state.nodeData = payload;
    },
    setIsCompanySearch: (state, {payload}) => {
      state.isCompanySearch = payload;
    },
    setEventFromDate: (state, {payload}) => {
      state.eventFromDate = payload;
    },
    setEventEndDate: (state, {payload}) => {
      state.eventEndDate = payload;
    },
    setReportKeyWord: (state, {payload}) => {
      state.reportKeyWord = payload;
    },
    setActiveId: (state, {payload}) => {
      const {dataType, id ,businessTypeId} = payload;
      if(dataType === 'company'){
        state.company.activedBusinessId = id
      }else{
        state.business.activedBusinessId = businessTypeId
        state.business.activedCompanyId = id
      }
    },
    setNewSearchStatus: (state, {payload}) => {
      state.searchStatus = payload;
    },
    setListShow:(state, {payload}) => {
      state.listShow = payload
    },
    setBusinessSearchStatus: (state, {payload}) => {
      state.isBusinessSearching = payload;
    },
    setCompanySearchStatus: (state, {payload}) => {
      state.isCompanySearching = payload;
    },
    setSearchType: (state, {payload}) => {
      state.dataType = payload;
    },
    setSearchTagsActiveItem: (state, {payload}) => {
      state.searchList.searchTagsActiveOption.push(payload)
    },
    setSearchId: (state, {payload}) => {
      const {dataType, id} = payload;
      if(dataType === 'company'){
        state.company.activedCompanyId = id
      }else{
        state.business.activedTagsBusinessId = id
      }
    },
    setSearchActiveItem: (state, {payload}) => {
      state.searchList.searchActiveOption = payload
    },
    setSelectType: (state, {payload}) => {
      state.selectType = payload;
    },
    setActiveCompany: (state, {payload}) => {
      state.company.activedCompanyId = payload;
    },
    setBusinessList: (state, {payload}) => {
      state.business.dataList = payload
    },
    setCompanyList: (state, {payload}) => {
      state.company.dataList = payload
    },
    setQuestionTitle: (state, {payload}) => {
      if(state.questionTitle[payload.id]){
        state.questionTitle[payload.id].content = state.questionTitle[payload.id].content + payload.content
      }else{
        state.questionTitleArr =  state.questionTitleArr.concat([payload.id])
        state.questionTitle[payload.id] = payload
      }
    },
    setIndustryEndwordSearchType : (state, {payload}) => {
      state.industryEndwordSearchType= payload
    },
    setRestIndustry : (state, {payload}) => {
      state.industryQuestion = []
      state.allQuestionItem = []
      state.industryKeywordAnswer = {}
      state.questionTitleArr = []
      state.childquestionType = []
      state.questionChildObj = {}
      state.questionAnswer ={}
      state.generateType = false
      state.industryEndwordSearchType = false
    },
    setIndustryKeyWord : (state, {payload}) => {
      state.industryKeyWord = payload
    },
    setQuestionAnswer: (state, {payload}) => {
      payload.forEach((item:any) => {
        item.answer = item.answer?.replaceAll('\n','<br />')
        item.edit=false;
        item.add=false;
        item.updateLoading = false;
        item.addLoading = false;
        item.newQuestion='';
        item.keyWord=item.question;
        if(state.questionAnswer[item.pid]){

          if(!state.questionAnswer[item.pid].some((y:any) => y.id === item.id)){
            state.questionAnswer[item.pid].push(item)
          }
        }else{
          state.questionAnswer[item.pid] = [item]
        }
      });
     
    },
    setQuestionAdd: (state, {payload}) => {
      const {item} = payload
      if(item.pid !== '0' ){
        const index = state.questionAnswer[item.pid].findIndex((info:any) => info.id === item.id )
        if(index>-1){
          if(state.questionAnswer[item.pid][index].add) {
            state.questionAnswer[item.pid][index].add = false
          }else{
            state.questionAnswer[item.pid][index].add = true
          }
        }
      }else{
        const index = state.industryQuestion.findIndex((info:any) => info.id === item.id )
        if(index>-1){
          if(state.industryQuestion[index].add) {
            state.industryQuestion[index].add = false
          }else{
            state.industryQuestion[index].add = true
          }
        }
      }
      
    },
    setQuestionEdit: (state, {payload}) => {
      const {type,item} = payload
      if(item.pid !== '0'){
        const index = state.questionAnswer[item.pid].findIndex((info:any) => info.id === item.id )
        if(index>-1){
          if(type === 'add' && state.questionAnswer[item.pid][index].edit){
            return
          }
          if(state.questionAnswer[item.pid][index].edit) {
            state.questionAnswer[item.pid][index].edit = false
            state.questionAnswer[item.pid][index].keyWord = ''
          }else{
            state.questionAnswer[item.pid][index].edit = true
            state.questionAnswer[item.pid][index].keyWord = item.question
          }
  
        }
      }else{
        const index = state.industryQuestion.findIndex((info:any) => info.id === item.id )
        if(index>-1){
          if(type === 'add' && state.industryQuestion[index].edit){
            return
          }
          if(state.industryQuestion[index].edit) {
            state.industryQuestion[index].edit = false
            state.industryQuestion[index].keyWord = ''
          }else{
            state.industryQuestion[index].edit = true
            state.industryQuestion[index].keyWord = item.question
          }
        }
      }
     

    },
    setNewQuestion: (state, {payload}) => {
      const {keyword,item} = payload
      if(item.pid !== '0'){
        const index = state.questionAnswer[item.pid].findIndex((info:any) => info.id === item.id )
        if(index>-1){
          state.questionAnswer[item.pid][index].newQuestion = keyword
        }
      }else{
        const index = state.industryQuestion.findIndex((info:any) => info.id === item.id )
        if(index>-1){
          state.industryQuestion[index].newQuestion = keyword
        }
      }
      
    },
    setQuestionKeyWord: (state, {payload}) => {
      const {keyword,item} = payload
      if(item.pid !== '0'){
        const index = state.questionAnswer[item.pid].findIndex((info:any) => info.id === item.id )
        if(index>-1){
          state.questionAnswer[item.pid][index].keyWord = keyword
        }
      }else{
        const index = state.industryQuestion.findIndex((info:any) => info.id === item.id )
        if(index>-1){
          state.industryQuestion[index].keyWord = keyword
        }
      }
      
    },
    setGenerateType: (state, {payload}) => {
      state.generateType = payload
    },
    setEventMarkedTop: (state, {payload}) => {
      const index = state.eventDriven.companyEventItemList.findIndex((item:any) => item.companyId === payload.companyId )
      if(payload.markedTop){
        const opObj = state.eventDriven.companyEventItemList[index]
        const untopIndex = state.eventDriven.companyEventItemList.findIndex((item:any) => item.markedTop === false )
        console.log('untopIndex',untopIndex)
        let arr = state.eventDriven.companyEventItemList
        arr.splice(index,1)
        opObj.markedTop = !payload.markedTop
        if(untopIndex === -1){
          arr.push(opObj); 
        }else{
          arr.splice(untopIndex-1, 0, opObj); 
        }
        state.eventDriven.companyEventItemList = arr
      }else{
        const opObj = state.eventDriven.companyEventItemList[index]
        opObj.markedTop = !payload.markedTop
        let arr = state.eventDriven.companyEventItemList
        arr.splice(index,1)
        arr.unshift(opObj)
        state.eventDriven.companyEventItemList = arr
      }
    },
    setEventHide: (state, {payload}) => {
      let arr = state.eventDriven.companyEventItemList
      arr.splice(state.eventDriven.companyEventItemList.findIndex((item:any) => item.companyId ===  payload.companyId), 1)
      state.eventDriven.companyEventItemList= arr
    },
    setAiMarkedTop: (state, {payload}) => {
      const index = state.aiList.dataList.findIndex((item:any) => item.id === payload.id )
      if(payload.top){
        const opObj = state.aiList.dataList[index]
        const untopIndex = state.aiList.dataList.findIndex((item:any) => !item.top )
        let arr = state.aiList.dataList
        arr.splice(index,1)
        opObj.top = 0
        if(untopIndex === -1){
          arr.push(opObj); 
        }else{
          arr.splice(untopIndex-1, 0, opObj); 
        }
        state.aiList.dataList = arr
      }else{
        const opObj = state.aiList.dataList[index]
        opObj.top = 1
        let arr = state.aiList.dataList
        arr.splice(index,1)
        arr.unshift(opObj)
        state.aiList.dataList = arr
      }
    },
    setAiHide: (state, {payload}) => {
      let arr = state.aiList.dataList
      arr.splice(state.aiList.dataList.findIndex((item:any) => item.id ===  payload.id), 1)
      state.aiList.dataList= arr
    },
    setPolicyMarkedTop: (state, {payload}) => {
      const index = state.policyDriven.dataList.findIndex((item:any) => item.id === payload.policyData.id )
      state.policyDriven.dataList[index].top = !payload.policyData.top
      const opObj = state.policyDriven.dataList[index]
      let arr = state.policyDriven.dataList
      arr.splice(index,1)
      arr.unshift(opObj)
      state.policyDriven.dataList = arr

    },
    setPolicyHide: (state, {payload}) => {
      let arr = state.policyDriven.dataList
      arr.splice(state.policyDriven.dataList.findIndex((item:any) => item.id ===  payload.policyData.id), 1)
      state.policyDriven.dataList= arr
    },
    resetPolicyDrivenList: (state, {payload}) => {
      state.policyDriven = {total:0,dataList:[] }
    },
    resetEventDrivenList: (state, {payload}) => {
      state.eventDriven = {companyEventItemList:[],totalEventCount:0}
    },
    resetAiList: (state, {payload}) => {
      state.aiList = {dataList:[],total:0}
    },

    
   
  },
  extraReducers: (builder) => {
    builder.addCase(searchCompany.pending, (state) => {
      state.loading.searchLoading = true;
    }).addCase(searchCompany.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.searchCompanyList = data
      }
      state.loading.searchLoading = false;
    }).addCase(searchCompanyInfo.pending, (state) => {
      state.loading.searchLoading = true;
      state.searchCompanyList= []
    }).addCase(searchCompanyInfo.fulfilled, (state, {payload}) => {
      const {data, keyWords,code} = payload;
      if(code === '0'){
        if(data.historyList){
          if(data.historyList.length){
            data.historyList.forEach((item:any)=>{
              item.keyword = keyWords;
              item.type = '常搜';
            })
          }
          Object.entries(data).map((recommend: [string, any]) => console.log(recommend) )
          state.searchCompanyList = Object.entries(data).map((recommend: [string, any]) => (recommend[1] as Array<any>).map(item => ({
            ...item, 
            type: recommend[0] ==='searchList' ? ''  : '常搜'
          }))).flat();
          state.searchHistoryList = data.historyList;
        }
      }
      state.loading.searchLoading = false;
    }).addCase(fetchGetNodeList.pending, state => {
      state.loading.nodeListLoading = true
    }).addCase(fetchGetNodeList.fulfilled, (state, {payload})=>{
      const {code, data, type , page } = payload;
      if(code === '0'){
        if(type === "technology"){
          const newNodeList:any = []
          if(data.nodes.length){
            for(const i in data.nodes){
              const newobj:any = {};
              newobj.name = data.nodes[i].data.name
              newobj.id = data.nodes[i].id
              if(data.nodes[i].relatedNodes.patent){
                const newpatent = data.nodes[i].relatedNodes.patent.map((item:any) => (
                  item = item.data
                ))
                newobj.patent = newpatent
              }
              if(data.nodes[i].relatedNodes.company){
                const newcompany = data.nodes[i].relatedNodes.company.map((item:any) => (
                  item = item.data
                ))
                newobj.company = newcompany
              }
              newNodeList.push(newobj)
            }
            state.nodeList = newNodeList;
          } 
        }else if(type === "product"){
          const newNodeList:any = [];
          if(data.nodes.length){
            for(const i in data.nodes){
              const newobj:any = {};
              newobj.name = data.nodes[i].data.name
              newobj.id = data.nodes[i].id
              if(data.nodes[i].relatedNodes.company){
                const newcompany = data.nodes[i].relatedNodes.company.map((item:any) => (
                  item = item.data
                ))
                newobj.company = newcompany
              }
              newNodeList.push(newobj)
            }
            state.nodeList = newNodeList;
          }
        }else{
          const newNodeList = data.nodes.length ? data.nodes.map((item:any) => (
            item = item.data
          )) : []
          state.nodeList = newNodeList;
        }
        if(type === 'company'){
          state.nodeListOptions = {
            total:data.total,
            size:30,
            hasMore: !Boolean(data.nodes.length < 30),
            page: page 
          }
        }else{
          state.nodeListOptions = {
            total: 0, 
            size:30,
            hasMore: true,
            page:0
          }
        }
        
        state.loading.nodeListLoading = false
      }
    }).addCase(fetchGetNodeDetail.pending, state => {
      state.loading.nodeDetailoading = true
    }).addCase(fetchGetNodeDetail.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.activeTab = data.type;
        if(data.type === 'technology'){
          data.data.relatedNodes = data.relatedNodes
          state.nodeDetails = data.data;
        }else{
          state.nodeDetails = data.data;
        }
        state.loading.nodeDetailoading = false
      }
    }).addCase(fetchNodeKeywordMatch.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.nodeKeywordMatch = data;
        if( !data[0]?.nodeId ){
          state.noData = true
        }else{
          state.noData = false
        }
      }
    }).addCase(fetchGetSubNodeTypes.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        const newData = data.map((item:any) => (
          { 
            name : item['type'].toUpperCase(),
            nameZh : item['typeZh'],
            key : item
          }
        ))
        state.nodeTypes = newData;
      }
    }).addCase(fetchSearchNode.pending, state => {
      state.loading.searchNodeloading = true
    }).addCase(fetchSearchNode.fulfilled, (state, {payload})=>{
      const {code, data, type} = payload;
      if(code === '0'){
        state.refresh = true
        if(type === 'source'){
          state.nodeData = data;
        }else{
          state.nodeData.nodesList = state.nodeData?.nodesList.concat(data.nodesList || [])
          state.nodeData.relations = state.nodeData?.relations.concat(data.relations || [])
        }
        state.loading.searchNodeloading = false
      }
    }).addCase(fetchGetEventDetail.pending, (state) => {
      state.loading.fetchEventDetailLoading = true
    }).addCase(fetchGetEventDetail.fulfilled, (state, {payload}) => {
      const {code, data, eventId} = payload;
      if(code==='0'){
        const {eventEntity, entityGraph,  ...restResult} = data;
        let entityGraphObj = {
          children:[] as any[],
          name:'',
          id:''
        }
        entityGraph.forEach((item:any) => {
          if(item.master){
            entityGraphObj.id =item.entityId
            entityGraphObj.name = item.entityValueZh
          }else{
            entityGraphObj.children.push({...item, name:item.entityValueZh,type:'child'})
          }
        });
        const normlizedData = eventEntity.reduce((all: {[key: string]: any}, dataItem: any) => {
          const {entityList:currentEntities, type} = dataItem;
          return {
                ...all,
                [type]: currentEntities.map((item:any) => ({...item, company_name_en: item.entityValueEn, company_name_zh: item.entityValueZh})),
              }
        }, {});
        state.eventDetails[eventId] = {
          ...restResult,
          graph:{
            data:entityGraphObj
          },
          entities:{
            data_list: normlizedData
          }
        };
      }
      state.loading.fetchEventDetailLoading = false
    }).addCase(fetchGetEventDataListByCompanyEvent.pending, state => {
      state.loading.getEventDataListByCompany = true
    }).addCase(fetchGetEventDataListByCompanyEvent.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        const {eventTimeList,...restProps} = data;
        const newEventTimeList = eventTimeList.length ? eventTimeList.map((item:any) => ({...item, TimeRanges:  moment(Number(item.updateAt)).format('YYYY MMM DD')})) : []
        
        let tempArr = [];
        let afterData = [];
        for (let i = 0; i < newEventTimeList.length; i++) {
          if (tempArr.indexOf(newEventTimeList[i].TimeRanges) === -1) {
              afterData.push({
              TimeRanges: newEventTimeList[i].TimeRanges,
              time: newEventTimeList[i].newsTimestamp,
              news: [newEventTimeList[i]]
              });
            tempArr.push(newEventTimeList[i].TimeRanges);
          } else {
            for (let j = 0; j < afterData.length; j++) {
              if (afterData[j].TimeRanges === newEventTimeList[i].TimeRanges) {
              afterData[j].news.push(newEventTimeList[i]);
                break;
              }
            }
          }
        }

        state.opportunities = {
          ...restProps,
          eventTimeList:afterData
        }
        state.loading.getEventDataListByCompany = false
      }
    }).addCase(fetchGetReportYears.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.reportYears = data
        if(!data.length){
          state.reportFuture=[]
          state.reportCoreCompetence=[]
          state.companyResearch={
            project:{},
            employees:{},
            spending:{},
          }
          state.researchList =[]
          state.reportInvestment=[]
          state.reportCAGR=[]
          state.reportCrnTab=[]
          state.reportCrn={}
        }
      }
    }).addCase(fetchGetReportMainBusiness.pending, state => {
      state.loading.getReportMainBusiness = true
    }).addCase(fetchGetReportMainBusiness.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.reportMainBusiness = data
      }
      state.loading.getReportMainBusiness = false
    }).addCase(fetchGetReportUrl.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.reportUrl = data
      }
    }).addCase(fetchGetCoreCompetenceAndFuture.pending, state => {
      state.loading.getCoreCompetenceAndFuture = true
    }).addCase(fetchGetCoreCompetenceAndFuture.fulfilled, (state, {payload})=>{
      const {code, type, data} = payload;
      if(code === '0'){
        if(type === 'Future'){
          state.reportFuture = data
        }else{
          state.reportCoreCompetence = data
        }
      }
      state.loading.getCoreCompetenceAndFuture = false
    }).addCase(fetchGetCompanyResearch.pending, state => {
      state.loading.fetchGetCompanyResearch = true
    }).addCase(fetchGetCompanyResearch.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.companyResearch={
          project:{},
          employees:{},
          spending:{},
        }
        data.forEach((item:any) => {
          if(item.type === 'Project'){
            state.companyResearch.project = item
          }
          if(item.type === 'Spending'){
            state.companyResearch.spending = item
          }
          if(item.type === 'Employees'){
            state.companyResearch.employees = item
          }
        });

      }
      state.loading.fetchGetCompanyResearch = false
    }).addCase(fetchGetReportByKeyword.pending, state => {
      state.loading.fetchGetReportByKeyword = true
    }).addCase(fetchGetReportByKeyword.fulfilled, (state, {payload})=>{
      const {code,keyword, data} = payload;
      if(code === '0'){
        const newData = data.map( (item:any) => {
          return{
              page:item.page,
              searchTxt:item.text,
              text:`<div> ${item.text.replace(keyword, `<span style="color:#FF9900">${keyword}</span>`)} </div> `
          }
        })
        state.reportKeyWordList = newData
      }
      state.loading.fetchGetReportByKeyword = false
    }).addCase(fetchGetResearchList.pending, state => {
      state.loading.fetchGetResearchList = true
    }).addCase(fetchGetResearchList.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.researchList = data
        if(!data.length){
          state.reportCAGR=[]
          state.reportCrnTab=[]
          state.reportCrn={}
        }
      }
      state.loading.fetchGetResearchList = false
    }).addCase(fetchGetReportInvestment.pending, state => {
      state.loading.fetchGetReportInvestment = true
    }).addCase(fetchGetReportInvestment.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.reportInvestment = data
      }
      state.loading.fetchGetReportInvestment = false
    }).addCase(fetchGetReportCAGR.pending, state => {
      state.loading.fetchGetReportCAGR = true
    }).addCase(fetchGetReportCAGR.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.reportCAGR = data
      }
      state.loading.fetchGetReportCAGR = false
    }).addCase(fetchGetReportCRn.pending, state => {
      state.loading.fetchGetReportCRn = true
    }).addCase(fetchGetReportCRn.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        const tabArr: any = []
        const newObj: any = {}
        data.forEach((item:any) => {
          tabArr.push(item.type)
          newObj[item.type] = item.data
        });
        state.reportCrnTab = tabArr
        state.reportCrn = newObj
      }
      state.loading.fetchGetReportCRn = false
    }).addCase(fetchGetResearchUrl.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.researchUrl = data
      }
    }).addCase(fetchCompanyBusinessList.fulfilled, (state, {payload}) => {
      const {dataList, companyId, page, total, hasMore} = payload;
        if(page > 0) {
          state.company.dataList[companyId] = {
            dataList: state.company.dataList[companyId].dataList.concat(dataList),
            total: total,
            page,
            hasMore
          }
        }else{
          state.company.dataList[companyId] = {
            dataList,
            page,
            total,
            hasMore
          }
        }
    }).addCase(getBusinessList.fulfilled, (state, {payload}) => {
      const {code, dataList, page, hasMore, total} = payload;
      if(!code){
        if(page > 0){
          state.business.dataList = {
            dataList: state.business.dataList.dataList.concat(dataList), 
            page, 
            hasMore, 
            total 
          } 
        }else{
          state.business.dataList = {dataList, page, hasMore, total } 
        }
      }
    }).addCase(searchBusiness.pending, (state) => {
      state.searchLoading = true;
    }).addCase(searchBusiness.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        const {dataList, ...restDataProps} = data;
        const normlizedData = dataList.map((item:any) => ({...item, companyNameEn: item.nameEn || '', companyNameZh: item.nameZh || ''}))
        state.searchList.business = {
          ...restDataProps,
          dataList: normlizedData
        };
      }
      state.searchLoading = false;
    }).addCase(fetchBusinessReport.fulfilled, (state, {payload}) => {
      const {companyId, businessTypeId, data} = payload;
      state.reportDetails[`report-${companyId}-${businessTypeId}`] = data
    }).addCase(fetchGetQuestionByIndustryName.pending, state => {
      state.loading.fetchGetQuestionByIndustryName = true
      state.industryQuestion = []
      state.allQuestionItem = []
      state.industryKeywordAnswer = {}
      state.questionTitleArr = []
      state.childquestionType = []
      state.generateType = false
      state.questionAnswer ={}
      state.questionChildObj = {}
      state.industryEndwordSearchType = false
      state.loading.fetchGetAnswerByIndustryNameAndKeyword = false
    }).addCase(fetchGetQuestionByIndustryName.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === 0){
        const arr = [] as any
        state.questionAnswer['admin'] = []
        data.forEach((item:any) => {
          item.answer = null
          item.pid = "0";
          item.edit=false;
          item.updateLoading = false;
          item.addLoading = false;
          item.add=false;
          item.newQuestion='';
          item.keyWord=item.question;
          // state.questionAnswer['admin'].push(item)
          for (let i = 0; i < item.question.length; i++) {
            arr.push({id:item.id,question:item.question,len:item.question.length,content:item.question[i],uuid:item.uuid})
          }
        });
        state.allQuestionItem = arr
        state.industryQuestion = data
        
        state.loading.fetchGetQuestionByIndustryName = false
      }
    }).addCase(fetchGetQuestionByQuestion.pending, (state,action) => {
      state.loading[action.meta.arg.pid] = true
    }).addCase(fetchGetQuestionByQuestion.fulfilled, (state, {payload}) => {
      const {code, id, data} = payload;
      if(code === 0){
        data.forEach((item:any) => {
          item.answer = item.answer?.replaceAll('\n','<br />')
          item.pid = id;
          item.edit=false;
          item.add=false;
          item.newQuestion='';
          item.updateLoading = false;
          item.addLoading = false;
          item.keyWord=item.question;
          if(state.questionAnswer[id]){
            if(state.questionAnswer[id].findIndex((info:any) => info.id === item.id) < 0){
              state.questionAnswer[id].push(item)
            }
          }else{
            state.questionAnswer[id] = [item]
          }
        });
        state.questionChildObj[id] = data
        state.loading[id] = false
      }      
    }).addCase(fetchGetAnswerStatusByQuestion.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === 0){
        state.industryEndwordSearchType = data
      }
    }).addCase(fetchGetAnswerByIndustryNameAndKeyword.pending, (state,action) => {
      state.loading.fetchGetAnswerByIndustryNameAndKeyword = true
    }).addCase(fetchGetAnswerByIndustryNameAndKeyword.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === 0){
        if(state.industryKeyWord.e === data.keyword && state.industryKeyWord.s === data.industryName){
          data.answer = data.answer?.replaceAll('\n','<br />')
          state.industryKeywordAnswer = data
        }else{
          state.industryKeywordAnswer = {}
        }
        state.loading.fetchGetAnswerByIndustryNameAndKeyword = false
      }
    }).addCase(fetchGetSubQuestionStatusByPid.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === 0){
        state.childquestionType = data
      }
    }).addCase(fetchAddQuestion.pending, (state,action) => {
      if(action.meta.arg.ppid === '0'){
        const index = state.industryQuestion.findIndex((info:any) => info.id === action.meta.arg.pid)
        if(index>-1){
          state.industryQuestion[index].addLoading = true
        }
      }else{
        const index = state.questionAnswer[action.meta.arg.ppid].findIndex((info:any) => info.id === action.meta.arg.pid)
        if(index>-1){
          state.questionAnswer[action.meta.arg.ppid][index].addLoading = true
        }
      }
      // state.loading[action.meta.arg.ppid] = true
     
    }).addCase(fetchAddQuestion.fulfilled, (state, {payload}) => {
      const {code, pid,ppid,data} = payload;
      if(code === 0){
          data.edit=false;
          data.add=false;
          data.updateLoading = false;
          data.addLoading = false;
          data.newQuestion='';
          data.keyWord=data.question;
          data.answer = data.answer?.replaceAll('\n','<br />')
        if(ppid !== '0'){
          if(state.questionAnswer[data.pid]){
            state.questionAnswer[data.pid].unshift(data)
          }else{
            state.questionAnswer[data.pid] = [data]
          }
          const index = state.questionAnswer[ppid].findIndex((info:any) => info.id === pid)
          if(index>-1){
            state.questionAnswer[ppid][index].add = false
            state.questionAnswer[ppid][index].newQuestion = ''
            state.questionAnswer[ppid][index].addLoading = false
          }
        }else{
        
          if(state.questionAnswer[data.pid]) {
            state.questionAnswer[data.pid].unshift(data)
          }else{
            state.questionAnswer[data.pid]=[data]
          }
          const index = state.industryQuestion.findIndex((info:any) => info.id === pid)
          if(index>-1){
            state.industryQuestion[index].add = false
            state.industryQuestion[index].newQuestion = ''
            state.industryQuestion[index].addLoading = false
          }
            
        }
      }
    }).addCase(fetchDeleteQuestion.fulfilled, (state, {payload}) => {
      const {code, pid,id} = payload;
      if(code === 0){
        const idArr = [id] as any;
        const arrFun = (id:any) =>{
          if(state.questionAnswer[id]){
            state.questionAnswer[id].forEach((item:any) => {
              if(state.questionAnswer[item.id]){
                arrFun(item.id)
                idArr.push(item.id)
              }
            });
          }
        }
        arrFun(id)
        idArr.forEach((id:any) => {
          state.questionAnswer[id] = []
        });
        if(pid !== '0'){
          let arr = state.questionAnswer[pid]
          arr.splice(state.questionAnswer[pid].findIndex((item:any) => item.id === id), 1)
          state.questionAnswer[pid] = arr
          const newObj = JSON.parse(JSON.stringify(state.questionAnswer))
          delete newObj[id]
          state.questionAnswer = newObj
        }else{
          let arr = state.industryQuestion.reduce(function(prev:any, cur:any) {
            if(cur.id !== id){
              prev.push(cur)
            }
              return prev;
          }, []);
          state.industryQuestion = arr
        }
      }
    }).addCase(fetchUpdateQuestion.pending, (state,action) => {
      if(action.meta.arg.pid !== '0'){
        const index = state.questionAnswer[action.meta.arg.pid].findIndex((info:any) => info.id === action.meta.arg.id)
        if(index>-1){
          state.questionAnswer[action.meta.arg.pid][index].updateLoading = true
        }
      }else{
        state.generateType = false;
        state.industryEndwordSearchType = false
        state.industryKeywordAnswer = {}
        state.industryKeyWord['e'] = ''
        state.questionChildObj[action.meta.arg.id] = []
        const index = state.industryQuestion.findIndex((info:any) => info.id === action.meta.arg.id)
        if(index>-1){
          state.industryQuestion[index].updateLoading = true
        }
      }
      
    }).addCase(fetchUpdateQuestion.fulfilled, (state, {payload}) => {
      const {code,pid,id,data} = payload;
      if(code === 0){
          data.edit=false;
          data.add=false;
          data.updateLoading = false;
          data.addLoading = false;
          data.newQuestion='';
          data.keyWord=data.question;
        if(pid !== '0'){
          const index = state.questionAnswer[pid].findIndex((info:any) => info.id === id)
          if(index>-1){
            state.questionAnswer[pid][index].updateLoading = false
          }
          data.answer = data.answer?.replaceAll('\n','<br />')
          state.questionAnswer[pid][index] = data
        }else{
          const index =state.industryQuestion.findIndex((info:any) => info.id === id)
          if(index>-1){
            state.industryQuestion[index].updateLoading = false
          }
          data.answer = null
          state.industryQuestion[index] = data
        }
        const idArr = [id] as any;
          const arrFun = (id:any) =>{
            if(state.questionAnswer[id]){
              state.questionAnswer[id].forEach((item:any) => {
                if(state.questionAnswer[item.id]){
                  arrFun(item.id)
                  idArr.push(item.id)
                }
              });
            }
          }
          arrFun(id)
          idArr.forEach((id:any) => {
            state.questionAnswer[id] = []
          });
        
      }
    }).addCase(fetchAddAdminQuestion.pending, (state) => {
      state.loading['addAdminQuestion'] = true;
      state.generateType =false
      state.industryEndwordSearchType = false
      state.industryKeywordAnswer = {}
      state.industryKeyWord['e'] = ''
    }).addCase(fetchAddAdminQuestion.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === 0){
        data.answer = ''
        data.edit=false;
        data.add=false;
        data.newQuestion='';
        data.updateLoading = false;
        data.addLoading = false;
        data.keyWord=data.question;
        state.industryQuestion.unshift(data)
        state.loading['addAdminQuestion'] = false;
      }
    }).addCase(getCompanyListWithEventRank.pending, (state) => {
      state.loading['getCompanyListWithEventRank'] = true;
    }).addCase(getCompanyListWithEventRank.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.eventDriven = data
        state.loading['getCompanyListWithEventRank'] = false;
      }
    }).addCase(tocSocialMedia.pending, (state) => {
      state.loading['tocSocialMedia'] = true;
    }).addCase(tocSocialMedia.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.socialMedia = data
        state.loading['tocSocialMedia'] = false;
      }
    }).addCase(policyDrivenList.pending, (state) => {
      state.loading['policyDrivenList'] = true;
    }).addCase(policyDrivenList.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.policyDriven.total = data.total
        state.policyDriven.dataList = state.policyDriven.dataList.concat(data.dataList)
        state.loading['policyDrivenList'] = false;
      }
    }).addCase(getCompanyScoreRank.pending, (state) => {
      state.loading['getCompanyScoreRank'] = true;
    }).addCase(getCompanyScoreRank.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.aiList.total = data.total
        state.aiList.dataList = state.aiList.dataList.concat(data.dataList)
        state.loading['getCompanyScoreRank'] = false;
      }
    })

    

    

    

  }
})
export const {resetAiList,setAiMarkedTop,setAiHide,resetEventDrivenList ,resetPolicyDrivenList, setPolicyHide,setEventHide, setPolicyMarkedTop, setEventMarkedTop, setGenerateType, setQuestionAdd, setQuestionEdit, setNewQuestion, setQuestionKeyWord, setQuestionAnswer,setIndustryKeyWord,setRestIndustry,setIndustryEndwordSearchType,setQuestionTitle,setSelectType,setActiveCompany,setBusinessList,
  setCompanyList,setSearchActiveItem, setSearchId, setSearchTagsActiveItem, setSearchType, setCompanySearchStatus, setBusinessSearchStatus, setListShow, setNewSearchStatus, setActiveId, setReportKeyWord, setEventFromDate, setEventEndDate, setIsCompanySearch, setNodeList, setNodeDetail, setNodeData, setactiveTab, setTab, setNodeTypes, setRefresh, setActiveNodeId, resetNodeListOptions, setSortList, setSorting, setSortOder ,setSortType, setActiveCompanyInfo}  = pandoraSearch.actions;

const selectBaseState = (state: RootState)  => state.pandoraSearch;
export const selectActiveCompanyInfo = createSelector(selectBaseState, state => state.activeCompanyInfo || {})
export const selectSearchCompanyList = createSelector(selectBaseState, state => state.searchCompanyList || [])
export const selectSearchHistoryList = createSelector(selectBaseState, state => state.searchHistoryList || [])
export const selectLoadingByType = (type:string) => createSelector(selectBaseState, state => state.loading[type] || false);
export const selectNodeTypes = createSelector(selectBaseState, state => state.nodeTypes || [])
export const selectNodeList = createSelector(selectBaseState, state => state.nodeList || [])
export const selectActiveTab = createSelector(selectBaseState, state => state.activeTab)
export const selectNodeDetail = createSelector(selectBaseState, state => state.nodeDetails || {})
export const selectNodeData = createSelector(selectBaseState, state => state.nodeData || {})
export const selectChartRefresh = createSelector(selectBaseState, state => state.refresh || false)
export const selectActiveNodeId = createSelector(selectBaseState, state => state.activeNodeId || '')
export const selectNodeKeywordMatch = createSelector(selectBaseState, state => state.nodeKeywordMatch || [])
export const selectFetchOptions = createSelector(selectBaseState, state => state.nodeListOptions)
export const selectSortList = createSelector(selectBaseState, state => state.sortingInfo.sortList)
export const selectSorting = createSelector(selectBaseState, state => state.sortingInfo.sorting)
export const selectSortOder = createSelector(selectBaseState, state => state.sortingInfo.order)
export const selectSortType = createSelector(selectBaseState, state => state.sortingInfo.sortType)
export const selectNoData = createSelector(selectBaseState, state => state.noData)
export const selectIsCompanySearch = createSelector(selectBaseState, state => state.isCompanySearch || false)
export const selectOpportunitiesDetail = createSelector(selectBaseState, state => state.opportunities )
export const selectEventFromDate = createSelector(selectBaseState, state => state.eventFromDate )
export const selectEventEndDate = createSelector(selectBaseState, state => state.eventEndDate )
export const selectReportYears= createSelector(selectBaseState, state => state.reportYears || [] )
export const selectReportMainBusiness= createSelector(selectBaseState, state => state.reportMainBusiness || [] )
export const selectReportUrl= createSelector(selectBaseState, state => state.reportUrl || '' )
export const selectReportFuture= createSelector(selectBaseState, state => state.reportFuture || [] )
export const selectReportCoreCompetence = createSelector(selectBaseState, state => state.reportCoreCompetence || [] )
export const selectReportCompanyResearch = createSelector(selectBaseState, state => state.companyResearch || {project:{},employees:{},spending:{}} )
export const selectReportKeyWord = createSelector(selectBaseState, state => state.reportKeyWord || '' )
export const selectReportKeyWordList = createSelector(selectBaseState, state => state.reportKeyWordList || [] )
export const selectResearchList = createSelector(selectBaseState, state => state.researchList || [] )
export const selectReportInvestment = createSelector(selectBaseState, state => state.reportInvestment || [] )
export const selectReportCAGR = createSelector(selectBaseState, state => state.reportCAGR || [] )
export const selectReportCrnTab= createSelector(selectBaseState, state => state.reportCrnTab || [] )
export const selectReportCrn = (type:string) => createSelector(selectBaseState, state => state.reportCrn[type] || {});
export const selectResearchUrl = createSelector(selectBaseState, state => state.researchUrl || '' )
export const selectSearchType = createSelector(selectBaseState, state => state.dataType || '')
export const selectActivedCompanyId = createSelector(selectBaseState,  state => state.company.activedCompanyId || '')
export const selectSearchActiveOption = createSelector(selectBaseState, state => state.searchList.searchActiveOption)

export const selectActivedTagsBusinessId = createSelector(selectBaseState, state => state.business.activedTagsBusinessId || [])
export const selectCompanySearchingStatus = createSelector(selectBaseState, state => state.isCompanySearching || false)
export const selectBusinessSearchingStatus = createSelector(selectBaseState, state => state.isBusinessSearching || false)
export const selectSearchStatus = createSelector(selectBaseState, state => state.searchStatus || false)
export const selectType = createSelector(selectBaseState, state => state.selectType || '')
export const selectBusinessReport = (companyId: number, businessTypeId: number) => createSelector(selectBaseState, state => state.reportDetails[`report-${companyId}-${businessTypeId}`])
export const selectListShow = createSelector(selectBaseState, state => state.listShow || false)

export const selectCompanyBusinessFetchOptions = createSelector(selectBaseState, selectSearchType,  (state, type) => {
  const data = type === 'company' ? state.company.dataList[state.company.activedCompanyId] : state.business.dataList;
  if(data){
    const {dataList, ...fetchOptions} = data
    return fetchOptions
  }
  return {
    page: 0, 
    total: 1
  };
})
export const selectListDataByDataType = createSelector(
  selectBaseState, 
  selectSearchType,  
  (state, type)=> {
    if(!type){
      return {
        activedTagsBusinessId:[],
        activedBusinessId:0,
        activedCompanyId: 0,
      } as DataProps;
    }
    const {dataList, ...listData} = type === 'company' ? state.company : state.business;
    return listData
  }
);
export const selectDataListById = createSelector(
  selectBaseState, 
  selectSearchType, 
  (state, type)=> {
    if(!type){
      return [];
    }
    if(type === 'company'){
      return state.company.dataList[state.company.activedCompanyId]?.dataList || []
    }else{
      return state.business.dataList?.dataList || [];
    }
  }
)
export const selectSearchOptions = createSelector(
  selectBaseState, 
  selectType,
  (state, type) => {
    if(!type){
      return  [];
    }
    if(type === 'company'){
      return state.searchList.company.dataList;
    }else{
      return  state.searchList.business.dataList
    }
  }
)
export const selectSearchLoading = createSelector(selectBaseState, state => state.searchLoading)
export const selectEventDetailById = (id:any) => createSelector(selectBaseState, state => state.eventDetails[id] || {})
export const selectQuestionChild = (key:any) => createSelector(selectBaseState, state => state.questionChildObj[key] || [])
export const selectQuestionTitle = (key:any) => createSelector(selectBaseState, state => state.questionTitle[key] || {})
export const selectQuestionTitleArr = createSelector(selectBaseState, state => state.questionTitleArr || [])
export const selectIndustryQuestion = createSelector(selectBaseState, state => state.industryQuestion || [])
export const selectAllQuestionItem = createSelector(selectBaseState, state => state.allQuestionItem || [])
export const selectIndustryKeywordAnswer = createSelector(selectBaseState, state => state.industryKeywordAnswer || {})
export const selectIndustryEndwordSearchType = createSelector(selectBaseState, state => state.industryEndwordSearchType || false)
export const selectChildquestionType = createSelector(selectBaseState, state => state.childquestionType || [] )
export const selectChildquestionTypeKey = (key:any) => createSelector(selectBaseState, state =>{
  let index = state.childquestionType.findIndex((item:any) => item.pid === key)
  if(index > -1){
    return  state.childquestionType[index].status
  }else{
    return  false
  }
})
export const selectIndustryKeyWord = createSelector(selectBaseState, state => state.industryKeyWord || {s:'',e:''} )
export const selectQuestionAnswer = (key:any) => createSelector(selectBaseState, state => state.questionAnswer[key] || {})
export const selectGenerateType = createSelector(selectBaseState, state => state.generateType || false)
export const selectEventDriven= createSelector(selectBaseState, state => state.eventDriven)
export const selectSocialMedia= createSelector(selectBaseState, state => state.socialMedia)
export const selectPolicyDriven= createSelector(selectBaseState, state => state.policyDriven)
export const selectAiList= createSelector(selectBaseState, state => state.aiList)

// export const selectChildquestionType = (key:any) => createSelector(selectBaseState, state => state.childquestionType[key] || false)


export default pandoraSearch.reducer;
