import { createSlice, createSelector, createAsyncThunk, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api'
import {checkToken} from '../../user/reducer';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

interface DailyFlashProps {
    loading: {
        [key: string]: boolean
    },
    chartData:{
      [key: string]: object
    },
    industrInvolved:Array<any>,
    headerList:Array<any>,
    headerListTwo:Array<any>,
    policyAnalysisId:string
    policyAnalysisList:{
      dataList:Array<any>,
      total:number
    },
    economicList:Array<any>,
    listClass: any,
    policyParseInfo:{
      contentHtml:any,
      soureContent:string,
      content:string,
      publishTime:string,
      entities:Array<any>,
      url:string,
      title:string
    },
    policyNewsFilter:any,
    policyNewsGraph:Array<any>,
    policyNewsList:{
      total:number,
      dataList:Array<any>
    },
    economicIndicatorsInfo:any
    shibor:any,
    lpr:any,
    exchangeRate:any,
    economicUrl:string,
    contentFlag:boolean,
    topicPolicyList:{
      total:number,
      dataList:Array<any>
    },
    policyTopicConfiguration:Array<any>,
    policyTopicConfigurationSelect:Array<any>,
    policyTopicConfigurationItem:{
      [key: string]: Array<any>,
    },
    policyTopicTree: Array<any>,
    swIndustry:Array<any>,
    swIndustrySencond:{
      [key: string]: Array<any>,
    },
    swIndustryThree:{
      [key: string]: Array<any>,
    },
    issuingDep:Array<any>,
    depData:{
      country:Array<any>,
      countryNum:number,
      localityNum:number,
      ministriesCommissionsNum:number,
      ministriesCommissions:Array<any>,
      locality:Array<any>,
    },
    involveArea:Array<any>,
    topicPolicyInvolveAreaCount:Array<any>,
    policyType:Array<any>,
    involveCompany:Array<any>,
    timeLineByTopic:Array<any>,
    topicPolicyInfo:any,
    activeTopic:{
      topic:string,
      id:string,
      policyId:string
    },
    wordCloud:Array<any>,
    hotTopic:Array<any>,
    isSearching: boolean,
    searchList:Array<any>,
    mapData:Array<any>,
    topicHot:Array<any>,
        topicHotChart:any,
    timeOpen:boolean,
    topicPage:Array<any>,
    pageParams:any,
    swIndustryParams:any,
    swAllIndustry:Array<any>,
    allPolicyType:Array<any>,
    searchData:{
      [key: string]: string
    },
    importMeetingList:Array<any>,
    previousMeetingList:Array<any>,
    htmlDiff:Array<any>,
    character:Array<any>,
    wordCloudDetailsInfo:any,
    setDfcfTotal:number,
    setXqfTotal:number,
    popoverClosed:Boolean,
    policyTimeLine:{
      dataList :Array<any>,
      total :string
    },
    filterList:Array<any>,
    policyParams:any,
    drawer:boolean,
    activeFilterItem:any,
    restPolicyPage:boolean,
    policyReleaseTime:Array<any>,
}

const initialState: DailyFlashProps = {
    loading: {},
    policyAnalysisId:'',
    policyAnalysisList:{
      dataList:[],
      total:0
    },
    economicList:[],
    listClass:'',
    policyParseInfo:{
      contentHtml:null,
      soureContent:'',
      content:'',
      publishTime:'',
      entities:[],
      url:'',
      title:''
    },
    policyNewsFilter:{},
    policyNewsGraph:[],
    policyNewsList:{
      total:0,
      dataList:[]
    },
    economicIndicatorsInfo:{
      timeType:'',
      source:''
    },
    chartData:{
      
    },
    shibor:{xAxis:[],
      now:[],nineMonth:[],oneMonth:[],oneWeek:[],oneYear:[],sixMonth:[],threeMonth:[],twoWeek:[]
    },
    lpr:{
      oneY:[],
      fiveY:[]
    },
    exchangeRate:{
       xAxis:[],
        eurCny:[],
        gbpCny:[],
      hkdCny:[],
        jpyCny:[],
        usdCny:[]
    },
    economicUrl:'',
    contentFlag:false,
    topicPolicyList:{
      dataList:[],
      total:0
    },
    industrInvolved:[],
    headerList:[],
    headerListTwo:[],
    policyTopicConfiguration:[],
    policyTopicConfigurationSelect:[],
    policyTopicConfigurationItem:{},
    policyTopicTree:[],
    swIndustry:[],
    swIndustrySencond:{},
    swIndustryThree:{},
    issuingDep:[],
    depData:{
      country:[],
      countryNum:0,
      ministriesCommissionsNum:0,
      localityNum:0,
      ministriesCommissions:[],
      locality:[],
    },
    involveArea:[],
    topicPolicyInvolveAreaCount:[],
    policyType:[],
    involveCompany:[],
    timeLineByTopic:[],
    topicPolicyInfo:{},
    activeTopic:{
      topic:'',
      id:'',
      policyId:''
    },
    wordCloud:[],
    hotTopic:[],
    isSearching: false,
    searchList:[],
    mapData:[],
    topicHot:[],
        topicHotChart:{
      xAxis:[],seriesData:[],maxNum:10
    },
    timeOpen:false,
    topicPage:[],
    pageParams:{},
    swIndustryParams:{},
    swAllIndustry:[],
    allPolicyType:[],
    searchData:{},
    importMeetingList:[],
    previousMeetingList:[],
    htmlDiff:[
      {html:'',id:'',title:'',url:''},
      {html:'',id:'',title:'',url:''},
    ],
    character:[],
    wordCloudDetailsInfo:{},
    setDfcfTotal:0,
    setXqfTotal:0,
    popoverClosed:false,
    policyTimeLine:{
      dataList :[],
      total :''
    },
    filterList:[],
    policyParams:{},
    drawer:false,
    activeFilterItem:{
      id:'',
      filterName:''
    },
    restPolicyPage:false,
    policyReleaseTime:[],
}




export const fetchGetPolicyParseInfo = createAsyncThunk(
    'policy-analysis/fetch-get-policy-parse-info',
    async (params: any, thunkAPI) => {
        return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyParseInfo(params))
    }
)
export const fetchGetPolicyParseList = createAsyncThunk(
    'policy-analysis/fetch-get-policy-parse-list',
    async (params: any, thunkAPI) => {
        return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyParseList(params))
    }
)
export const fetchGetPolicyNewsFilter = createAsyncThunk(
  'policy-analysis/fetch-get-policy-news-filter',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyNewsFilter(params))
  }
)

export const fetchGetPolicyNewsGraph = createAsyncThunk(
  'policy-analysis/fetch-get-policy-news-graph',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyNewsGraph(params))
  }
)

export const fetchGetPolicyNewsList = createAsyncThunk(
  'policy-analysis/fetch-get-policy-news-list',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyNewsList(params))
  }
)





export const fetchGetEconomicList = createAsyncThunk(
  'policy-analysis/fetch-get-economic-list',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetEconomicList(params))
  }
)

export const fetchGetFindShibor = createAsyncThunk(
  'policy-analysis/fetch-get-find-shibor',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchFindShibor(params))
  }
)
export const fetchGetFindLpr = createAsyncThunk(
  'policy-analysis/fetch-get-find-lpr',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchFindLpr(params))
  }
)

export const fetchGetExchangeRate = createAsyncThunk(
  'policy-analysis/fetch-find-exchange-rate',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchFindExchangeRate(params))
  }
)
export const fetchGetPolicyTopicConfiguration = createAsyncThunk(
  'policy-analysis/fetch-get-policy-topic-configuration',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyTopicConfiguration(params))
  }
)

export const fetchGetPolicyTopicTree = createAsyncThunk(
  'policy-analysis/fetch-get-policy-topic-tree',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyTopicTree(params))
  }
)


export const searchTopic = createAsyncThunk(
  'policy-analysis/fetch-get-search-topic',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyTopicTree(params))
  }
)

export const fetchGetSwIndustry = createAsyncThunk(
  'policy-analysis/fetch-get-sw-industry',
  async (params: any, thunkAPI) => {
    const {type} = params
      return thunkAPI.dispatch(checkToken()).then(async (resp) => {
        const result = await pandora.fetchGetSwIndustry(params);
        return {
          type,
          ...result
        }
      })
  }
)
export const fetchGetPolicySwIndustry = createAsyncThunk(
  'policy-analysis/fetch-get-policy-sw-industry',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(async (resp) => {
        const result = await pandora.fetchGetPolicySwIndustry(params);
        return {
          ...result
        }
      })
  }
)

export const fetchGetIssuingDep = createAsyncThunk(
  'policy-analysis/fetch-get-issuing-dep',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetIssuingDep(params))
  }
)

export const fetchGetInvolveArea = createAsyncThunk(
  'policy-analysis/fetch-get-involve-area',
  async (params: any, thunkAPI) => {
      const {type} = params
      return thunkAPI.dispatch(checkToken()).then(async (resp) => {
        const result = await pandora.fetchGetInvolveArea(params);
        return {
          type,
          ...result
        }
      })
  }
)
export const fetchGetNewInvolveArea = createAsyncThunk(
  'policy-analysis/fetch-get-new-involve-area',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(async (resp) => {
        const result = await pandora.fetchGetNewInvolveArea(params);
        return {
          ...result
        }
      })
  }
)

export const fetchGetInvolveCompany = createAsyncThunk(
  'policy-analysis/fetch-get-involve-company',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetInvolveCompany(params))
  }
)

export const fetchGetPolicyType = createAsyncThunk(
  'policy-analysis/fetch-get-policy-type',
  async (params: any, thunkAPI) => {
      const {type} = params
      return thunkAPI.dispatch(checkToken()).then(async (resp) => {
        const result = await pandora.fetchGetPolicyType(params);
        return {
          type,
          ...result
        }
      })
  }
)
export const fetchGetPolicyTypeCount = createAsyncThunk(
  'policy-analysis/fetch-get-policy-type-count',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(async (resp) => {
        const result = await pandora.fetchGetPolicyTypeCount(params);
        return {
          ...result
        }
      })
  }
)
export const fetchGetTopicPolicyList = createAsyncThunk(
  'policy-analysis/fetch-topic-policy-list',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetTopicPolicyList(params))
  }
)
export const fetchGetTimeLineByTopic = createAsyncThunk(
  'policy-analysis/fetch-time-line-by-topic',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetTimeLineByTopic(params))
  }
)

export const fetchGetTopicPolicyInfo = createAsyncThunk(
  'policy-analysis/fetch-get-topic-policy-info',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetTopicPolicyInfo(params))
  }
)

export const fetchWordCloudDetailsInfo = createAsyncThunk(
  'policy-analysis/fetch-word-cloud-details-info',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchWordCloudDetailsInfo(params))
  }
)

export const fetchGetTopicLabelCount = createAsyncThunk(
  'policy-analysis/fetch-get-topic-label-count',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetTopicLabelCount(params))
  }
)

export const fetchGetPolicyLabelCount = createAsyncThunk(
  'policy-analysis/fetch-get-new-topic-label-count',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyLabelCount(params))
  }
)

export const fetchSavePolicyTopicConfiguration = createAsyncThunk(
  'policy-analysis/fetch-save-policy-topic-configuration',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchSavePolicyTopicConfiguration(params))
  }
)

export const fetchAddPolicyTopicTop = createAsyncThunk(
  'policy-analysis/fetch-add-policy-topicTop',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchAddPolicyTopicTop(params))
  }
)

export const fetchDeletePolicyTopicTop = createAsyncThunk(
  'policy-analysis/fetch-delete-policy-topicTop',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchDeletePolicyTopicTop(params))
  }
)

export const fetchAddPolicyTopic = createAsyncThunk(
  'policy-analysis/fetch-add-policy-topic',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchAddPolicyTopic(params))
  }
)

export const fetchGetPolicyTopicHistory = createAsyncThunk(
  'policy-analysis/fetch-get-policy-topic-history',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyTopicHistory(params))
  }
)

export const fetchCheckPolicyTopic = createAsyncThunk(
  'policy-analysis/fetch-check-policy-topic',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchCheckPolicyTopic(params))
  }
)

export const fetchGetTopicHot = createAsyncThunk(
  'policy-analysis/fetch-get-topic-hot',
  async (params: any, thunkAPI) => {
    const {interval} = params
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetTopicHot(params);
      return {
        interval,
        ...result
      }
    })
  }
)
export const fetchGetPolicyTopicPage = createAsyncThunk(
  'policy-analysis/fetch-get-policy-topic-page',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyTopicPage(params))
  }
)

export const fetchSavePolicyTopicSearchHistory = createAsyncThunk(
  'policy-analysis/fetch-save-policy-topic-search-history',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchSavePolicyTopicSearchHistory(params))
  }
)

export const fetchGetTopicPolicyInvolveAreaCount = createAsyncThunk(
  'policy-analysis/fetch-get-topic-policy-involve-areaCount',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetTopicPolicyInvolveAreaCount(params))
  }
)
export const fetchGetPolicyInvolveAreaCount = createAsyncThunk(
  'policy-analysis/fetch-get-policy-involve-areaCount',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPolicyInvolveAreaCount(params))
  }
)
export const fetchGetImportMeetingList = createAsyncThunk(
  'policy-analysis/fetch-get-import-meeting-list',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetImportMeetingList(params))
  }
)

export const fetchGetPreviousMeetingList = createAsyncThunk(
  'policy-analysis/fetch-get-previous-meeting-list',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPreviousMeetingList(params))
  }
)

export const getMeetChirden = createAsyncThunk(
  'policy-analysis/fget-meeting-list',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetPreviousMeetingList(params))
  }
);

export const fetchPolicyHtmlDiff = createAsyncThunk(
  'policy-analysis/fetch-policy-html-diff',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchPolicyHtmlDiff(params))
  }
);
export const fetchGetUserPolicyTagOrder = createAsyncThunk(
  'policy-analysis/fetch-get-user-policy-tag-order',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetUserPolicyTagOrder(params))
  }
)
export const fetchUpdateUserPolicyTagOrder = createAsyncThunk(
  'policy-analysis/fetch-update-user-policy-tag-order',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchUpdateUserPolicyTagOrder(params))
  }
)
export const fetchGetIndustryVein = createAsyncThunk(
  'policy-analysis/fetch-get-industry-vein',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetIndustryVein(params))
  }
)
export const getTopicPolicyListByTimeLine = createAsyncThunk(
  'policy-analysis/fetch-get-topicPolicyListByTimeLine',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.getTopicPolicyListByTimeLine(params))
  }
)
export const downloadPolicyInfo = createAsyncThunk(
  'policy-analysis/fetch-download-info',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.downloadPolicyInfo(params))
  }
)
export const downloadPolicyHot = createAsyncThunk(
  'policy-analysis/fetch-download-policy-hot',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.downloadPolicyHot(params))
  }
)
export const fetcheHotInfo = createAsyncThunk(
  'policy-analysis/fetch-hot-info',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetcheHotInfo(params))
  }
)

export const getPolicyFilterList = createAsyncThunk(
  'policy-analysis/getPolicyFilterList',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.getPolicyFilterList(params))
  }
)
export const deletePolicyFilter = createAsyncThunk(
  'policy-analysis/deletePolicyFilter',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.deletePolicyFilter(params))
  }
)
export const savePolicyFilter = createAsyncThunk(
  'policy-analysis/savePolicyFilter',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.savePolicyFilter(params))
  }
)
export const updatePolicyFilterName = createAsyncThunk(
  'policy-analysis/updatePolicyFilterName',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.updatePolicyFilterName(params))
  }
)


export const fetchGetEconomicIndicators = createAsyncThunk(
  'policy-analysis/fetch-get-economic-indicators',
  async (params: any, thunkAPI) => {
    const {type,name} = params
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      var result = {} as any
      switch(type){
        case 'gdp':
          result = await pandora.fetchFindAnnualGdp(params);
          break;
        case 'unemploymentRate':
          result = await pandora.fetchFindUnemploymentRate(params);
          break;
        case 'socialFinancing':
          result = await pandora.fetchFindSocialFinancing(params);
          break;
        case 'cpi':
          result = await pandora.fetchFindCpi(params);
          break;
        case 'pmi':
          result = await pandora.fetchFindPmi(params);
          break;  
        case 'ppi':
          result = await pandora.fetchFindPpi(params);
          break; 
        case 'money':
          result = await pandora.fetchFindMoney(params);
          break; 
        case 'electricityConsumption':
          result = await pandora.fetchFindElectricityConsumption(params);
          break;
        case 'exchangeRate':
          result = await pandora.fetchFindExchangeRate(params);
          break; 
        case 'householdConsumption':
          result = await pandora.fetchFindConsumption(params);
          break;
        case 'retailSales':
          result = await pandora.fetchFindTotalRetailSales(params);
          break;
        case 'exportImport':
          result = await pandora.fetchFindExportImport(params);
          break;
       }
      return {
        type,
        name,
        ...result
      }
    })
  }
)

export const policyAnalysis = createSlice({
  name:'policyAnalysis',
  initialState,
  reducers: {
    setNewsId: (state, {payload}) => {
      state.loading['fetchDailyFlashNewsDetailById'] = payload;
    },
    setListClass: (state, {payload}) => {
      state.listClass = payload;
    },
    setNewContent: (state, {payload}) => {
      var contentTxt = JSON.parse(JSON.stringify(state.policyParseInfo.soureContent))
      payload.forEach((item:any) => {
        contentTxt = contentTxt.replace(item.txt,`<span>${item.txt.replace(item.keyword,`<span id='${item.targetId}' style="background:#FF9900;color:#fff;">${item.keyword}</span>`)}</span>`)
      });
      state.policyParseInfo.content = contentTxt
    },
    setNewContentIndex: (state, {payload}) => {
        // console.log('state.policyParseInfo.soureContent--',state.policyParseInfo.soureContent)
      var contentTxt = JSON.parse(JSON.stringify(state.policyParseInfo.soureContent))
      var txtcontent = '';
      for(var i = 0; i< payload.length ; i++){
        if(i === 0){
          txtcontent += contentTxt.substring(0,payload[i].indexArr[0]) +`<span id='${payload[i].targetId}' style="background:#FF9900;color:#fff;">${contentTxt.substring(payload[i].indexArr[0],Number(payload[i].indexArr[1] + 1)).replace(/<[^>]+>/g, '')}</span>`
        }else{
          txtcontent += contentTxt.substring(Number(payload[i-1].indexArr[1] + 1),payload[i].indexArr[0]) +`<span id='${payload[i].targetId}' style="background:#FF9900;color:#fff;">${contentTxt.substring(payload[i].indexArr[0],Number(payload[i].indexArr[1]+1)).replace(/<[^>]+>/g, '')}</span>`
        }
        if(i === payload.length-1){
          txtcontent += contentTxt.substring(Number(payload[i].indexArr[1] + 1))
        }
      }
      state.policyParseInfo.content = txtcontent

    },
    setNewTopicContentIndex: (state, {payload}) => {
        // console.log('setNewTopicContentIndex--',payload);
        state.topicPolicyInfo.reportContentHtml = state.topicPolicyInfo.copyReportContentHtml ? state.topicPolicyInfo.copyReportContentHtml : state.topicPolicyInfo.reportContentHtml;
        state.topicPolicyInfo.heighlightStartIndex = null;
        state.topicPolicyInfo.heighlightEndIndex = null;
        var contentTxt = state.topicPolicyInfo.copyReportContentHtml ? JSON.parse(JSON.stringify(state.topicPolicyInfo.copyReportContentHtml)):JSON.parse(JSON.stringify(state.topicPolicyInfo.reportContentHtml));
        var txtcontent = '';
        for(var i = 0; i< payload.length ; i++){
            if(i === 0){
                txtcontent += contentTxt.substring(0,payload[i].indexArr[0]) +`<span style="background:#FF9900;color:#fff;">${contentTxt.substring(payload[i].indexArr[0],Number(payload[i].indexArr[1] + 1)).replace(/<[^>]+>/g, '')}</span>`
            }else{
                txtcontent += contentTxt.substring(Number(payload[i-1].indexArr[1] + 1),payload[i].indexArr[0]) +`<span style="background:#FF9900;color:#fff;">${contentTxt.substring(payload[i].indexArr[0],Number(payload[i].indexArr[1]+1)).replace(/<[^>]+>/g, '')}</span>`
            }
            if(i === payload.length-1){
                txtcontent += contentTxt.substring(Number(payload[i].indexArr[1] + 1))
            }
        }
        state.topicPolicyInfo.copyReportContentHtml = state.topicPolicyInfo.reportContentHtml;
        state.topicPolicyInfo.reportContentHtml = txtcontent;
        // console.log('state.topicPolicyInfo.reportContentHtml--',state.topicPolicyInfo.reportContentHtml);
    },
    setHeighlightContentIndex: (state, {payload}) => {
        state.topicPolicyInfo.reportContentHtml = state.topicPolicyInfo.copyReportContentHtml ? state.topicPolicyInfo.copyReportContentHtml : state.topicPolicyInfo.reportContentHtml;
      var contentTxt = JSON.parse(JSON.stringify(state.topicPolicyInfo.reportContentHtml));
      var txtcontent = '';
      if(payload.index){
        var startIndex = payload.index[0];
        var endIndex = payload.index[1];
        if(state.topicPolicyInfo.heighlightStartIndex && state.topicPolicyInfo.heighlightEndIndex){//已经存在高亮的句子
            console.log("已经存在高亮的句子")
          //取消高亮
          state.topicPolicyInfo.reportContentHtml = state.topicPolicyInfo.copyReportContentHtml;
          if(state.topicPolicyInfo.heighlightStartIndex !== startIndex || state.topicPolicyInfo.heighlightEndIndex !== endIndex){//再设置高亮
            txtcontent += contentTxt.substring(0,startIndex) +`<span id='ceshi' style="background:#0068E1;color:#fff;">${contentTxt.substring(startIndex,endIndex).replace(/<[^>]+>/g, '')}</span>` + contentTxt.substring(Number(endIndex + 1));
            state.topicPolicyInfo.heighlightStartIndex = startIndex;
            state.topicPolicyInfo.heighlightEndIndex = endIndex;
            state.topicPolicyInfo.reportContentHtml = txtcontent;
          }else {
            state.topicPolicyInfo.heighlightStartIndex = null;
            state.topicPolicyInfo.heighlightEndIndex = null;
          }
        }else {//没有高亮的句子
            console.log("没有高亮的句子")
          txtcontent += contentTxt.substring(0,startIndex) +`<span id='ceshi' style="background:#0068E1;color:#fff;">${contentTxt.substring(startIndex,endIndex).replace(/<[^>]+>/g, '')}</span>` + contentTxt.substring(Number(endIndex + 1));
          state.topicPolicyInfo.heighlightStartIndex = startIndex;
          state.topicPolicyInfo.heighlightEndIndex = endIndex;
          state.topicPolicyInfo.copyReportContentHtml = state.topicPolicyInfo.reportContentHtml;
          state.topicPolicyInfo.reportContentHtml = txtcontent;
        }
      }else{
        var txt = payload.sentence.replace(/<[^>]+>/g, '')
        console.log(txt)
        if(state.topicPolicyInfo.heighlightStartIndex && state.topicPolicyInfo.heighlightEndIndex){//已经存在高亮的句子
          state.topicPolicyInfo.reportContentHtml = state.topicPolicyInfo.copyReportContentHtml;
          state.topicPolicyInfo.heighlightStartIndex = null;
          state.topicPolicyInfo.heighlightEndIndex = null;
        }else{
          state.topicPolicyInfo.heighlightStartIndex = 1;
          state.topicPolicyInfo.heighlightEndIndex = 2;
          contentTxt = contentTxt.replace(txt,`<span id='ceshi' style="background:#0068E1;color:#fff;">${txt}</span>`)
          state.topicPolicyInfo.copyReportContentHtml = state.topicPolicyInfo.reportContentHtml;
          state.topicPolicyInfo.reportContentHtml = contentTxt
        }

      }
    },
    setEconomicIndicators:(state, {payload}) => {
      if(payload === 'GDP' || payload === '社会融资规模' ){
        state.economicIndicatorsInfo = {source:payload,timeType:'年'}
      }else if(payload === '居民消费和收入支出'){
        state.economicIndicatorsInfo = {source:payload,timeType:'季'};
      }else{
        state.economicIndicatorsInfo = {source:payload,timeType:'月'};
      }
      
    },
    setPolicyAnalysisId: (state, {payload}) => {
      state.policyAnalysisId = payload;
    },
    setIndustrInvolved:(state, {payload}) => {
      state.industrInvolved = payload;
    },
    setHeaderList:(state, {payload}) => {
      state.headerListTwo = payload;
    },
    restEconomicIndicators:(state, {payload}) => {
      state.economicIndicatorsInfo ={source:'',timeType:''}
      
    },
    setPolicyAnalysisList: (state, {payload}) => {
      state.policyAnalysisList = {
        dataList:[],
        total:0
      };
    },
    setRestPolicyNews:(state, {payload}) => {
      state.policyNewsFilter = payload
      state.policyNewsGraph = []
      state.policyNewsList = {dataList:[],total:0}
    },
    setPolicyTopicConfigurationItemChecked:(state, {payload}) => {
      state.policyTopicConfigurationItem[payload.activeId].forEach((item:any) => {
        if(payload.id === item.id){
          item.flag = payload.flag
        }
      });
    },
    setPolicyTopicConfigurationItemAllChecked:(state, {payload}) => {
      state.policyTopicConfiguration.forEach((item:any) => {
        if(payload.id === item.id){
          item.flag = payload.flag
        }
      });
      state.policyTopicConfigurationItem[payload.id].forEach((item:any) => {
        item.flag = payload.flag
      });
    },
    setTopic:(state, {payload}) => {
      state.activeTopic = payload
    },
    setSearching: (state, {payload}) => {
      state.isSearching = payload
    },
    setTimeOpen: (state, {payload}) => {
      state.timeOpen = payload
    },
    setPageParams: (state, {payload}) => {
      state.pageParams = payload
    },
    setGetSwIndustryParams: (state, {payload}) => {
      state.swIndustryParams = payload
    },
    setSearchData: (state, {payload}) => {
      state.searchData[payload.key] = payload.val
    }, 
    setPreviousMeetingList: (state, {payload}) => {
      state.previousMeetingList = payload
    },
    setContentFlag:(state, {payload}) => {
      state.contentFlag = payload
    },
    setHtmlDiff:(state, {payload}) => {
      state.htmlDiff = payload
    },
    setCharacter:(state, {payload}) => {
      state.character = payload
    },
    setPopoverClosed:(state, {payload}) => {
        state.popoverClosed = payload;
    },
    setPolicyParams:(state, {payload}) => {
      state.policyParams = payload;
    },
    setDrawer:(state, {payload}) => {
      state.drawer = payload;
    },
    setActiveFilterItem :(state, {payload}) => {
      console.log(payload)
      state.activeFilterItem = payload;
    },
    setRestPolicyPage:(state, {payload}) => {
      state.restPolicyPage = payload;
    },
    setPolicyReleaseTime:(state, {payload}) => {
      state.policyReleaseTime = payload;
    },
    setLoadingType:(state, {payload}) => {
      state.loading[payload.key] = payload.type;
    },
    setTopicHot:(state, {payload}) => {
      state.topicHot = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetPolicyParseList.pending, (state) => {
        state.loading.fetchGetPolicyParseList = true;
      }).addCase(fetchGetPolicyParseList.fulfilled, (state, { payload }) => {
        const {data,code} =payload;
        if(code === '0'){
            state.policyAnalysisList = {
                dataList:data.dataList,
                total:data.total
            }
            state.loading.fetchGetPolicyParseList = false;
        }
      }).addCase(fetchGetEconomicList.pending, (state) => {
        state.loading.fetchGetEconomicList = true;
      }).addCase(fetchGetEconomicList.fulfilled, (state, { payload }) => {
        const {data,code} =payload;
        if(code === '0'){
            state.economicList = data.dataList
        }
        state.loading.fetchGetEconomicList = false
      }).addCase(fetchGetExchangeRate.fulfilled, (state, { payload }) => {
        const {data,code} =payload;
        if(code === '0'){

          const xAxis: any = [];
          const eurCny: any = [];
          const gbpCny: any = [];
          const hkdCny: any = [];
          const jpyCny: any = [];
          const usdCny: any = [];
          if(data.length){
            state.economicUrl = data[0].url
           }else{
            state.economicUrl = ''
           }
          data.forEach((item: any) => { 
            xAxis.push(`${moment(Number(item.time)).format('YYYY-MM-DD')}`)
            eurCny.push(Number(item.eurCny))
            gbpCny.push(Number(item.gbpCny))
            hkdCny.push(Number(item.hkdCny))
            jpyCny.push(Number(item.jpyCny))
            usdCny.push(Number(item.usdCny))
          })
          state.exchangeRate = {xAxis:xAxis,eurCny:eurCny,gbpCny:gbpCny,hkdCny:hkdCny,jpyCny:jpyCny,usdCny:usdCny}
        }
      }).addCase(fetchGetFindLpr.fulfilled, (state, { payload }) => {
        const {data,code} =payload;
        if(code === '0'){
          const oneY: any = [];
          const fiveY: any = [];
          data.forEach((item: any) => { 
            oneY.push([moment(Number(item.valueCode)).format('YYYY-MM-DD'),Number(item.oneYear)])
            fiveY.push([moment(Number(item.valueCode)).format('YYYY-MM-DD'),Number(item.fiveYear)])
          })
          state.lpr = {oneY:oneY,fiveY:fiveY}
        }
          
      }).addCase(fetchGetFindShibor.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        const xAxis: any = [];
        const nineMonth: any = [];
        const now: any = [];
        const oneMonth: any = [];
        const oneWeek: any = [];
        const oneYear: any = [];
        const sixMonth: any = [];
        const threeMonth: any = [];
        const twoWeek: any = [];
        if(data.length){
          state.economicUrl = data[0].url
         }else{
          state.economicUrl = ''
         }
        data.forEach((item: any) => { 
          xAxis.push(`${moment(Number(item.valueCode)).format('YYYY-MM-DD')}`)
          nineMonth.push(Number(item.nineMonth))
          now.push(Number(item.now))
          oneMonth.push(Number(item.oneMonth))
          oneWeek.push(Number(item.oneWeek))
          oneYear.push(Number(item.oneYear))
          sixMonth.push(Number(item.sixMonth))
          threeMonth.push(Number(item.threeMonth))
          twoWeek.push(Number(item.twoWeek))
        })
        state.shibor = {
          xAxis:xAxis,
          nineMonth:nineMonth,
          now:now,
          oneMonth:oneMonth,
          oneWeek:oneWeek,
          oneYear:oneYear,
          sixMonth:sixMonth,
          threeMonth:threeMonth,
          twoWeek:twoWeek
        }
      }
    }).addCase(fetchGetPolicyParseInfo.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
          data.entities.forEach((item:any) => {
            item.entities.forEach((entitiesItem:any) => {
              entitiesItem.nodeId = uuidv4()
            })
          });
         
          state.policyParseInfo = {
            contentHtml: data.contentHtml,
            title:data.title,
            publishTime:data.publishTime,
            // soureContent:'<div style="text-align:center">'+ data.title +'</div><div>'+ data.content + '</div>',
            // content:'<div>'+data.content.replace(/\s+/g,"<br/> &nbsp; &nbsp;&nbsp;&nbsp;") +'<div>',
            // content:'<div style="text-align:center">'+ data.title +'</div><div>'+data.content +'</div>',
            soureContent:data.contentHtml ? data.contentHtml :'<div style="text-align:center">'+ data.title +'</div><div>'+ data.content + '</div>',
            content:data.contentHtml ? data.contentHtml : '<div style="text-align:center">'+ data.title +'</div><div>'+data.content +'</div>',
            entities:data.entities,
            url:data.url
          }
          state.contentFlag = true
      }
    }).addCase(fetchGetPolicyNewsFilter.pending, (state) => {
      // state.policyNewsFilter = {areaInvolvedList:[],industryList:[],visitAreaList:[]}
      state.loading.fetchGetPolicyNewsFilter = true;
    }).addCase(fetchGetPolicyNewsFilter.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      
      if(code === '0'){
         state.policyNewsFilter = data
         state.loading.fetchGetPolicyNewsFilter = false;
      }
    }).addCase(fetchGetPolicyNewsGraph.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        if(!data.length){
          state.policyNewsList = {dataList:[],total:0}
        }
         state.policyNewsGraph = data
      }
    }).addCase(fetchGetPolicyNewsList.pending, (state) => {
      state.loading.fetchGetPolicyNewsList = true;
    }).addCase(fetchGetPolicyNewsList.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.policyNewsList = data
        state.loading.fetchGetPolicyNewsList = false
      }
    }).addCase(fetchGetEconomicIndicators.fulfilled, (state, { payload }) => {
      const {data,type,name,code} =payload;
      if(code === '0'){
         const xAxis: any = [];
         if(data.length){
          state.economicUrl = data[0].url
         }else{
          state.economicUrl = ''
         }
         data.forEach((item: any) => { 
          
          if(state.economicIndicatorsInfo.timeType === '年'){
            xAxis.push(`${moment(Number(item.valueCode)).format('YYYY')}年`)
          }else if(state.economicIndicatorsInfo.timeType === '月'){
            xAxis.push(`${moment(Number(item.valueCode)).format('YYYY')}年${moment(Number(item.valueCode)).format('MM')}月`)
          }else{
            xAxis.push(item.valueCode)

          }
        });
         if(type === 'money'){
          const money0Arr: any = []
          const money1Arr: any = []
          const money2Arr: any = []
          data.forEach((item: any) => { 
            money0Arr.push(Number(item.money0supplyQuantityEndingValue).toFixed(1))
            money1Arr.push(Number(item.money1supplyVolumeEndingValue).toFixed(1))
            money2Arr.push(Number(item.money2supplyQuantityEndingValue).toFixed(1))
          });
          state.chartData[type] = {
            xAxis:xAxis,
            barSeriesData:[
              {
                  data: money2Arr,
                  type: 'bar',
                  name:'货币和准货币(M2)供应量期末值(亿元)',
                  barWidth:10,
                  itemStyle:{ opacity:0.8,color:'#FF9900'},
                  // label: {show: true, position: 'inside'},
                  showSymbol: false
              },
              {
                data: money1Arr,
                type: 'bar',
                name:'货币(M1)供应量期末值(亿元)',
                barWidth:10,
                itemStyle:{ opacity:0.8,color:'#A93330'},
                // label: {show: true, position: 'inside'},
                showSymbol: false
              },
              {
                  data: money0Arr,
                  type: 'bar',
                  name:'流通中现金(M0)供应量期末值(亿元)',
                  barWidth:10,
                  itemStyle:{ opacity:0.8,color:'#66CCCC'},
                  // label: {show: true, position: 'inside'},
                  showSymbol: false
              }
              ],
              lineSeriesData:[
                {
                    data:money2Arr,
                    type: 'line',
                    name:'货币和准货币(M2)供应量期末值(亿元)',
                    smooth: true,
                    showSymbol: false,
                    lineStyle:{color:'#FF9900', width:4}
                },
                {
                  data: money1Arr,
                  type: 'line',
                  name:'货币(M1)供应量期末值(亿元)',
                  smooth: true,
                  showSymbol: false,
                  lineStyle:{color:'#A93330', width:4}
                },
                {
                    data: money0Arr,
                    type: 'line',
                    name:'流通中现金(M0)供应量期末值(亿元)',
                    smooth: true,
                    showSymbol: false,
                    lineStyle:{color:'#66CCCC', width:4}
                }
              ]
          }

         }else if(type === 'cpi'){
          const cpi1: any = []
          const cpi2: any = []
          data.forEach((item: any) => { 
            cpi1.push(Number(item.consumerPriceIndex))
            cpi2.push(Number(item.foodTobaccoAlcoholConsumerPriceIndex))
          });
          state.chartData[type] = {
            xAxis:xAxis,
            lineSeriesData:[
              {
                  data:cpi1,
                  type: 'line',
                  name:'居民消费价格指数(%)',
                  smooth: true,
                  showSymbol: false,
                  lineStyle:{color:'#FF9900', width:4}
              },
              {
                data: cpi2,
                type: 'line',
                name:'食品类居民消费价格指数(%)',
                smooth: true,
                showSymbol: false,
                lineStyle:{color:'#A93330', width:4}
              },
            ]
          }
          
         }else if(type === 'retailSales'){
          const data1: any = []
          const data2: any = []
          data.forEach((item: any) => { 
            data1.push(Number(item.yoyGrowthOfSocialConsumerGoods))
            data2.push(Number(item.currentValueOfSocialConsumerGoods))
          });
          state.chartData[type] = {
            xAxis:xAxis,
            lineSeriesData:[
              {
                  data:data1,
                  yAxisIndex: 1,
                  type: 'line',
                  name:'社会消费品零售总额同比增长率(%)',
                  smooth: true,
                  showSymbol: false,
                  lineStyle:{ color:'#A93330', width:4}
              },
              {
                data: data2,
                type: 'bar',
                yAxisIndex: 0,
                name:'社会消费品零售总额当期值(亿元)',
                barWidth:20,
                itemStyle:{opacity:0.8,color:'#FF9900'},
                // label: { show: true, position: 'inside'},
                showSymbol: false
              },
            ]
          }
          
         }else if(type=== 'exportImport' || type=== 'householdConsumption' ){
          const data1: any = []
          const data2: any = []
          data.forEach((item: any) => { 
            data1.push(Number(  type=== 'householdConsumption' ? item.consumption :item.totalImportValueYearOnYearGrowth))
            data2.push(Number( type=== 'householdConsumption' ? item.income :item.yearOnYearGrowthInTotalExportValue))
          });
          state.chartData[type] = {
            xAxis:xAxis,
            barSeriesData:[
              {
                  data: data2,
                  type: 'bar',
                  name: type=== 'householdConsumption' ? '居民人均可支配收入(元)' :'出口总值同比增长(%)',
                  barWidth:10,
                  itemStyle:{ opacity:0.8,color:'#FF9900'},
                  // label: {show: true, position: 'inside'},
                  showSymbol: false
              },
              {
                data: data1,
                type: 'bar',
                name:type=== 'householdConsumption' ? '居民人均消费支出(元)' :'进口总值同比增长(%)',
                barWidth:10,
                itemStyle:{ opacity:0.8,color:'#A93330'},
                // label: {show: true, position: 'inside'},
                showSymbol: false
              },
              ],
              lineSeriesData:[
                {
                    data:data2,
                    type: 'line',
                    name:type=== 'householdConsumption' ? '居民人均可支配收入(元)' :'出口总值同比增长(%)',
                    smooth: true,
                    showSymbol: false,
                    lineStyle:{color:'#FF9900', width:4}
                },
                {
                  data: data1,
                  type: 'line',
                  name:type=== 'householdConsumption' ? '居民人均消费支出(元)' :'进口总值同比增长(%)',
                  smooth: true,
                  showSymbol: false,
                  lineStyle:{color:'#A93330', width:4}
                },
              ]
          }
         }else{
          const barData: any = []
          data.forEach((item: any) => { 
            barData.push(item.value)
          });
        
          //  时间 valueCode 
          state.chartData[type] = {
            xAxis:xAxis,
            barSeriesData:[
            {
                data: barData,
                type: 'bar',
                name:name,
                barWidth:20,
                itemStyle:{opacity:0.8,color:'#FF9900'},
                // label: { show: true, position: 'inside'},
                showSymbol: false
            }
            ],
            lineSeriesData:[
              {
                  data: barData,
                  type: 'line',
                  name:name,
                  smooth: true,
                  showSymbol: false,
                  lineStyle:{ color:'#FF9900', width:4}
              }
            ]
          }
         }
         
      }
    }).addCase(fetchGetTopicPolicyList.pending, (state) => {
      state.loading.fetchGetTopicPolicyList = true;
    }).addCase(fetchGetTopicPolicyList.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      state.loading.fetchGetTopicPolicyList = false;
      if(code === '0'){
        
        data.dataList.forEach((item:any) => {
            var labeArr = [] as any;
            var showLable = item.labelList.slice(0,10)
            showLable.forEach((show:any) => {
              show.class = show.type === '出台部门' ? 'bm' : show.type === '机构' ? 'jg' : show.type === '重要人物' ? 'rw' : show.type === '行业' ? 'hy' : show.type === '经济数据' ? 'jj' : 'dq' 
            });
            item.labelList.forEach((label:any) => {
              var index = labeArr.findIndex(function(x:any) {
                return x.type === label.type;
              });
              
              if(index !== -1){
                labeArr[index].arr.push(label.name)
              }else{
                labeArr.push({
                  type:label.type,
                  class:label.type === '出台部门' ? 'bm' : label.type === '机构' ? 'jg' : label.type === '重要人物' ? 'rw' : label.type === '行业' ? 'hy' : label.type === '经济数据' ? 'jj' : 'dq' ,
                  arr:[label.name]
                })
              }
            });
            item.showLable = showLable
            item.labeArr = labeArr
        });

        
        state.topicPolicyList = {
            dataList:data.dataList?data.dataList :[],
            total:data.total?data.total :0
        }
      }
    }).addCase(fetchGetPolicyTopicConfiguration.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        let distributeEventDetailList= data.reduce(function(prev:any, cur:any) {
          prev.push({flag:cur.flag,id:cur.id,topic:cur.topic})
          state.policyTopicConfigurationItem[cur.id] = cur.dataList
          return  prev
        }, []);

        state.policyTopicConfiguration = distributeEventDetailList
        state.policyTopicConfigurationSelect = distributeEventDetailList
      }
    }).addCase(fetchGetPolicyTopicTree.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.policyTopicTree = data
      }
    }).addCase(fetchGetSwIndustry.pending, (state) => {
      state.loading.fetchGetSwIndustry = true;
    }).addCase(fetchGetSwIndustry.fulfilled, (state, { payload }) => {
      const {data,code,type} =payload;
      if(code === '0'){
        if(!data.length){
          state.swIndustry =[]
          state.swIndustrySencond = {}
          state.swIndustryThree ={}
          state.loading.fetchGetSwIndustry = false;
          return
        }
        let industryArr= data.reduce(function(prev:any, cur:any) {
          prev.push({nodeName:cur.nodeName,num:cur.num,id:cur.id,nodeList:cur.nodeList})
          state.swIndustrySencond[cur.id] = cur.nodeList
          return  prev
        }, []);
        data.forEach((item:any) => {
          item.nodeList.forEach((item1:any) => {
            state.swIndustryThree[item1.id] = item1.nodeList
          });
        });
        if(type === 'all'){
          state.swAllIndustry = industryArr
        }else{
          state.swIndustry = industryArr
        }
      }
        state.searchData.company = ''
        state.searchData.area = ''
        state.searchData.type = ''
        state.searchData.dep = ''
      state.loading.fetchGetSwIndustry = false;
    }).addCase(fetchGetPolicySwIndustry.pending, (state) => {
      state.loading.fetchGetPolicySwIndustry = true;
    }).addCase(fetchGetPolicySwIndustry.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        if(!data.length){
          state.swIndustry =[]
          state.swIndustrySencond = {}
          state.swIndustryThree ={}
          state.loading.fetchGetPolicySwIndustry = false;
          return
        }
        let industryArr= data.reduce(function(prev:any, cur:any) {
          prev.push({nodeName:cur.nodeName,num:cur.num,id:cur.id,nodeList:cur.nodeList})
          state.swIndustrySencond[cur.id] = cur.nodeList
          return  prev
        }, []);
        data.forEach((item:any) => {
          item.nodeList.forEach((item1:any) => {
            state.swIndustryThree[item1.id] = item1.nodeList
          });
        });
        state.swAllIndustry = industryArr
      }
        state.searchData.company = ''
        state.searchData.area = ''
        state.searchData.type = ''
        state.searchData.dep = ''
      state.loading.fetchGetPolicySwIndustry = false;
    }).addCase(fetchGetIssuingDep.pending, (state) => {
      state.loading.fetchGetIssuingDep = true;
    }).addCase(fetchGetIssuingDep.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        data.forEach((item:any) => {
          if(item.type ==='国家'){state.depData.country = item.dataList;state.depData.countryNum = item.sum;}
          if(item.type ==='部委'){state.depData.ministriesCommissions = item.dataList;state.depData.ministriesCommissionsNum = item.sum;}
          if(item.type ==='地方'){
            let province = ['北京','上海','天津','重庆','安徽','澳门','福建','甘肃','广东','广西','贵州','海南','河北','河南','黑龙江','湖北','湖南','江苏','江西','辽宁','内蒙古','宁夏',
            '青海','山东','山西','陕西','四川','台湾','西藏','香港','新疆','云南','浙江']
            var newArr = [] as any
            province.forEach(x => {
              let countArr = item.dataList.reduce(function(prev:any, cur:any) {
                if(cur.name.includes(x)){
                  prev.push(cur)
                }
                return  prev
              }, []);
              newArr = newArr.concat(countArr)
            });
            let newAr1r = [] as any  //没有匹配到省市的 数据
            item.dataList.forEach((d:any) => {
              var isExist = false;	
              newArr.forEach((m:any) => {
                if(d.name === m.name){
                  isExist = true;
                  return false;
                }
              })
              if(!isExist) newAr1r.push(d);
            })
            state.depData.locality = newArr.concat(newAr1r)
            state.depData.localityNum = item.sum;
          }
        });
        state.searchData.company = ''
        state.searchData.area = ''
        state.searchData.type = ''
        state.searchData.industry = ''
        state.issuingDep = data
      }
      state.loading.fetchGetIssuingDep = false;
    }).addCase(fetchGetInvolveArea.fulfilled, (state, { payload }) => {
      const {data,code,type} =payload;
      if(code === '0'){
        if(type === 'all'){
          const mapData = [] as any
          const numArr = [] as any
          data.forEach((item:any) => {
            numArr.push(item.num)
          });
          const max = Math.max.apply(null, numArr);
          data.forEach((item:any) => {
            mapData.push({...item,
              value:item.num,
              itemStyle:{
                color:`rgba(255,153,0,${max === 0 ? 0.4 : item.num/max < 0.7 ? item.num/max + 0.4 : item.num/max})`,
              }
            })
          });
          state.mapData = mapData
        }
        state.searchData.company = ''
        state.searchData.type = ''
        state.searchData.industry = ''
        state.searchData.dep = ''
        state.involveArea = data
      }
    }).addCase(fetchGetNewInvolveArea.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        const mapData = [] as any
        const numArr = [] as any
        data.forEach((item:any) => {
          numArr.push(item.num)
        });
        const max = Math.max.apply(null, numArr);
        data.forEach((item:any) => {
          mapData.push({...item,
            value:item.num,
            itemStyle:{
              color:`rgba(255,153,0,${max === 0 ? 0.4 : item.num/max < 0.7 ? item.num/max + 0.4 : item.num/max})`,
            }
          })
        });
        state.mapData = mapData;
        state.searchData.company = ''
        state.searchData.type = ''
        state.searchData.industry = ''
        state.searchData.dep = ''
        state.involveArea = data
      }
    }).addCase(fetchGetTopicPolicyInvolveAreaCount.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.topicPolicyInvolveAreaCount = data
      }
    }).addCase(fetchGetPolicyInvolveAreaCount.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.topicPolicyInvolveAreaCount = data
      }
    }).addCase(fetchGetInvolveCompany.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.searchData.area = ''
        state.searchData.type = ''
        state.searchData.industry = ''
        state.searchData.dep = ''
        state.involveCompany = data
      }
    }).addCase(fetchGetPolicyType.fulfilled, (state, { payload }) => {
      const {data,code,type} =payload;
      if(code === '0'){
        data.forEach((item:any) => {
          item.value = item.num
        });
        if(type === 'all'){
          state.allPolicyType = data
        }else{
          state.policyType = data
        }
        state.searchData.company = ''
        state.searchData.area = ''
        state.searchData.industry = ''
        state.searchData.dep = ''
      }
    }).addCase(fetchGetPolicyTypeCount.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        data.forEach((item:any) => {
          item.value = item.num
        });
        state.allPolicyType = data
        state.searchData.company = ''
        state.searchData.area = ''
        state.searchData.industry = ''
        state.searchData.dep = ''
      }
    }).addCase(fetchGetTimeLineByTopic.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        let countArr = data.reduce(function(prev:any, cur:any) {
          prev.push(Number(cur.count))
          return  prev
        }, []);
        const max = Math.max.apply(null, countArr);
        data.forEach((item:any) => {
          item.monthTime = moment(Number(item.timestamp)).format('YYYY.MM')
          let val = (Number(item.count) / max ) * 100
          item.height = val < 20  ? 20 : val.toFixed(0) + '%'
        });
        state.timeLineByTopic = data
      }
    }).addCase(fetchGetTopicPolicyInfo.pending, (state) => {
      state.loading.fetchGetTopicPolicyInfo = true;
    }).addCase(fetchGetTopicPolicyInfo.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        if(data.labelList){
          data.labelList.forEach((item:any) => {
            item.class =item.type === '出台部门' ? 'bm' : item.type === '机构' ? 'jg' : item.type === '重要人物' ? 'rw' : item.type === '行业' ? 'hy' : item.type === '经济数据' ? 'jj' : 'dq' 
          });
        }
        if(!data.reportContentHtml)  data.reportContentHtml =  data.reportContent;
       
        state.topicPolicyInfo = data
      }
      state.loading.fetchGetTopicPolicyInfo = false;
    }).addCase(fetchGetTopicLabelCount.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          const newData =  data.slice(0,200)
          const colorArr = ['#ff9900', '#ffad32', '#ffb74c', '#ffc166', '#ffa319', '#ffd699', '#ffe0b2', '#ffeacc', '#ffcc7f','#fff4e5']
          
          newData.forEach((item: any) => {
            item.color = colorArr[Math.floor(Math.random() * 10)]
            item.num = item.num  * 10000000 + (Math.random() * 1000)
            item.id ='111'
          });
          state.wordCloud = newData
          state.loading.wordCloud = false;
        }
    }).addCase(fetchGetPolicyLabelCount.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          const newData =  data.slice(0,200)
          const colorArr = ['#ff9900', '#ffad32', '#ffb74c', '#ffc166', '#ffa319', '#ffd699', '#ffe0b2', '#ffeacc', '#ffcc7f','#fff4e5']
          
          newData.forEach((item: any) => {
            item.color = colorArr[Math.floor(Math.random() * 10)]
            item.num = item.num  * 10000000 + (Math.random() * 1000)
            item.id ='111'
          });
          state.wordCloud = newData
          state.loading.wordCloud = false;
        }
    }).addCase(fetchGetPolicyTopicHistory.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          state.hotTopic = Object.entries(data).map((recommend: [string, any]) => (recommend[1] as Array<any>).map(item => ({
            ...item, 
            type: recommend[0] ==='hotLabel' ? '热门话题'  : '历史搜索'
          }))).flat();
        }
    }).addCase(searchTopic.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          
          if(data.length > 0){
            const topicArr = [] as any
            data.forEach((item:any) => {
              item.dataList.forEach((topic:any) => { 
                topicArr.push({topicId: topic, topic: topic.topic})
              });
            });
            state.searchList = topicArr
          }
        }
    }).addCase(fetchGetTopicHot.pending, (state) => {
      state.loading.fetchGetTopicHot = true;
    }).addCase(fetchGetTopicHot.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          const lineData: any = [];

          data.forEach((item: any) => {
            lineData.push(item.sum)
          });
          var maxNum = Math.max.apply(null, lineData)
          
          data.forEach((item: any) => {
            if(maxNum === 0){
              item.percentage =  '0%'
            }else{
              let val = (Number(item.sum) / maxNum ) * 100
              item.percentage = val + '%'
            }
            item.barTypeNumList = JSON.parse(JSON.stringify(item.typeNumList)).reverse()
            lineData.push(item.sum)
          
          
          });
         
          state.topicHot = data
        }
        state.loading.fetchGetTopicHot = false;
    }).addCase(fetchGetPolicyTopicPage.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          state.topicPage = data
        }
    }).addCase(fetchGetImportMeetingList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          state.importMeetingList = data
        }
    }).addCase(fetchGetUserPolicyTagOrder.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          state.headerList = JSON.parse(JSON.stringify(data));
          // let titleIndex = 1;
          // state.headerList.forEach((item,index)=>{
          //   if(item.name === '政策标题'){
          //       titleIndex = index;
          //   }
          // })
          state.headerList.splice(0, 0, {name: "政策对比", fields: "", visible: true, width: "50",widthTwo: null, minwidth: "50"});
          state.headerListTwo = data
        }
    }).addCase(fetchGetPreviousMeetingList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          state.previousMeetingList = data
        }
    }).addCase(fetchPolicyHtmlDiff.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          state.htmlDiff[0].html = data[0].replaceAll('width: 1000px;','')
          state.htmlDiff[1].html = data[1].replaceAll('width: 1000px;','')
        }
    }).addCase(downloadPolicyInfo.fulfilled, (state, { payload }) => {
    }).addCase(downloadPolicyHot.fulfilled, (state, { payload }) => {
    }).addCase(fetchWordCloudDetailsInfo.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          state.wordCloudDetailsInfo = data;
          state.setDfcfTotal = Math.ceil(data.dcTotal / 5);
          state.setXqfTotal = Math.ceil(data.xqTotal / 5);
        }
    }).addCase(fetchUpdateUserPolicyTagOrder.fulfilled, (state, { payload }) => {
    }).addCase(fetchGetIndustryVein.fulfilled, (state, { payload }) => {
    }).addCase(getTopicPolicyListByTimeLine.pending, (state) => {
      state.loading.getTopicPolicyListByTimeLine = true;
      state.policyTimeLine = {dataList:[],total:''}
    }).addCase(getTopicPolicyListByTimeLine.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        var newArr = [] as any
        data.dataList.forEach((item:any) => {
          if(item.dataList.length ){
            if(item.dataList[0][0].flag){
              newArr.push( item.dataList[0][0])
            }else{
              newArr.push( item)
            }
          }else{
            newArr.push(item)
          }
        });
        state.policyTimeLine = {dataList:newArr,total:data.total}
        state.loading.getTopicPolicyListByTimeLine = false;
      }

    }).addCase(fetcheHotInfo.pending, (state) => {
      state.loading.fetcheHotInfo = true;
    }).addCase(fetcheHotInfo.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const lineData: any = [];
        data.forEach((item: any) => {
          lineData.push(item.sum)
        });
        var maxNum = Math.max.apply(null, lineData)
        
        data.forEach((item: any) => {
          if(maxNum === 0){
            item.percentage =  '0%'
          }else{
            let val = (Number(item.sum) / maxNum ) * 100
            item.percentage = val + '%'
          }
          item.barTypeNumList = JSON.parse(JSON.stringify(item.typeNumList)).reverse()
          lineData.push(item.sum)
        });
        state.topicHot = data
      }
      state.loading.fetcheHotInfo = false;
    }).addCase(getPolicyFilterList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.filterList = data
      }
    })


    
    
  },
})

export const {setTopicHot,setLoadingType,setPolicyReleaseTime,setRestPolicyPage,setActiveFilterItem,setDrawer,setPolicyParams,setCharacter,setPopoverClosed, setHtmlDiff,setIndustrInvolved, setHeaderList,setPreviousMeetingList, setSearchData,setGetSwIndustryParams, setPageParams,setTimeOpen, setSearching, setTopic, setPolicyTopicConfigurationItemAllChecked,setPolicyTopicConfigurationItemChecked,setRestPolicyNews,setContentFlag,setNewContentIndex,setNewTopicContentIndex,setHeighlightContentIndex,setPolicyAnalysisList,restEconomicIndicators,setEconomicIndicators, setPolicyAnalysisId,setNewContent,setListClass,setNewsId} = policyAnalysis.actions;

const selectBaseState = (state: RootState) => state.policyAnalysis;
export const selectLoadingByType = (type: string) => createSelector(selectBaseState, state => state.loading[type] || false);
export const selectPolicyAnalysisList =  createSelector(selectBaseState, state => state.policyAnalysisList);
export const selectListClass = createSelector(selectBaseState, state => state.listClass || '')
export const selectPolicyParseInfo =  createSelector(selectBaseState, state => state.policyParseInfo)
export const selectPolicyAnalysisId =  createSelector(selectBaseState, state => state.policyAnalysisId)
export const selectPolicyNewsFilter =  createSelector(selectBaseState, state => state.policyNewsFilter)
export const selectPolicyNewsGraph =  createSelector(selectBaseState, state => state.policyNewsGraph)
export const selectPolicyNewsList =  createSelector(selectBaseState, state => state.policyNewsList)
export const selectEconomicIndicatorsInfo =  createSelector(selectBaseState, state => state.economicIndicatorsInfo)
export const selectEconomicList =  createSelector(selectBaseState, state => state.economicList)
export const selectChartData = (type: string) => createSelector(selectBaseState, state => state.chartData[type]|| {xAxis :[],barSeriesData:[],lineSeriesData:[]});
export const selectShibor =  createSelector(selectBaseState, state => state.shibor)
export const selectLpr =  createSelector(selectBaseState, state => state.lpr)
export const selectExchangeRate =  createSelector(selectBaseState, state => state.exchangeRate)
export const selectEconomicUrl =  createSelector(selectBaseState, state => state.economicUrl)
export const selectcontentFlag =  createSelector(selectBaseState, state => state.contentFlag)
export const selectTopicPolicyList =  createSelector(selectBaseState, state => state.topicPolicyList);
export const selectPolicyTopicTree =  createSelector(selectBaseState, state => state.policyTopicTree)
export const selectPolicyTopicConfigurationItem = (type: string) => createSelector(selectBaseState, state => state.policyTopicConfigurationItem[type] || []);
export const selectPolicyTopicConfigurationItemAll =createSelector(selectBaseState, state => state.policyTopicConfigurationItem);
export const selectPolicyTopicConfiguration = createSelector(selectBaseState, state => state.policyTopicConfiguration)
export const selectPolicyTopicConfigurationSelect = createSelector(selectBaseState, state => state.policyTopicConfigurationSelect)
export const selectSwIndustry = createSelector(selectBaseState, state => state.swIndustry)
export const selectSwIndustrySecond = (type: string) => createSelector(selectBaseState, state => state.swIndustrySencond[type] || []);
export const selectSwIndustryThree = (type: string) => createSelector(selectBaseState, state => state.swIndustryThree[type] || []);
export const selectIssuingDep = createSelector(selectBaseState, state => state.issuingDep)
export const selectInvolveArea = createSelector(selectBaseState, state => state.involveArea)
export const selectPolicyType = createSelector(selectBaseState, state => state.policyType)
export const selectInvolveCompany = createSelector(selectBaseState, state => state.involveCompany)
export const selectTimeLineByTopic = createSelector(selectBaseState, state => state.timeLineByTopic)
export const selectTopicPolicyInfo = createSelector(selectBaseState, state => state.topicPolicyInfo)
export const selectActiveTopic = createSelector(selectBaseState, state => state.activeTopic)
export const selectWordCloud = createSelector(selectBaseState, state => state.wordCloud || [])
export const selectHotTopic = createSelector(selectBaseState, state => state.hotTopic || [])
export const selectIsSearching = createSelector(selectBaseState, state => state.isSearching)
export const selectSearchList = createSelector(selectBaseState, state => state.searchList);
export const selectMapData = createSelector(selectBaseState, state => state.mapData || []);
export const selectTopicHotChart = createSelector(selectBaseState, state => state.topicHotChart || {xAxis:[],maxNum:10,seriesData:[]});
export const selectTopicHot = createSelector(selectBaseState, state => state.topicHot);
export const selectTimeOpen = createSelector(selectBaseState, state => state.timeOpen);
export const selectTopicPage = createSelector(selectBaseState, state => state.topicPage);
export const selectPageParams = createSelector(selectBaseState, state => state.pageParams);
export const selectSwIndustryParams = createSelector(selectBaseState, state => state.swIndustryParams);
export const selectTopicPolicyInvolveAreaCount = createSelector(selectBaseState, state => state.topicPolicyInvolveAreaCount);
export const selectDepData = createSelector(selectBaseState, state => state.depData);
export const selectSwAllIndustry = createSelector(selectBaseState, state => state.swAllIndustry);
export const selectAllPolicyType = createSelector(selectBaseState, state => state.allPolicyType);
export const selectImportMeetingList = createSelector(selectBaseState, state => state.importMeetingList);
export const selectPreviousMeetingList = createSelector(selectBaseState, state => state.previousMeetingList);
export const selectHtmlDiff = createSelector(selectBaseState, state => state.htmlDiff);
export const selectCharacter = createSelector(selectBaseState, state => state.character);
export const selectSearchData = (type: string) => createSelector(selectBaseState, state => state.searchData[type] || '');
export const selectWordCloudDetailsInfo = createSelector(selectBaseState, state => state.wordCloudDetailsInfo);
export const selectDfcfTotal = createSelector(selectBaseState, state => state.setDfcfTotal);
export const selectXqfTotal = createSelector(selectBaseState, state => state.setXqfTotal);
export const selectInvolved = createSelector(selectBaseState, state => state.industrInvolved);
export const selectHeaderList =  createSelector(selectBaseState, state => state.headerList);
export const selectHeaderListTwo =  createSelector(selectBaseState, state => state.headerListTwo);
export const selectPopoverClosed =  createSelector(selectBaseState, state => state.popoverClosed);
export const selectPolicyTimeLine =  createSelector(selectBaseState, state => state.policyTimeLine);
export const selectFilterList =  createSelector(selectBaseState, state => state.filterList);
export const selectPolicyParams =  createSelector(selectBaseState, state => state.policyParams || {});
export const selectDrawer =  createSelector(selectBaseState, state => state.drawer || false);
export const selectRestPolicyPage =  createSelector(selectBaseState, state => state.restPolicyPage || false);
export const selectActiveFilterItem =  createSelector(selectBaseState, state => state.activeFilterItem || {id:'',filterName:''});
export const selectPolicyReleaseTime =  createSelector(selectBaseState, state => state.policyReleaseTime || []);

export default policyAnalysis.reducer as Reducer<typeof initialState>;
