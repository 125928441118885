import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import { checkToken } from '../../user/reducer';
import moment from 'moment';
interface FetchedListProps {
  result?: Array<any>,
  dataList: Array<any>,
  page_no?: number,
  page_size?: number,
  total?: number,
  total_page?: number,
  data_recommend?: {
    [key: string]: Array<any>
  },
  data_recormend?: any,
  hasMore?: boolean,
}

interface StateProps {
  loading: {
    [key: string]: boolean
  },
  topStockCompany: Array<any>,
  topStockCompanySearch: boolean,
  industryBar: Array<any>,
  authorList: Array<any>,
  postStatistics: Array<any>,
  xueqiuStatistics: Array<any>,
  wordCloud: Array<any>,
  authorInfo: any,
  updateFrequency: any,
  fansAndPolarity: any,
  accumulatedFansNum: any,
  authorArticleReadCommentNum: any,
  overviewArticleStatistics: any,
  overviewPolarityStatistics: any,
  barPolarityStatistics: any,
  macroPolarityStatistics:any,
  overviewAuthorHotTrend: any,
  articleListOptions: {
    articleList: Array<any>,
    total: number,
    hasMore?: boolean,
    size: number,
    page?: number
  },
  isSearching: boolean,
  recommend: Array<any>,
  search: FetchedListProps,
  companyInfo: any,
  treeData: Array<any>,
  heatCurve:any,
  heatList:Array<any>,
  stockCode:any,
  newCompanyProfile:any,
  moreData:any,
  articleAndComment:{
    df:{
      dataList:Array<any>,
      total: number
    },
    xq:{
      dataList:Array<any>,
      total: number
    }
  },
  barInfo:any

}

const initialState: StateProps = {
  loading: {},
  topStockCompany: [],
  topStockCompanySearch: false,
  industryBar: [],
  authorList: [],
  postStatistics: [],
  xueqiuStatistics: [],
  wordCloud: [],
  authorInfo: {},
  updateFrequency: {},
  fansAndPolarity: {},
  accumulatedFansNum: {},
  authorArticleReadCommentNum: {},
  overviewArticleStatistics: {},
  overviewPolarityStatistics: {},
  barPolarityStatistics:{},
  macroPolarityStatistics:{
    'xAxis': [], 'heatNum':[], 'manyNum': [], 'maxNum': 50, 'neutralNum': [], 'emptyNum': [], 'markData':[], 'irrelevanceNum': []
  },
  overviewAuthorHotTrend: {},
  articleListOptions: {
    articleList: [],
    total: 0,
    size: 10,
    hasMore: true,
    page: 0
  },
  isSearching: false,
  recommend: [],
  search: {
    dataList: []
  },
  companyInfo: null,
  treeData: [],
  heatCurve:{
    xAxis: [], 
    seriesData: [],
    markData:[],
    turnoverNow:[],
    turnoverNext:[],
    turnoverRateNow:[],
    turnoverRateNext:[],
    amplitudeNow:[],
    amplitudeNext:[],
    TransactionPrice:[],
    TradingVolume:[],
    RiseAndFallNow:[],
    RiseAndFallNext:[],
    multipleSpaceNow:[],
    multipleSpaceNext:[],
    PersistenceCurvee:[]
  },
  heatList:[],
  stockCode:'',
  newCompanyProfile:{},
  moreData:{},
  articleAndComment:{
    df:{
      dataList:[],
      total: 0
    },
    xq:{
      dataList:[],
      total: 0
    }
  },
  barInfo:{alphabet:'',name:''}
}

export const fetchGetTopStockCompany = createAsyncThunk(
  'noyaxe/get-topStock-company',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetTopStockCompany(params))
  }
)

export const fetchGetAuthorArticleList = createAsyncThunk(
  'noyaxe/get-author-articleList',
  async (params: any, thunkAPI) => {
    const { page } = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetAuthorArticleList(params);
      return {
        page,
        ...result
      }
    })
  }
)
export const fetchGetAuthorPage = createAsyncThunk(
  'noyaxe/get-author-page',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetAuthorPage())
  }
)
export const fetchGetIndustryBar = createAsyncThunk(
  'noyaxe/get-industry-bar',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetIndustryBar())
  }
)

export const fetchGetAuthorDetail = createAsyncThunk(
  'noyaxe/get-author-detail',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetAuthorDetail(params))
  }
)
export const fetchGetNewFansAndPolarity = createAsyncThunk(
  'noyaxe/get-fans-polarity',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetNewFansAndPolarity(params))
  }
)
export const fetchGetUpdateFrequency = createAsyncThunk(
  'noyaxe/get-update-frequency',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetUpdateFrequency(params))
  }
)

export const fetchGetAccumulatedFansNum = createAsyncThunk(
  'noyaxe/get-accumulated-fansNum',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetAccumulatedFansNum(params))
  }
)

export const fetchGetAuthorArticleReadCommentNum = createAsyncThunk(
  'noyaxe/get-author-article-read-comment',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetAuthorArticleReadCommentNum(params))
  }
)

export const fetchGetOverviewArticleStatistics = createAsyncThunk(
  'noyaxe/get-overview-article-statistics',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetOverviewArticleStatistics(params))
  }
)

export const fetchGetOverviewPolarityStatistics = createAsyncThunk(
  'noyaxe/get-author-overview-polarity-statistics',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetOverviewPolarityStatistics(params))
  }
)

export const fetchGetOverviewAuthorHotTrend = createAsyncThunk(
  'noyaxe/get-author-overview-author-hotTrend',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetOverviewAuthorHotTrend(params))
  }
)

export const fetchGetBarPolarityStatistics = createAsyncThunk(
  'noyaxe/get-bar-polarity-statistics',
  async (params: any, thunkAPI) => {
    const { showNeutral , timeType, source } = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      if(source === 'bar'){
        const result = await pandora.fetchGetBarPolarityStatistics(params);
        return {
          source,
          showNeutral,
          ...result
        }
      }else{
        const result = await pandora.fetchGetMacro(params);
        return {
          timeType,
          source,
          showNeutral,
          ...result
        }
      }
    })
  }
)

export const fetchGetHeatCurve = createAsyncThunk(
  'noyaxe/fetch-get-heat-curve',
  async (params: any, thunkAPI) => {
    const {timeType} = params
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetHeatCurve(params);
      return {
        timeType,
        ...result
      }
    })
  }
)
export const fetchGetOtherCurve = createAsyncThunk(
  'noyaxe/fetch-get-heat-other-curve',
  async (params: any, thunkAPI) => {
    const {key} = params
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetOtherCurve(params);
      return {
        key,
        ...result
      }
    })
  }
)
export const fetchGetCompanyProfile = createAsyncThunk(
  '/noyaxep/fetch-get-company-profile',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyProfile(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetTradingVolume = createAsyncThunk(
  'noyaxe/fetch-get-trading-volume',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetTradingVolume(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetPersistenceCurvee = createAsyncThunk(
  'noyaxe/fetch-get-persistence-curvee',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetPersistenceCurvee(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetTransactionPrice = createAsyncThunk(
  'noyaxe/fetch-get-transaction-price',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetTransactionPrice(params);
      return {
        ...result
      }
    })
  }
)
export const fetchFindArticleAndCommentByBarId = createAsyncThunk(
  'noyaxe/fetch-find-article-and-comment-by-barId',
  async (params: any, thunkAPI) => {
    const {webType} = params
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchFindArticleAndCommentByBarId(params);
      return {
        webType,
        ...result
      }
    })
  }
)

export const fetchGetWordCloud = createAsyncThunk(
  'noyaxe/get-word-cloud',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetWordCloud(params))
  }
)

export const fetchGetStockCompanyHeatGraphList = createAsyncThunk(
  'noyaxe/get-stock-company-heat-graph-list',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetStockCompanyHeatGraphList())
  }
)

export const fetchGetStockCompanyHeatGraphDetail = createAsyncThunk(
  'noyaxe/get-stock-company-heat-graph-detail',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetStockCompanyHeatGraphDetail(params))
  }
)


export const fetchGetIndustryBarPostStatistics = createAsyncThunk(
  'noyaxe/get-industr-barPost-statistics',
  async (params: any, thunkAPI) => {
    const { webName } = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetIndustryBarPostStatistics(params);
      return {
        webName,
        ...result
      }
    })
  }
)


export const fetchGetPostStatistics = createAsyncThunk(
  'noyaxe/get-post-statistics',
  async (params: any, thunkAPI) => {
    const { webName } = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetPostStatistics(params);
      return {
        webName,
        ...result
      }
    })
  }
)

export const fetchCompanyList = createAsyncThunk(
  '/watch-list/fetch-company',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp: any) => pandora.fetchSearchStockCompany(params))
  }
)

export const searchStockCompany = createAsyncThunk(
  'search/search-stock-company',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp: any) => pandora.fetchSearchStockCompany(params))
  }
)

export const fetchGetCompanyDetail = createAsyncThunk(
  'search/fetch-get-company-detail',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp: any) => pandora.fetchGetCompanyDetail(params))
  }
)


export const noyaxe = createSlice({
  name: 'noyaxe',
  initialState,
  reducers: {
    setNodataCompany: (state, { payload }) => {
      state.topStockCompany = payload;
    },
    setSearching: (state, { payload }) => {
      state.isSearching = payload
    },
    setLoading: (state, { payload }) => {
      state.loading[payload] = false
    },
    setStockCode: (state, { payload }) => {
      state.stockCode = payload
    },
    setMoredata: (state, { payload }) => {
      state.moreData = payload
    },
    setBarInfo: (state, { payload }) => {
      state.barInfo = payload
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetTopStockCompany.pending, state => {
      state.loading.topStockCompany = true;
    }).addCase(fetchGetTopStockCompany.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.topStockCompany = data
        state.topStockCompanySearch = true;
        state.loading.topStockCompany = false;
      }
    }).addCase(fetchGetIndustryBar.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.industryBar = data
      }
    }).addCase(fetchGetAuthorArticleList.fulfilled, (state, { payload }) => {
      const { code, data, page } = payload;
      if (code === '0') {
        for (var i in data.dataList) {
          var num = data.dataList[i].emptyNum + data.dataList[i].manyNum + data.dataList[i].irrelevantNum
          data.dataList[i].NO = (Number(i) + 1) + Number(page) * 10
          data.dataList[i].emptyPercent = Math.ceil(data.dataList[i].emptyNum / num * 100) || 0
          data.dataList[i].manyPercent = Math.ceil(data.dataList[i].manyNum / num * 100) || 0
          data.dataList[i].irrelevantPercent = Math.ceil(data.dataList[i].irrelevantNum / num * 100) || 0
        }
        state.articleListOptions = {
          articleList: data.dataList,
          total: data.total,
          size: 10,
          hasMore: !Boolean(data.dataList.length < 10),
          page: page
        }
      }
    }).addCase(fetchGetAuthorPage.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.authorList = [{ name: '总览' + data.dataList.length, id: '0' }].concat(data.dataList)
      }
    }).addCase(fetchGetAuthorDetail.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.authorInfo = data
      }
    }).addCase(fetchGetNewFansAndPolarity.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const manyNum: any = [];
        const xAxis: any = [];
        const fansNum: any = [];
        const emptyNum: any = [];
        data.forEach((item: any) => {
          xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
          emptyNum.push(item.emptyNum)
          fansNum.push(item.fansNum)
          manyNum.push(item.manyNum)
        });

        var maxNum =Math.ceil(Math.max.apply(null, fansNum) / 20) * 20
        var minNum =Math.floor(Math.min.apply(null,fansNum) / 20) * 20
        state.fansAndPolarity = { 'xAxis': xAxis, 'emptyNum': emptyNum, 'fansNum': fansNum, 'manyNum': manyNum,'minNum':minNum, 'maxNum': maxNum }
      }
    }).addCase(fetchGetUpdateFrequency.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const toutiaoData: any = [];
        const weiboData: any = [];
        const wechartData: any = [];
        const lengthObj: any = []
        for (var i in data) {
          lengthObj.push({'index':i,'len':data[i].dateNumDtoList.length})
          if (data[i].name === '今日头条' && data[i].dateNumDtoList.length) {
            data[i].dateNumDtoList.forEach((item: any) => {
              toutiaoData.push(item.num)
            })
          }
          if (data[i].name === '微博' && data[i].dateNumDtoList.length) {
            data[i].dateNumDtoList.forEach((item: any) => {
              weiboData.push(item.num)
            })
          }
          if (data[i].name === '公众号' && data[i].dateNumDtoList.length) {
            data[i].dateNumDtoList.forEach((item: any) => {
              wechartData.push(item.num)
            })
          }
          
        }
        const max:number = Math.max.apply(Math,data.map((item:any) => { return item.dateNumDtoList.length }))
        if(max){
          for(var l in lengthObj){
            if(lengthObj[l].len === max){
              const xAxis: any = [];
              data[lengthObj[l].index].dateNumDtoList.forEach((item:any) => {
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
              })
              state.updateFrequency = { 'xAxis': xAxis, 'weiboData': weiboData, 'wechartData': wechartData, 'toutiaoData': toutiaoData }
              return
            }
          }
        }else{
          state.updateFrequency = { 'xAxis': [], 'weiboData': [], 'wechartData': [], 'toutiaoData': [] }
        }
       
      }
    }).addCase(fetchGetAccumulatedFansNum.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const xAxis: any = [];
        const xAxisZh: any = [];
        const seriesData: any = []
        data.forEach((item: any) => {
          xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
          xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MM月')}`)
          seriesData.push(item.num / 10000)
        });
        state.accumulatedFansNum = { 'xAxis': xAxis,'xAxisZh': xAxisZh, 'seriesData': seriesData }
      }
    }).addCase(fetchGetAuthorArticleReadCommentNum.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const xAxis: any = [];
        const commentNum: any = []
        const likeNum: any = []
        data.forEach((item: any) => {
          xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
          commentNum.push(item.commentNum)
          likeNum.push(item.likeNum)
        });
        state.authorArticleReadCommentNum = { 'xAxis': xAxis, 'commentNum': commentNum, 'likeNum': likeNum }
      }
    }).addCase(fetchGetOverviewArticleStatistics.pending, state => {
      state.loading.overviewArticleStatistics = true;
    }).addCase(fetchGetOverviewArticleStatistics.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const xAxis: any = [];
        const xAxisZh: any = [];
        const articleNum: any = []
        const commentNum: any = []
        const likeNum: any = []
        data.forEach((item: any) => {
          xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
          xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MM月')}`)
          commentNum.push(item.commentNum / 1000)
          likeNum.push(item.likeNum / 1000)
          articleNum.push(item.articleNum)
        });
        var maxLineNum =Math.ceil(Math.max.apply(null, likeNum) / 20) * 20
        var maxBarNum =Math.ceil(Math.max.apply(null, articleNum) / 20) * 20
        state.overviewArticleStatistics = { 'maxLineNum':maxLineNum, 'maxBarNum':maxBarNum, 'xAxis': xAxis,'xAxisZh': xAxisZh, 'commentNum': commentNum, 'likeNum': likeNum, 'articleNum': articleNum }
        state.loading.overviewArticleStatistics = false;
      }
    }).addCase(fetchGetOverviewPolarityStatistics.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const xAxis: any = [];
        const xAxisZh: any = [];
        const emptyNum: any = []
        const irrelevanceNum: any = []
        const manyNum: any = []
        const neutralNum: any = []
        data.forEach((item: any) => {
          xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
          xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MM月')}`)
          neutralNum.push(item.manyNum+item.irrelevanceNum+item.emptyNum+item.neutralNum )
        });
        var maxNum = Math.max.apply(null, neutralNum)
        data.forEach((item: any) => {
          var activeNum = Number(item.manyNum + item.neutralNum + item.emptyNum + item.irrelevanceNum)
          manyNum.push( {value:item.manyNum / activeNum * maxNum})
          irrelevanceNum.push({value:(item.irrelevanceNum + item.neutralNum) / activeNum * maxNum})
          emptyNum.push({value:item.emptyNum / activeNum * maxNum})
        });
        for(var i in manyNum){
          if(manyNum[i].value && emptyNum[i].value){
            manyNum[i].itemStyle={opacity:0.8, borderRadius:[10,10,0,0]}
            emptyNum[i].itemStyle={opacity:0.8, borderRadius:[0,0,10,10]}
            irrelevanceNum[i].itemStyle={opacity:0.8,color:'#50B6EF'}
          }
          if(!manyNum[i].value && !emptyNum[i].value){
            irrelevanceNum[i].itemStyle={opacity:0.8,color:'#50B6EF',borderRadius:[10,10,10,10]}
          }
          if(!manyNum[i].value && emptyNum[i].value && irrelevanceNum[i].value){
            irrelevanceNum[i].itemStyle={opacity:0.8,color:'#50B6EF',borderRadius:[10,10,0,0]}
            emptyNum[i].itemStyle={opacity:0.8, borderRadius:[0,0,10,10]}
          }
          if(manyNum[i].value && !emptyNum[i].value && irrelevanceNum[i].value){
            manyNum[i].itemStyle={opacity:0.8,borderRadius:[10,10,0,0]}
            irrelevanceNum[i].itemStyle={opacity:0.8,color:'#50B6EF', borderRadius:[0,0,10,10]}
          }
          if(manyNum[i].value && !emptyNum[i].value && !irrelevanceNum[i].value){
            manyNum[i].itemStyle={opacity:0.8,borderRadius:[10,10,10,10]}
          }
          if(!manyNum[i].value && emptyNum[i].value && !irrelevanceNum[i].value){
            emptyNum[i].itemStyle={opacity:0.8,borderRadius:[10,10,10,10]}
          }
        }

        state.overviewPolarityStatistics = { 'xAxis': xAxis,'xAxisZh': xAxisZh,  'manyNum': manyNum, 'maxNum': maxNum, 'neutralNum': neutralNum, 'emptyNum': emptyNum, 'irrelevanceNum': irrelevanceNum }
      }
    }).addCase(fetchGetBarPolarityStatistics.fulfilled, (state, { payload }) => {
      const { showNeutral, timeType,source , code, data } = payload;
      if (code === '0') {
        const xAxis: any = [];
        const xAxisZh: any = [];
        const emptyNum: any = []
        const irrelevanceNum: any = []
        const manyNum: any = []
        const neutralNum: any = []
        const markData: any = []
        const heatNum: any = []
        if(showNeutral === 'show'){
          data.forEach((item: any,) => {
            heatNum.push({value:item.heatNum,...item})
            if(timeType === 'Day' || source === 'bar' ){
              if(source !== 'bar'){
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('MM')}-${moment(Number(item.dateTimestamp)).format('DD')}`)
                xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('MM')}-${moment(Number(item.dateTimestamp)).format('DD')}`)
              }else{
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
                xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MM月')}`)
              }
            }else{
              if(source !== 'bar'){
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('HH')}:${moment(Number(item.dateTimestamp)).format('mm')}\n${moment(Number(item.dateTimestamp)).format('MM')}-${moment(Number(item.dateTimestamp)).format('DD')}`)
                xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('HH')}:${moment(Number(item.dateTimestamp)).format('mm')}\n${moment(Number(item.dateTimestamp)).format('MM')}-${moment(Number(item.dateTimestamp)).format('DD')}`)

              }else{
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('HH')}\n${moment(Number(item.dateTimestamp)).format('mm')}\n${moment(Number(item.dateTimestamp)).format('DD')}日`)
                xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('HH')}\n${moment(Number(item.dateTimestamp)).format('mm')}\n${moment(Number(item.dateTimestamp)).format('DD')}日`)
              }
            }
            neutralNum.push({value:item.manyNum+item.irrelevanceNum+item.emptyNum+item.neutralNum ,...item})
          });
        }else{
          data.forEach((item: any,) => {
             heatNum.push({value:item.heatNum,...item})
            if(timeType === 'Day'){
              if(source !== 'bar'){
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('MM')}-${moment(Number(item.dateTimestamp)).format('DD')}`)
                xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('MM')}-${moment(Number(item.dateTimestamp)).format('DD')}`)
              }else{
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
                xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
              }
            }else{
              if(source !== 'bar'){
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('HH')}:${moment(Number(item.dateTimestamp)).format('mm')}\n${moment(Number(item.dateTimestamp)).format('MM')}-${moment(Number(item.dateTimestamp)).format('DD')}`)
                xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('HH')}:${moment(Number(item.dateTimestamp)).format('mm')}\n${moment(Number(item.dateTimestamp)).format('MM')}-${moment(Number(item.dateTimestamp)).format('DD')}`)
              }else{
                xAxis.push(`${moment(Number(item.dateTimestamp)).format('HH')}\n${moment(Number(item.dateTimestamp)).format('mm')}\n${moment(Number(item.dateTimestamp)).format('DD')}日`)
                xAxisZh.push(`${moment(Number(item.dateTimestamp)).format('HH')}\n${moment(Number(item.dateTimestamp)).format('mm')}\n${moment(Number(item.dateTimestamp)).format('DD')}日`)
              }
            }
            neutralNum.push({value:item.manyNum+item.emptyNum+item.neutralNum,...item} )
          });
        }
        var maxNum = Math.max.apply(null, neutralNum.map((item:any) =>{return item.value}))
        if(showNeutral === 'show'){
          data.forEach((item: any) => {
            var activeNum = Number(item.manyNum + item.neutralNum + item.emptyNum + item.irrelevanceNum)
            manyNum.push( {value:item.manyNum / activeNum * maxNum})
            irrelevanceNum.push({value:(item.irrelevanceNum + item.neutralNum) / activeNum * maxNum})
            emptyNum.push({value:item.emptyNum / activeNum * maxNum})
          });
        }else{
          data.forEach((item: any) => {
            var activeNum = Number(item.manyNum + item.neutralNum + item.emptyNum)
            manyNum.push( {value:item.manyNum / activeNum * maxNum})
            irrelevanceNum.push({value:0})
            emptyNum.push({value:item.emptyNum / activeNum * maxNum})
          });
        }
        
        for(var i in manyNum){
          if(manyNum[i].value && emptyNum[i].value){
            manyNum[i].itemStyle={opacity:0.8, borderRadius:[10,10,0,0]}
            emptyNum[i].itemStyle={opacity:0.8, borderRadius:[0,0,10,10]}
            irrelevanceNum[i].itemStyle={opacity:0.8,}
          }
          if(!manyNum[i].value && !emptyNum[i].value){
            irrelevanceNum[i].itemStyle={opacity:0.8,borderRadius:[10,10,10,10]}
          }
          if(!manyNum[i].value && emptyNum[i].value && irrelevanceNum[i].value){
            irrelevanceNum[i].itemStyle={opacity:0.8,borderRadius:[10,10,0,0]}
            emptyNum[i].itemStyle={opacity:0.8, borderRadius:[0,0,10,10]}
          }
          if(manyNum[i].value && !emptyNum[i].value && irrelevanceNum[i].value){
            manyNum[i].itemStyle={opacity:0.8,borderRadius:[10,10,0,0]}
            irrelevanceNum[i].itemStyle={opacity:0.8, borderRadius:[0,0,10,10]}
          }
          if(manyNum[i].value && !emptyNum[i].value && !irrelevanceNum[i].value){
            manyNum[i].itemStyle={opacity:0.8,borderRadius:[10,10,10,10]}
          }
          if(!manyNum[i].value && emptyNum[i].value && !irrelevanceNum[i].value){
            emptyNum[i].itemStyle={opacity:0.8,borderRadius:[10,10,10,10]}
          }
        }
        data.forEach((item: any, index: number) => {
          markData.push({
           coord: [index, item.heatNum],
           data:{...item},
           itemStyle:{color:'#fff'},
           symbol:'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAcCAYAAADvANYcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAS0SURBVHgBtVddaGNFFD63+btJmuaHYkwoSpe6tAkttEEp/jx0t/uyjS+uBbHQCiI+tSh9UZ8KLgiyslDQh4IvFV+sIOK2L0lXsLs+WLOBLWkh4G6jtdlaSmprmzR/4znTmXiTJreKmw8Od+bOzDnfPefMmbkANWCMuVC+Z83BN6QfzoGCk95gzcU7ZEdrtEVLYGZmRimXy53QRKB+N9lBMsoZDwhCpmw2e501EaSf7JA97HLjRqj2irFQKBhUVdX9mgcPf4V78fuQWE9CLncCqmqBYOAiXL70ErhdTt21pN/n85n8fj91iQXjJIhRMBhs2d3dNdAkPSXLt+9A9PYKhAZ64dVrI+D3eSGT+RNi99bg4xufwTASuXzpRV0SJLFYrKQgBBEAjBF5wYLi3NnZudHIlT/H7rOZDz9hv28/4v1fHqRYZPkHhh7hfXpP43furjYMB+lHO7RDVOH903AkEgmZEy2YOEpjL6xAeGSYf730iAT3zCthCF8dhsWlZd6nMNWiVCpxOx0dHcrW1ha3xZksLCzI+LQ0IpDYSPL0DfX38b6qmrnBj66/DwP9vTwclB+hgT5UxGA7vQN6QAIwOjoKFRIYDkbM9BalUanvSW+l/8Lzz6E8C3d/XIV1JOh2OytffqHzKUzelJ66KluVcBiNRtbW1kZh0yWjBXnn1lKUuz589Qr8B/BkFBH4Jxybm5vKwcFBQwIXOp+Gh7g1tfCjZ4gAbU1t/NPpP8Dn84IOGlZMXfh8T/BYx+JrlXdULygX1rFeSFCfTAR7LuqpY3VJYE6Aw+FouMqKBWwY9//iYpR/qSTW0/MMBAKnBikZby1FdOuEAPeETMxKxaRsPQ+UjFncAbOffs7DMIA75eWRK5xUdHkFq+gaJyB3UF3risJzD0Nfeact23wQzgFVRDJCNeKLL7+ulG3yxltvvs5zRw+U+FoCVSS0xeM80HYcvRaG/4GzW5Twb8LxuEAet9vtyhkSIDIWj9pDaCIODw9/o3CgMFknJOjktKJ4xsbGejOZTJQ1AWj4p/Hx8T70hEfY4ye2dIk8RfEqoTpzuVyryWSy4TpLsVikOVRRAdt88uzsbP/k5OTN2q+cmJh4bX5+/hHOVXBuJcmxT8d2Ho/wY+xSVv6FQu0CSkmeHfRgLpeLIYESDeAC1FPkk1CKWpmamlpNJpNfaQmkUqklJLAl5hSEFJFAkStCfWazuWi1Wstut7skljGtJ5Suri7z3t6ems/n1aOjIzsusGKbX8OgJpsJQ0NDrZFI5DuDweA4OTnZnp6efntubi6NxuQUht5URL+E+gqoL4vtI+GFvJDKYUVPSlKz0+k040Lr8fExESAxWCwWQEMgnxIbGxvj3d3dH8Tj8fcGBwe/pUIkx+Q8WkMksE8eytGQkLzw8ikJKiDiqkVETBgW0/7+PrUNtJVoHElxpTabDagtn5jtN71e77tyvAFKQop4vyyk02lyT1lIVYEkEgbx9XT1sqG0oji04vF42trb26veUb/2nXwv2nahj/QaxXWyYWHk1zw8WAzSK3XETM9AIGAmCYVC/J14mrRPKWId6WyBOif3GTbs9F9ARKcxaJ6co23XG6+3XNv5G3Vxxb+4dX//AAAAAElFTkSuQmCC',
           symbolSize: 20,
           symbolOffset:[0, '-60%']
         })
        })
       
        if(source === 'bar'){
          state.barPolarityStatistics = { 'xAxis': xAxis, 'xAxisZh': xAxisZh, 'manyNum': manyNum, 'maxNum': maxNum, 'neutralNum': neutralNum, 'emptyNum': emptyNum, 'irrelevanceNum': irrelevanceNum }
        }else{
          state.macroPolarityStatistics = { 'xAxis': xAxis, 'xAxisZh': xAxisZh, 'markData':markData, 'manyNum': manyNum, 'maxNum': maxNum, 'neutralNum': neutralNum, 'emptyNum': emptyNum,'heatNum':heatNum, 'irrelevanceNum': irrelevanceNum }
        }
      }
    }).addCase(fetchGetHeatCurve.fulfilled, (state, { payload }) => {
      const { code, timeType, data } = payload;
      if (code === '0') {
        const xAxis: any = [];
        const seriesData: any = []
        const markData: any = []
        state.heatList = data;
        data.forEach((item: any, index: number) => {
          if(timeType === 'Day'){
            xAxis.push(`${moment(Number(item.time)).format('MM')}-${moment(Number(item.time)).format('DD')}`)
          }else{
            xAxis.push(`${moment(Number(item.time)).format('HH')}:${moment(Number(item.time)).format('mm')}\n${moment(Number(item.time)).format('MM')}-${moment(Number(item.time)).format('DD')}`)
          }
          seriesData.push({value:item.number,...item})
          // if(item.total){
            markData.push({
              coord: [index, item.number],
              data:{...item},
              itemStyle:{color:'#fff'},
              // label:{
              //   show:true,
              //   color:'#000'
              // },
              symbol:'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAcCAYAAADvANYcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAS0SURBVHgBtVddaGNFFD63+btJmuaHYkwoSpe6tAkttEEp/jx0t/uyjS+uBbHQCiI+tSh9UZ8KLgiyslDQh4IvFV+sIOK2L0lXsLs+WLOBLWkh4G6jtdlaSmprmzR/4znTmXiTJreKmw8Od+bOzDnfPefMmbkANWCMuVC+Z83BN6QfzoGCk95gzcU7ZEdrtEVLYGZmRimXy53QRKB+N9lBMsoZDwhCpmw2e501EaSf7JA97HLjRqj2irFQKBhUVdX9mgcPf4V78fuQWE9CLncCqmqBYOAiXL70ErhdTt21pN/n85n8fj91iQXjJIhRMBhs2d3dNdAkPSXLt+9A9PYKhAZ64dVrI+D3eSGT+RNi99bg4xufwTASuXzpRV0SJLFYrKQgBBEAjBF5wYLi3NnZudHIlT/H7rOZDz9hv28/4v1fHqRYZPkHhh7hfXpP43furjYMB+lHO7RDVOH903AkEgmZEy2YOEpjL6xAeGSYf730iAT3zCthCF8dhsWlZd6nMNWiVCpxOx0dHcrW1ha3xZksLCzI+LQ0IpDYSPL0DfX38b6qmrnBj66/DwP9vTwclB+hgT5UxGA7vQN6QAIwOjoKFRIYDkbM9BalUanvSW+l/8Lzz6E8C3d/XIV1JOh2OytffqHzKUzelJ66KluVcBiNRtbW1kZh0yWjBXnn1lKUuz589Qr8B/BkFBH4Jxybm5vKwcFBQwIXOp+Gh7g1tfCjZ4gAbU1t/NPpP8Dn84IOGlZMXfh8T/BYx+JrlXdULygX1rFeSFCfTAR7LuqpY3VJYE6Aw+FouMqKBWwY9//iYpR/qSTW0/MMBAKnBikZby1FdOuEAPeETMxKxaRsPQ+UjFncAbOffs7DMIA75eWRK5xUdHkFq+gaJyB3UF3risJzD0Nfeact23wQzgFVRDJCNeKLL7+ulG3yxltvvs5zRw+U+FoCVSS0xeM80HYcvRaG/4GzW5Twb8LxuEAet9vtyhkSIDIWj9pDaCIODw9/o3CgMFknJOjktKJ4xsbGejOZTJQ1AWj4p/Hx8T70hEfY4ye2dIk8RfEqoTpzuVyryWSy4TpLsVikOVRRAdt88uzsbP/k5OTN2q+cmJh4bX5+/hHOVXBuJcmxT8d2Ho/wY+xSVv6FQu0CSkmeHfRgLpeLIYESDeAC1FPkk1CKWpmamlpNJpNfaQmkUqklJLAl5hSEFJFAkStCfWazuWi1Wstut7skljGtJ5Suri7z3t6ems/n1aOjIzsusGKbX8OgJpsJQ0NDrZFI5DuDweA4OTnZnp6efntubi6NxuQUht5URL+E+gqoL4vtI+GFvJDKYUVPSlKz0+k040Lr8fExESAxWCwWQEMgnxIbGxvj3d3dH8Tj8fcGBwe/pUIkx+Q8WkMksE8eytGQkLzw8ikJKiDiqkVETBgW0/7+PrUNtJVoHElxpTabDagtn5jtN71e77tyvAFKQop4vyyk02lyT1lIVYEkEgbx9XT1sqG0oji04vF42trb26veUb/2nXwv2nahj/QaxXWyYWHk1zw8WAzSK3XETM9AIGAmCYVC/J14mrRPKWId6WyBOif3GTbs9F9ARKcxaJ6co23XG6+3XNv5G3Vxxb+4dX//AAAAAElFTkSuQmCC',
              symbolSize: 20,
              symbolOffset:[0, '-60%']
            })
          // }
          
        });
        state.heatCurve.xAxis = xAxis
        state.heatCurve.seriesData = seriesData
        state.heatCurve.markData = markData
     }
    }).addCase(fetchGetOtherCurve.fulfilled, (state, { payload }) => {
      const { code, key, data } = payload;
      if (code === '0') {
        const seriesData: any = []
        data.forEach((item: any, index: number) => {
          seriesData.push({value:item.number,...item})
        });
        state.heatCurve[key]= seriesData
      }
    }).addCase(fetchGetTradingVolume.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const seriesData: any = []
        data.forEach((item: any, index: number) => {
          seriesData.push({value:item.number,...item})
        });
        state.heatCurve.TradingVolume= seriesData
      }
    }).addCase(fetchGetTransactionPrice.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const seriesData: any = []
        data.forEach((item: any, index: number) => {
          seriesData.push({value:item.number,...item})
        });
        state.heatCurve.TransactionPrice= seriesData
      }
    }).addCase(fetchGetPersistenceCurvee.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const seriesData: any = []
        data.forEach((item: any, index: number) => {
          seriesData.push({value:item.number,...item})
        });
        state.heatCurve.PersistenceCurvee= seriesData
      }
    }).addCase(fetchFindArticleAndCommentByBarId.fulfilled, (state, { payload }) => {
      const { code, webType, data } = payload;
      if (code === '0') {
        if(webType.includes('东方')){
          state.articleAndComment.df = {dataList:data.dataList,total: Number(data.total)}
        }else{
          state.articleAndComment.xq = {dataList:data.dataList,total: Number(data.total)}
        }

       
      }
    }).addCase(fetchGetCompanyProfile.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.newCompanyProfile = data
      }
    }).addCase(fetchGetOverviewAuthorHotTrend.pending, state => {
      state.loading.overviewAuthorHotTrend = true;
    }).addCase(fetchGetOverviewAuthorHotTrend.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const dataArr: any = []
        const xAxis: any = [];
        const xAxisZh: any = [];
        const topArr = data.heatTopLists;
        topArr.forEach((item: any) => {
          dataArr.push({ 'name': item.authorName, seriesData: [] })
        });
        data.heatDtoList.forEach((item: any) => {
          xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MMMM')}`)
          xAxis.push(`${moment(Number(item.dateTimestamp)).format('DD')}\n${moment(Number(item.dateTimestamp)).format('MM月')}`)
          item.heatDtoList.forEach((heatItem: any) => {
            for (var i in dataArr) {
              if (heatItem.name === dataArr[i].name) {
                dataArr[i].seriesData.push(heatItem.heatNum)
              }
            }
          });
        });
        state.overviewAuthorHotTrend = { 'xAxis': xAxis,'xAxisZh':xAxisZh, 'trend': dataArr }
        state.loading.overviewAuthorHotTrend = false;
      }
    }).addCase(fetchGetPostStatistics.fulfilled, (state, { payload }) => {
      const { code, data, webName } = payload;
      if (code === '0') {
        state[webName === '雪球' ? 'xueqiuStatistics' : 'postStatistics'] = [
          [
            { value:data.pastFiveDays.article.empty,
              total:data.pastFiveDays.article.total,
              xqtotal:data.pastFiveDays.article.xqtotal,
              xqirrelevance:data.pastFiveDays.article.xqirrelevance,
              xqempty:data.pastFiveDays.article.xqempty,
              xqmany:data.pastFiveDays.article.xqmany,
              dfempty:data.pastFiveDays.article.dfempty,
              dfmany:data.pastFiveDays.article.dfmany,
              dfirrelevance:data.pastFiveDays.article.dfirrelevance,
              dftotal:data.pastFiveDays.article.dftotal,
              }, 
            {value:data.pastOneDays.article.empty,
              total:data.pastOneDays.article.total,
              xqtotal:data.pastOneDays.article.xqtotal,
              xqirrelevance:data.pastOneDays.article.xqirrelevance,
              xqempty:data.pastOneDays.article.xqempty,
              xqmany:data.pastOneDays.article.xqmany,
              dfempty:data.pastOneDays.article.dfempty,
              dfmany:data.pastOneDays.article.dfmany,
              dfirrelevance:data.pastOneDays.article.dfirrelevance,
              dftotal:data.pastOneDays.article.dftotal,
            },
            {value:data.yesterdayCloseToTodayOpen.article.empty,
              total:data.yesterdayCloseToTodayOpen.article.total,
              xqtotal:data.yesterdayCloseToTodayOpen.article.xqtotal,
              xqirrelevance:data.yesterdayCloseToTodayOpen.article.xqirrelevance,
              xqempty:data.yesterdayCloseToTodayOpen.article.xqempty,
              xqmany:data.yesterdayCloseToTodayOpen.article.xqmany,
              dfempty:data.yesterdayCloseToTodayOpen.article.dfempty,
              dfmany:data.yesterdayCloseToTodayOpen.article.dfmany,
              dfirrelevance:data.yesterdayCloseToTodayOpen.article.dfirrelevance,
              dftotal:data.yesterdayCloseToTodayOpen.article.dftotal,
            },
            {value:data.todayOpenToNowTime.article?.empty,
              total:data.todayOpenToNowTime.article?.total,
              xqtotal:data.todayOpenToNowTime.article?.xqtotal,
              xqirrelevance:data.todayOpenToNowTime.article?.xqirrelevance,
              xqempty:data.todayOpenToNowTime.article?.xqempty,
              xqmany:data.todayOpenToNowTime.article?.xqmany,
              dfempty:data.todayOpenToNowTime.article?.dfempty,
              dfmany:data.todayOpenToNowTime.article?.dfmany,
              dfirrelevance:data.todayOpenToNowTime.article?.dfirrelevance,
              dftotal:data.todayOpenToNowTime.article?.dftotal,
            },
          ],
          [
            {value:data.pastFiveDays.article.irrelevance}, 
            {value:data.pastOneDays.article.irrelevance},
            {value:data.yesterdayCloseToTodayOpen.article.irrelevance},
            {value:data.todayOpenToNowTime.article?.irrelevance},
          ],
          [
            { value:data.pastFiveDays.article.many}, 
            {value:data.pastOneDays.article.many},
            {value:data.yesterdayCloseToTodayOpen.article.many},
            {value:data.todayOpenToNowTime.article?.many,},
          ],
          [
            { value:data.pastFiveDays.comment.empty,
              total:data.pastFiveDays.comment.total,
              xqtotal:data.pastFiveDays.comment.xqtotal,
              xqirrelevance:data.pastFiveDays.comment.xqirrelevance,
              xqempty:data.pastFiveDays.comment.xqempty,
              xqmany:data.pastFiveDays.comment.xqmany,
              dfempty:data.pastFiveDays.comment.dfempty,
              dfmany:data.pastFiveDays.comment.dfmany,
              dfirrelevance:data.pastFiveDays.comment.dfirrelevance,
              dftotal:data.pastFiveDays.comment.dftotal,
              }, 
            {value:data.pastOneDays.comment.empty,
              total:data.pastOneDays.comment.total,
              xqtotal:data.pastOneDays.comment.xqtotal,
              xqirrelevance:data.pastOneDays.comment.xqirrelevance,
              xqempty:data.pastOneDays.comment.xqempty,
              xqmany:data.pastOneDays.comment.xqmany,
              dfempty:data.pastOneDays.comment.dfempty,
              dfmany:data.pastOneDays.comment.dfmany,
              dfirrelevance:data.pastOneDays.comment.dfirrelevance,
              dftotal:data.pastOneDays.comment.dftotal,
            },
            {value:data.yesterdayCloseToTodayOpen.comment.empty,
              total:data.yesterdayCloseToTodayOpen.comment.total,
              xqtotal:data.yesterdayCloseToTodayOpen.comment.xqtotal,
              xqirrelevance:data.yesterdayCloseToTodayOpen.comment.xqirrelevance,
              xqempty:data.yesterdayCloseToTodayOpen.comment.xqempty,
              xqmany:data.yesterdayCloseToTodayOpen.comment.xqmany,
              dfempty:data.yesterdayCloseToTodayOpen.comment.dfempty,
              dfmany:data.yesterdayCloseToTodayOpen.comment.dfmany,
              dfirrelevance:data.yesterdayCloseToTodayOpen.comment.dfirrelevance,
              dftotal:data.yesterdayCloseToTodayOpen.comment.dftotal,
            },
            {value:data.todayOpenToNowTime.comment?.empty,
              total:data.todayOpenToNowTime.comment?.total,
              xqtotal:data.todayOpenToNowTime.comment?.xqtotal,
              xqirrelevance:data.todayOpenToNowTime.comment?.xqirrelevance,
              xqempty:data.todayOpenToNowTime.comment?.xqempty,
              xqmany:data.todayOpenToNowTime.comment?.xqmany,
              dfempty:data.todayOpenToNowTime.comment?.dfempty,
              dfmany:data.todayOpenToNowTime.comment?.dfmany,
              dfirrelevance:data.todayOpenToNowTime.comment?.dfirrelevance,
              dftotal:data.todayOpenToNowTime.comment?.dftotal,
            },
          ],
          [
            {value:data.pastFiveDays.comment.irrelevance}, 
            {value:data.pastOneDays.comment.irrelevance },
            {value:data.yesterdayCloseToTodayOpen.comment.irrelevance},
            {value:data.todayOpenToNowTime.comment?.irrelevance},
          ],
          [
            {value:data.pastFiveDays.comment.many}, 
            {value:data.pastOneDays.comment.many},
            {value:data.yesterdayCloseToTodayOpen.comment.many},
            {value:data.todayOpenToNowTime.comment?.many},
          ]
        ]
      }
    }).addCase(fetchGetIndustryBarPostStatistics.fulfilled, (state, { payload }) => {
      const { code, data, webName } = payload;
      if (code === '0') {
        state[webName === '雪球' ? 'xueqiuStatistics' : 'postStatistics'] = [
          [
            { value:data.pastFiveDays.article.empty,
              total:data.pastFiveDays.article.total,
              xqtotal:data.pastFiveDays.article.xqtotal,
              xqirrelevance:data.pastFiveDays.article.xqirrelevance,
              xqempty:data.pastFiveDays.article.xqempty,
              xqmany:data.pastFiveDays.article.xqmany,
              dfempty:data.pastFiveDays.article.dfempty,
              dfmany:data.pastFiveDays.article.dfmany,
              dfirrelevance:data.pastFiveDays.article.dfirrelevance,
              dftotal:data.pastFiveDays.article.dftotal,
              }, 
            {value:data.pastOneDays.article.empty,
              total:data.pastOneDays.article.total,
              xqtotal:data.pastOneDays.article.xqtotal,
              xqirrelevance:data.pastOneDays.article.xqirrelevance,
              xqempty:data.pastOneDays.article.xqempty,
              xqmany:data.pastOneDays.article.xqmany,
              dfempty:data.pastOneDays.article.dfempty,
              dfmany:data.pastOneDays.article.dfmany,
              dfirrelevance:data.pastOneDays.article.dfirrelevance,
              dftotal:data.pastOneDays.article.dftotal,
            },
            {value:data.yesterdayCloseToTodayOpen.article.empty,
              total:data.yesterdayCloseToTodayOpen.article.total,
              xqtotal:data.yesterdayCloseToTodayOpen.article.xqtotal,
              xqirrelevance:data.yesterdayCloseToTodayOpen.article.xqirrelevance,
              xqempty:data.yesterdayCloseToTodayOpen.article.xqempty,
              xqmany:data.yesterdayCloseToTodayOpen.article.xqmany,
              dfempty:data.yesterdayCloseToTodayOpen.article.dfempty,
              dfmany:data.yesterdayCloseToTodayOpen.article.dfmany,
              dfirrelevance:data.yesterdayCloseToTodayOpen.article.dfirrelevance,
              dftotal:data.yesterdayCloseToTodayOpen.article.dftotal,
            },
            {value:data.todayOpenToNowTime.article?.empty,
              total:data.todayOpenToNowTime.article?.total,
              xqtotal:data.todayOpenToNowTime.article?.xqtotal,
              xqirrelevance:data.todayOpenToNowTime.article?.xqirrelevance,
              xqempty:data.todayOpenToNowTime.article?.xqempty,
              xqmany:data.todayOpenToNowTime.article?.xqmany,
              dfempty:data.todayOpenToNowTime.article?.dfempty,
              dfmany:data.todayOpenToNowTime.article?.dfmany,
              dfirrelevance:data.todayOpenToNowTime.article?.dfirrelevance,
              dftotal:data.todayOpenToNowTime.article?.dftotal,
            },
          ],
          [
            { value:data.pastFiveDays.article.irrelevance}, 
            {value:data.pastOneDays.article.irrelevance},
            {value:data.yesterdayCloseToTodayOpen.article.irrelevance},
            {value:data.todayOpenToNowTime.article?.irrelevance},
          ],
          [
            { value:data.pastFiveDays.article.many}, 
            {value:data.pastOneDays.article.many},
            {value:data.yesterdayCloseToTodayOpen.article.many},
            {value:data.todayOpenToNowTime.article?.many,},
          ],
          [
            { value:data.pastFiveDays.comment.empty,
              total:data.pastFiveDays.comment.total,
              xqtotal:data.pastFiveDays.comment.xqtotal,
              xqirrelevance:data.pastFiveDays.comment.xqirrelevance,
              xqempty:data.pastFiveDays.comment.xqempty,
              xqmany:data.pastFiveDays.comment.xqmany,
              dfempty:data.pastFiveDays.comment.dfempty,
              dfmany:data.pastFiveDays.comment.dfmany,
              dfirrelevance:data.pastFiveDays.comment.dfirrelevance,
              dftotal:data.pastFiveDays.comment.dftotal,
              }, 
            {value:data.pastOneDays.comment.empty,
              total:data.pastOneDays.comment.total,
              xqtotal:data.pastOneDays.comment.xqtotal,
              xqirrelevance:data.pastOneDays.comment.xqirrelevance,
              xqempty:data.pastOneDays.comment.xqempty,
              xqmany:data.pastOneDays.comment.xqmany,
              dfempty:data.pastOneDays.comment.dfempty,
              dfmany:data.pastOneDays.comment.dfmany,
              dfirrelevance:data.pastOneDays.comment.dfirrelevance,
              dftotal:data.pastOneDays.comment.dftotal,
            },
            {value:data.yesterdayCloseToTodayOpen.comment.empty,
              total:data.yesterdayCloseToTodayOpen.comment.total,
              xqtotal:data.yesterdayCloseToTodayOpen.comment.xqtotal,
              xqirrelevance:data.yesterdayCloseToTodayOpen.comment.xqirrelevance,
              xqempty:data.yesterdayCloseToTodayOpen.comment.xqempty,
              xqmany:data.yesterdayCloseToTodayOpen.comment.xqmany,
              dfempty:data.yesterdayCloseToTodayOpen.comment.dfempty,
              dfmany:data.yesterdayCloseToTodayOpen.comment.dfmany,
              dfirrelevance:data.yesterdayCloseToTodayOpen.comment.dfirrelevance,
              dftotal:data.yesterdayCloseToTodayOpen.comment.dftotal,
            },
            {value:data.todayOpenToNowTime.comment?.empty,
              total:data.todayOpenToNowTime.comment?.total,
              xqtotal:data.todayOpenToNowTime.comment?.xqtotal,
              xqirrelevance:data.todayOpenToNowTime.comment?.xqirrelevance,
              xqempty:data.todayOpenToNowTime.comment?.xqempty,
              xqmany:data.todayOpenToNowTime.comment?.xqmany,
              dfempty:data.todayOpenToNowTime.comment?.dfempty,
              dfmany:data.todayOpenToNowTime.comment?.dfmany,
              dfirrelevance:data.todayOpenToNowTime.comment?.dfirrelevance,
              dftotal:data.todayOpenToNowTime.comment?.dftotal,
            },
          ],
          [
            {value:data.pastFiveDays.comment.irrelevance}, 
            {value:data.pastOneDays.comment.irrelevance },
            {value:data.yesterdayCloseToTodayOpen.comment.irrelevance},
            {value:data.todayOpenToNowTime.comment?.irrelevance},
          ],
          [

            {value:data.pastFiveDays.comment.many}, 
            {value:data.pastOneDays.comment.many},
            {value:data.yesterdayCloseToTodayOpen.comment.many},
            {value:data.todayOpenToNowTime.comment?.many},
          ]
        ]
        
      }
    }).addCase(fetchGetWordCloud.pending, state => {
      state.loading.wordCloud = true;
    }).addCase(fetchGetWordCloud.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const colorArr = ['#C0E0A7', '#D8E0A7', '#E0CDA7', '#E0BCA7', '#E0A7A7', '#A7E0D3', '#A7ADE0', '#69E192', '#C269E1', '#E19469',]
        data.forEach((item: any) => {
          item.color = colorArr[Math.floor(Math.random() * 10)]
          item.num = item.num  * 10000000 + (Math.random() * 1000)
        });
        state.wordCloud = data
        state.loading.wordCloud = false;
      }
    }).addCase(fetchCompanyList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        var refactorList: any = []
        for (var i in data) {
          data[i].companyList.slice(0,4).forEach((item: any) => {
            refactorList.push({
              exchangeCode: item.exchangeCode,
              type: item.exchangeEn,
              companyNameEn: item.companyNameEn,
              companyNameZh: item.companyNameZh,
              companyId: item.companyId,
              exchangeEn: item.exchangeEn,
              exchangeZh: item.exchangeEn,
            })
          });
        }
        state.recommend = refactorList
      }
    }).addCase(searchStockCompany.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        var refactorList: any = []
        for (var i in data) {
          refactorList = refactorList.concat(data[i].companyList)
        }
        // const refactorList = companyList.map((item:any) => watchList.includes(item.companyId) ? ({...item, is_watching: 1}) : ({...item, is_watching: 0}));
        state.search = {
          dataList: refactorList
        }
      }
    }).addCase(fetchGetCompanyDetail.pending, state => {
      state.loading.companyDetail = true;
    }).addCase(fetchGetCompanyDetail.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.companyInfo = data
        state.loading.companyDetail = false;
      }
    }).addCase(fetchGetStockCompanyHeatGraphDetail.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.companyInfo = data
      }
    }).addCase(fetchGetStockCompanyHeatGraphList.pending, state => {
      state.loading.treeMapData = true;
    }).addCase(fetchGetStockCompanyHeatGraphList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const colorStr = (commentManyEmptyPercentage:number) => {
          if (commentManyEmptyPercentage > 0 && commentManyEmptyPercentage < 50) {
            return '#D54843'
          } else if (commentManyEmptyPercentage > 50 && commentManyEmptyPercentage < 70) {
            return '#C74945'
          } else if (commentManyEmptyPercentage > 70) {
            return '#8A494E'
          } else if (commentManyEmptyPercentage < 0 && commentManyEmptyPercentage > -50) {
            return '#62C165'
          } else if (commentManyEmptyPercentage < -50 && commentManyEmptyPercentage > -70) {
            return '#54A059'
          } else if (commentManyEmptyPercentage < -70 ) {
            return '#467451'
          } else {
            return '#141516'
          }
        }
        var newTreeData = [{
          "value": [
            1226629000,
          ],
          "name": "Company",
          "id": "company-1",
          "discretion": null,
          "children": [] as any
        }]
        data.forEach((item: any) => {
          newTreeData[0].children.push(
            {
              "value": [item.currentMarket, item.commentManyEmptyPercentage],
              "name": item.securitiesAbbreviation,
              "id": "company-" + item.companyId + item.stockCode,
              "stockCode":item.stockCode,
              "currentMarket":(item.currentMarket / 100000000 ).toFixed(2),
              "turnoverRate":item.turnoverRate,
              "commentManyNum":item.commentManyNum,
              "commentEmptyNum":item.commentEmptyNum,
              "securitiesAbbreviation":item.securitiesAbbreviation,
              "fullNameOfCompany":item.fullNameOfCompany,
              "companyDescription":item.companyDescription,
              "exchangeEn":item.exchangeEn || '--',
              "website":item.website || '--',
              "discretion": "company",
              itemStyle: {
                color: colorStr(item.commentManyEmptyPercentage),
              },
            }
          )
        });
        state.treeData = newTreeData
        state.loading.treeMapData = false;
      }
    })



  }
})

export const {setBarInfo, setMoredata, setStockCode, setNodataCompany, setSearching, setLoading } = noyaxe.actions;

const selectBaseState = (state: RootState) => state.noyaxe;
export const selectLoadingByType = (type: string) => createSelector(selectBaseState, state => state.loading[type] || false);
export const selectTopStockCompany = createSelector(selectBaseState, state => state.topStockCompany || [])
export const selectTopStockCompanySearch = createSelector(selectBaseState, state => state.topStockCompanySearch)
export const selectIndustryBar = createSelector(selectBaseState, state => state.industryBar || [])
export const selectAuthorList = createSelector(selectBaseState, state => state.authorList || [])
export const selectAuthorInfo = createSelector(selectBaseState, state => state.authorInfo || [])
export const selectPostStatistics = createSelector(selectBaseState, state => state.postStatistics || [])
export const selectXueqiuStatistics = createSelector(selectBaseState, state => state.xueqiuStatistics || [])
export const selectAccumulatedFansNum = createSelector(selectBaseState, state => state.accumulatedFansNum || { 'xAxis': [],'xAxisZh': [], 'seriesData': [] })
export const selectFansAndPolarity = createSelector(selectBaseState, state => state.fansAndPolarity || { 'xAxis': [], 'manyNum': [], 'fansNum': [], 'emptyNum': [], 'maxNum': 0 ,'minNum':0})
export const selectUpdateFrequency = createSelector(selectBaseState, state => state.updateFrequency || { 'xAxis': [], 'weiboData': [], 'wechartData': [], 'toutiaoData': [] })
export const selectAuthorArticleReadCommentNum = createSelector(selectBaseState, state => state.authorArticleReadCommentNum || { 'xAxis': [], 'likeNum': [], 'commentNum': [] })
export const selectOverviewArticleStatistics = createSelector(selectBaseState, state => state.overviewArticleStatistics || {  'maxLineNum':20, 'maxBarNum': 20, 'xAxis': [],'xAxisZh': [], 'likeNum': [], 'commentNum': [], 'articleNum': [] })
export const selectOverviewPolarityStatistics = createSelector(selectBaseState, state => state.overviewPolarityStatistics || { 'xAxis': [],'xAxisZh': [], 'manyNum': [], 'maxNum': 50, 'neutralNum': [], 'emptyNum': [], 'irrelevanceNum': [] })
export const selectBarPolarityStatistics = createSelector(selectBaseState, state => state.barPolarityStatistics || { 'xAxis': [], 'manyNum': [], 'maxNum': 50, 'neutralNum': [], 'emptyNum': [], 'irrelevanceNum': [] })
export const selectMarcoPolarityStatistics = createSelector(selectBaseState, state => state.macroPolarityStatistics || { 'xAxis': [], 'manyNum': [], 'maxNum': 50, 'neutralNum': [], 'heatNum':[], 'emptyNum': [], 'markData':[], 'irrelevanceNum': [] })
export const selectOverviewAuthorHotTrend = createSelector(selectBaseState, state => state.overviewAuthorHotTrend || { 'xAxis': [],'xAxisZh': [], 'trend': [] })
export const selectFetchOptions = createSelector(selectBaseState, state => state.articleListOptions)
export const selectArticleList = createSelector(selectBaseState, state => state.articleListOptions.articleList || [])
export const selectWordCloud = createSelector(selectBaseState, state => state.wordCloud || [])
export const selectIsSearching = createSelector(selectBaseState, state => state.isSearching)
export const selectRecommendList = createSelector(selectBaseState, state => state.recommend || []);
export const selectSearchList = createSelector(selectBaseState, state => state.search.dataList);
export const selectCompanyInfo = createSelector(selectBaseState, state => state.companyInfo || null);
export const selectTreeData = createSelector(selectBaseState, state => state.treeData || []);
export const selectHeatCurve = createSelector(selectBaseState, state => state.heatCurve);
export const selectHeatList = createSelector(selectBaseState, state => state.heatList);
export const selectstockCode= createSelector(selectBaseState, state => state.stockCode);
export const selectNewCompanyProfile = createSelector(selectBaseState, state => state.newCompanyProfile || {} )
export const selectMoreData = createSelector(selectBaseState, state => state.moreData || {} )
export const selectArticleAndComment = createSelector(selectBaseState, state => state.articleAndComment);
export const selectBarInfo = createSelector(selectBaseState, state => state.barInfo);

export default noyaxe.reducer;
